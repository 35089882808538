*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

body,
html {
  background-color: #f9f9f9;
  color: $text;
  font-family: 'Inter', sans-serif;
  font-size: $ds-text;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: $ds-space-6;
  -moz-osx-font-smoothing: grayscale;

  &.with-modal {
    overflow: hidden;
  }
}

::selection {
  background: rgba(#45c9ec, .5);
}

::-moz-selection,
input::-moz-selection,
textarea::-moz-selection {
  background: rgba(#45c9ec, .5);
}

input,
textarea {
  border: 1px solid #ccc;
}

button {
  //border: none;
  outline: none;

  &.focus-visible {
    box-shadow: 0 0 0 2px rgba(76, 76, 76, .8);
  }
}

div,
span {
  outline: none;

  &.focus-visible {
    box-shadow: 0 0 0 2px rgba(76, 76, 76, .8);
  }
}

a {
  outline: none !important;

  &.focus-visible {
    box-shadow: 0 0 0 2px rgba(76, 76, 76, .8);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

a {
  color: $orange;
  cursor: pointer;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.gray {
  color: $text-gray;
}

.blue {
  color: $blue;
}

.blue-darken {
  color: darken($blue, 5%);
}

.green {
  color: $green;
}

.orange {
  color: $orange;
}

.red {
  color: $red;
}

.mobile {
  display: none;
}

.upper {
  text-transform: uppercase;
}

.vspace {
  height: 15px;
}

.prewrap {
  white-space: pre-wrap;
}

.disabled {
  cursor: default;
  opacity: .3;
  pointer-events: none;
}

.color-yellow {
  color: $yellow;
}

.color-red {
  color: $red;
}

.color-green {
  color: $green;
}

.lined {
  position: relative;
  text-align: center;

  span {
    background-color: #fff;
    color: $text-gray;
    padding: 0 15px;
    position: relative;
    z-index: 2;
  }

  &:before {
    border-bottom: 1px solid $border-gray;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 1;
  }
}

ul {
  margin-top: 10px;

  li {
    font-size: 20px;
    margin-left: 14px;

    &:before {
      background: url('/img/icons/dot_o.svg') no-repeat 0 0;
      background-size: 100% auto;
      content: '';
      display: inline-block;
      height: 9px;
      margin-left: -14px;
      margin-right: 7px;
      width: 7px;
    }

    &.checked {
      &:before {
        background-image: url('/img/icons/checked.svg');
        height: 15px;
        width: 15px;
      }
    }

    &.wrong {
      &:before {
        background-image: url('/img/icons/conflict.svg');
        height: 15px;
        width: 15px;
      }
    }
  }

  &.white {
    li {
      &:before {
        background-image: url('/img/icons/dot_w.svg');
      }
    }
  }
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.alert {
  color: $red;
  margin: 20px 10px;
  text-align: center;
}

.top-space {
  margin-top: 20px;
}

.btn {
  background-color: $orange;
  background-position: 25px center;
  background-repeat: no-repeat;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  margin: 10px 5px;
  outline: none;
  padding: 15px 20px;
  text-decoration: none;

  @include border-radius(50px);

  &:hover {
    background-color: darken($orange, 8%);
  }

  &.hire-package-btn {
    background-image: linear-gradient(85deg, #23b8e5, #2099d2);
    background-position: 0;
    margin: 10px 0;
    padding: 8px 0;
    width: 100%;

    @include border-radius(10px);

    &:hover {
      background-image: linear-gradient(
      85deg,
      darken(#23b8e5, 2%),
      darken(#2099d2, 5%)
      );
    }
  }

  &.loading {
    background-image: url('/img/loader_w.svg');
    background-position: center;
    background-size: 25px;
    text-indent: -9999px;
  }

  &.btn-brd {
    background-color: transparent;
    border: 1px solid rgba(#fff, .5);
    padding-bottom: 14px;
    padding-top: 14px;

    &:hover {
      background-color: rgba(#fff, .14);
    }
  }

  &.btn-brd-dark {
    background-color: transparent;
    border: 1px solid rgba(#000, .5);
    color: inherit;
    padding-bottom: 14px;
    padding-top: 14px;

    &:hover {
      background-color: rgba(#000, .05);
    }
  }

  &.btn-brd-gray {
    background-color: transparent;
    border: 1px solid #d9d9d9;
    color: inherit;
    font-weight: normal;
    padding-bottom: 14px;
    padding-top: 14px;

    &:hover {
      background-color: rgba(#000, .05);
    }
  }

  &.btn-green {
    background-color: $green;

    &:hover {
      background-color: darken($green, 5%);
    }
  }

  &.btn-orange {
    background-color: $orange;

    &:hover {
      background-color: darken($orange, 5%);
    }
  }

  &.btn-yellow {
    background-color: $yellow;

    &:hover {
      background-color: darken($yellow, 5%);
    }
  }

  &.btn-red {
    background-color: $red;

    &:hover {
      background-color: darken($red, 5%);
    }
  }

  &.btn-blue {
    background-color: $blue;

    &:hover {
      background-color: darken($blue, 5%);
    }
  }

  &.btn-black {
    background-color: $text;

    &:hover {
      background-color: darken($text, 5%);
    }
  }

  &.btn-white {
    background-color: #fff;
    color: inherit;
  }

  &.btn-email {
    background-image: url('/img/icons/post.svg');
    background-position: 18px center;
    margin: 10px 4px;
    padding-left: 50px;
  }

  &.btn-messenger {
    background-image: url('/img/icons/messenger.svg');
    background-position: 18px center;
    margin: 10px 4px;
    padding-left: 50px;
  }

  &.btn-ico {
    img {
      margin: -4px 22px 0 -15px;
      width: 26px;
    }
  }
}

.buttons {
  .btn:first-child {
    margin-left: 0;
  }

  .btn:last-child {
    margin-right: 0;
  }
}

.close {
  background: url('/img/icons/close.svg') no-repeat center center;
  background-size: 22px auto;
  height: 44px;
  opacity: .9;
  position: absolute;
  right: 25px;
  top: 20px;
  width: 44px;
  z-index: 10;

  @include transition();

  &.dark {
    background-image: url('/img/icons/close_b.svg');
    opacity: .5;
  }

  &:hover {
    opacity: 1;

    @include transform(rotate(90deg));
  }
}

.intro {
  flex: auto;
  margin-top: 20vh;

  img {
    height: 65px;
  }

  h1 {
    font-size: 44px;
  }

  p {
    color: $text-gray;
    font-size: 18px;
    margin: 15px 0 25px;
  }

  .btn {
    padding: 15px 45px;
  }
}

.thumb {
  background-color: $gray;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #fafafa;
  height: 90px;
  margin: 18px 0 12px;
  overflow: hidden;
  position: relative;
  width: 90px;

  @include border-radius(50%);

  &:before {
    bottom: 0;
    content: attr(data-initials);
    font-size: 20px;
    left: 0;
    line-height: 90px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }

  .hover {
    background-color: rgba(#000, .7);
    color: #fff;
    display: none;
    height: 100%;
    line-height: 84px;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  &.loading {
    background-image: url('/img/loader-orange.svg') !important;
    background-size: 30px !important;
  }
}

.tags {
  span {
    background-color: $blue;
    color: #fff;
    display: inline-block;
    margin: 2px;
    padding: 3px 10px;

    @include border-radius(20px);
  }

  &.removable {
    span {
      background: $blue url('/img/icons/close.svg') no-repeat 115% center;
      background-size: auto 8px;
      padding-left: 18px;
      padding-right: 18px;

      @include transition();

      &:hover {
        background-color: $red;
        background-position: calc(100% - 10px) center;
        cursor: pointer;
        padding-left: 10px;
        padding-right: 26px;
      }
    }
  }
}

.wrapbox {
  font-size: 22px;
  font-weight: 300;
  padding-bottom: 50px;
  padding-top: 50px;

  h2 {
    font-size: 37px;
    line-height: 130%;
    margin-bottom: 20px;
  }

  strong {
    display: block;
    margin-top: 15px;
  }

  a {
    font-size: 16px;
  }

  &.paddbox {
    padding-bottom: 120px;
    padding-top: 120px;
  }
}

.paddbox {
  padding-bottom: 120px;
  padding-top: 120px;
}

.ember-modal-dialog {
  border: 1px solid #e6e6e6;
  box-shadow: none;

  @include border-radius(20px);
}

.ember-modal-overlay.modal-popup {
  background-color: rgba(#000, .5);

  .ember-modal-dialog {
    border: none;
    box-shadow: 0 2px 3px rgba(#000, .3);
    max-width: 100%;
  }
}

.ember-modal-dialog-target-attachment-none {
  left: 50%;
  top: 50%;

  @include center();
}

.default-dialog {
  max-height: 90vh;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px 38px;
  width: 500px;
  word-break: break-word;

  h2 {
    color: $orange;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
  }

  .sub-h2 {
    margin-bottom: 25px;
    margin-top: -15px;
    text-align: center;
  }

  .mob-close {
    display: none;
  }

  &.wide {
    max-width: 100%;
    width: 1100px;
  }

  &.no-overflow {
    overflow: inherit;
  }
}

.black-error {
  background-color: #000;
}

.new-item {
  background-image: url('/img/icons/add_item.svg') !important;
  background-position: 20px 50% !important;
  background-repeat: no-repeat;
  background-size: 25px !important;
  padding-left: 20px !important;
  text-indent: 35px;

  &:hover {
    background-image: url('/img/icons/add_item_blue.svg') !important;
    color: $blue;
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }
}

.fourohfour {
  img {
    @include transition();

    &:hover {
      @include transform(scale(1.05, 1.05));
    }
  }
}

$size: 36px;
$tint: $blue;
$off-white: rgba($tint, .4);
$spin-duration: 800ms;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    background: $tint;
  }
}

li {
  &.loading-item {
    background-image: url('/img/loader-orange.svg') !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: 25px !important;
  }
}

.loader {
  animation: spin $spin-duration infinite linear;
  border: .35rem solid $off-white;
  border-radius: 50%;
  border-top-color: $tint;
  height: $size;
  width: $size;

  &.small {
    border-width: .3rem;
    height: 25px;
    width: 25px;
  }
}

.hide-mobile {
  display: block;
}

.hide-desktop {
  display: none;
}

.no-margin {
  margin: 0 !important;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.profile-statistics--number {
  line-height: 70px;
}

@media (max-width: $md-width) {
  .hide-mobile {
    display: none;
  }

  .hide-desktop {
    display: block;
  }
}

@media (max-width: 767px) {
  .ember-modal-dialog {
    top: 0 !important;
    transform: translate(-50%, 0) !important;
  }
}

@keyframes shine-lines {
  0% {
    opacity: .2;
    transform: translate(-100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate(150%, 0);
  }
}
