.progress-bar {
  background-color: #f8f8f8;
  height: 16px;

  .status {
    background-color: $red;
    height: 6px;
    position: relative;
    top: 5px;

    &.green {
      background-color: $green;
      color: inherit;
    }

    &.yellow {
      background-color: $yellow;
    }

    span {
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      right: -32px;
      top: -9px;
    }
  }
}
