.profile-card-add-action {
  background: url('/img/icons/add_item.svg') no-repeat left center;
  cursor: pointer;
  display: block;
  line-height: 29px;
  padding-left: 45px;
  margin: 20px 25px;
  @include noselect;

  label {
    cursor: pointer;
  }

  input[type=file] {
    display: none;
  }

  &:hover {
    background-image: url('/img/icons/add_item_hover.svg');
    color: $orange;
  }

}

.profile-card-add-action--spacer {
  border-top: 1px #efefef solid;
  height: 1px;
  margin-top: 20px;
}

.profile-card-add-action--info-text {
  color: darken($gray, 25%);
  margin: 15px 25px;
  text-align: left;
}
