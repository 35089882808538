.user--profile {
  .-actions {
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
    margin: 20px 0;
    padding-top: 25px;

    .-btn {
      background-color: #fefefe;
      background-position: 10px center;
      background-repeat: no-repeat;
      background-size: auto 22px;
      border: 1px solid #F4F4F4;
      color: inherit;
      font-size: 15px;
      padding: 8px 10px;
      text-align: left;
      @include border-radius(10px);

      &:hover {
        background-color: #f9f9f9;
      }

      &.--download {
        background-image: url('/img/icons/download.svg');
        background-size: auto 18px;
        padding-left: 36px;
      }
    }
  }

  @media (max-width: $sm-width) {
    .--flex {
      flex-direction: column;

      .--left {
        margin-right: 0;
      }
    }
  }
}
