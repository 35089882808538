.fixed-nav {
  background-color: $orange;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 48;
}

.sticky-nav-container, .sticky-header-container {
  animation: slideDown 0.2s forwards;
  background-color: $orange;
  box-shadow: 0 2px 12px rgba(#000, 0.2);
  position: fixed;
  top: -100px;
  width: 100%;
  z-index: 48;

  @include transition();

  @media(max-width: $sm-width) {
    position: relative;
    top: 0;
  }

  &.blue {
    background-color: $blue;
  }
}

.sticky-header-container {
  position: fixed !important;
  z-index: 9999 !important;
}

@keyframes slideDown {
  from {
    top: -100px;
  }

  to {
    top: 0;
  }
}

@media(max-width: 1200px) {
  .fixed-nav {
    position: relative;
  }
}
