#job-detail {
  min-height: 100vh;

  .job-detail--spacer {
    height: 1px;
    background-color: #E6E6E6;
    margin-top: 40px;
    position: relative;
  }

  .job-detail-container {
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .detail-content {
    width: 760px;
  }

  .detail-content--container {
    display: flex;
    justify-content: flex-start;
  }

  .detail-content--right {
    position: relative;
    margin-left: 20px;
    width: 100%;
  }

  .main-footer {
    background-color: #fff;
  }

  @media(max-width: 1200px) {
    .detail-content {
      width: auto;
    }

    .job-detail-container {
      padding-top: 20px;
    }

    .detail-content--container {
      display: block;
    }

    .detail-content--left {
      float: none;
    }

    .detail-content--right {
      width: 100%;
      left: 0;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
