.user--inbox {
  .--flex {
    align-items: flex-start;
  }

  .--applications {
    flex: none;
    margin-right: 40px;
    margin-top: 35px;
    width: 340px;

    .--scroll {
      height: calc(100vh - 60px - 35px);
      overflow-y: auto;
    }

    .--title {
      font-weight: bold;
      margin: 0 0 10px 8px;
    }

    .wrap {
      margin-bottom: 20px;
    }

    @media (max-width: $sm-width) {
      margin-right: 20px;
      width: auto;

      .--scroll {
        max-width: 80px;
      }
    }
  }

  .--chat {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 35px;

    .--scroll {
      height: calc(100vh - 60px - 35px - 200px);
      overflow-y: auto;
    }
  }

  .wrap {
    background-color: #fff;
    border: 1px solid #F4F4F4;
    overflow: hidden;
    position: relative;
    word-break: break-word;

    @include border-radius(16px);

    .head {
      border-bottom: 1px solid $border-gray;
      padding: 15px 15px 12px;
      position: relative;
      text-align: center;

      .brand {
        color: $orange;
        font-size: 14px;
      }

      .actions {
        margin: -17px 15px 0 0;
        position: absolute;
        right: 0;
        top: 50%;

        .--more {
          background: #f7f7f7 url('/img/icons/dots.svg') no-repeat center;
          height: 34px;
          width: 34px;

          @include border-radius(20px);

          &:hover {
            background-color: #f0f0f0;
          }
        }

        .--menu {
          background-color: #fff;
          border: 1px solid #eaeaea;
          box-shadow: 0 4px 17px 3px rgba(#000, .1);
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 100%;
          white-space: nowrap;
          z-index: 10;

          @include border-radius(13px);

          .--action {
            background-color: #fff;
            border-bottom: 1px solid #eaeaea;
            color: inherit;
            cursor: pointer;
            display: block;
            padding: 12px 15px;
            text-align: left;
            width: 100%;

            &.red {
              color: $red;
            }

            &:last-child {
              border: none;
            }

            &:hover {
              background-color: #fafafa;
            }
          }
        }

        @media(max-width: $sm-width) {
          display: none;
        }
      }
    }
  }

  .--application {
    border-bottom: 1px solid $border-gray;
    margin: 0;

    a {
      align-items: center;
      color: inherit;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      overflow: auto;
      padding: 20px 10px;

      .logo {
        width: 70px;

        img {
          background-color: #fff;
          border: 1px solid #f2f2f2;
          padding: 5px 7px;
          width: 100%;

          @include border-radius(10px);
        }
      }

      .body {
        flex: 1;
        margin: 0 14px;
      }

      &.active {
        background-color: #FFF1E6;

        .title {
          font-weight: bold;
        }
      }

      &:hover {
        background-color: #fafafa;

        &.active {
          background-color: #FFF1E6;
        }
      }
    }

    .title {
      font-weight: 600;
      line-height: 1.4;
      margin: 3px 0 5px;
    }

    .brand {
      color: $orange;
      font-size: 14px;
    }

    .other {
      text-align: center;
      width: 30px;

      small {
        color: #a8a8a8 !important;
        display: inline-block;
        flex-basis: 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
      }

      img {
        margin-top: 10px;
      }
    }

    .badge {
      flex-shrink: 0;
      background-color: $blue;
      color: #fff;
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      min-width: 28px;
      padding: 2px 5px;
      text-align: center;

      @include border-radius(20px);
    }

    .status {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      &.ACTIVE,
      &.INTERVIEW,
      &.VIDEOINTERVIEW {
        color: $green;
      }

      &.REVIEW {
        color: $blue;
      }

      &.REJECTED {
        color: $red;
      }

      &.CANCELED {
        color: #888;
      }
    }

    &.unread {
      h4 {
        font-weight: bold;
      }

      strong {
        font-weight: bold;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .messages {
    min-width: 480px;
    padding: 10px 22px 100px;

    .date {
      color: $text-gray;
      font-size: 12px;
      margin-top: 30px;
      text-align: center;
      text-transform: uppercase;
    }

    .event-group {
      margin-top: 15px;

      .application-message {
        margin-bottom: 10px;

        &.MESSAGE {
          margin-bottom: 3px;

          &.APP {
            .flat-top {
              border-top-right-radius: 4px;
            }

            .flat-bottom {
              border-bottom-right-radius: 4px;
            }
          }

          &.WEB {
            .flat-top {
              border-top-left-radius: 4px;
            }

            .flat-bottom {
              border-bottom-left-radius: 4px;
            }
          }
        }

        &.CALINVITE {
          .bubble {
            width: 360px;
          }
        }
      }
    }

    .application-message {
      position: relative;

      .bubble {
        color: #040404;
        display: inline-block;
        font-size: 14px;
        margin: 0;
        padding: 0;
        position: relative;
        // overflow: hidden;

        @include border-radius(20px);

        .title {
          display: block;
          font-size: 15px;
          font-weight: 600;
          margin: 15px 15px -8px;
        }

        .prewrap,
        .text {
          font-size: 15px;
          line-height: 1.4;
          opacity: 0.75;
          padding: 10px 14px;
          word-break: break-word;
        }

        .action {
          border-top: 1px solid $border-gray;
          margin-top: 12px;
          padding-top: 8px;
        }
      }

      .header {
        bottom: 100%;
        left: 0;
        min-width: max-content;
        overflow: auto;
        padding: 3px 14px;
        position: absolute;
        right: 0;

        .date {
          float: left;
          font-size: 13px;
          font-weight: normal;
          min-width: max-content;
        }

        .author {
          float: right;
          font-size: 13px;
          font-style: italic;
          margin-left: 15px;
          min-width: max-content;
          text-align: right;
        }
      }

      &.WEB {
        .bubble {
          background-color: #f2f2f2;
          margin: 0 0 0 35px;
        }

        &:not(.MESSAGE) {
          .bubble {
            background-color: #fff;
            border: 1px solid $border-gray;
          }
        }
      }

      &.APP {
        > div {
          float: right;
          text-align: right;
        }

        .bubble {
          background-color: $orange;
          color: #fff;
          text-align: left;

          .prewrap,
          .text {
            opacity: 1;
          }
        }

        &:not(.MESSAGE) {
          .bubble {
            background-color: #fff;
            border: 1px solid $border-gray;
            color: $text;
          }
        }
      }

      &.CALCANCEL {
        .location {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .image {
      margin: 0 6px 0 0;
      position: absolute;
      right: 100%;
      top: 2px;
      width: 40px;
    }

    .open-video {
      margin-right: 25px;
      padding: 10px 35px;
    }

    .how-video {
      font-weight: bold;
      text-decoration: underline;
    }

    .location {
      background-color: #f5f5f5;
      color: $text;
      display: block;
      padding: 20px;

      strong {
        display: block;
        text-transform: capitalize;
      }

      p {
        margin: 0;
      }
    }

    .actions {
      display: flex;
      justify-content: space-evenly;
      padding: 15px 0;

      &.vi-invite {
        background: #f5f5f5 url('/img/icons/right.svg') no-repeat calc(100% - 15px) center;
        background-size: 12px auto;
        cursor: pointer;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 45px 10px 20px;

        @include border-radius(0 0 20px 20px);

        p {
          margin-bottom: 8px;
          margin-top: 2px;
        }

        .download-app {
          color: $blue;
          font-weight: 700;
        }
      }
    }
  }

  .new-msg {
    align-items: center;
    background-color: #fff;
    border-top: 1px solid $border-gray;
    bottom: 0;
    display: flex;
    left: 0;
    // position: absolute;
    right: 0;

    .textArea {
      background-color: #f9f9f9;
      border: 1px solid #e6e6e6;
      flex-grow: 1;
      margin: 20px;
      padding: 10px 20px 3px;

      @include border-radius(20px);

      textarea {
        background-color: transparent;
        border: none;
        outline: none;
        resize: none;
        width: 100%;
      }
    }

    .send {
      background-image: url('/img/icons/send-msg.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 44px;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      height: 44px;
      margin: 0 20px 0 0;
      opacity: 1;
      text-align: center;
      width: 44px;

      @include border-radius(20px);

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .content--empty {
    margin-top: 40px;
  }
}

.replyModal {
  .options {
    margin-bottom: 30px;
    text-align: center;

    li {
      display: block;
      margin: 15px 0;

      span {
        cursor: pointer;
        display: inline-block;
        padding: 3px 20px;

        @include border-radius(20px);
      }

      &:before {
        display: none;
      }

      &.active {
        span {
          color: #fff;
        }

        &.accept {
          span {
            background-color: $green;
          }
        }

        &.reject {
          span {
            background-color: $red;
          }
        }

        &.requestnew {
          span {
            background-color: $blue;
          }
        }
      }
    }
  }
}

.successModal {
  img {
    height: 100px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 36px;
  }

  p {
    font-size: 18px;
    margin: 0 38px 15px;
  }
}

.cancelModal {
  textarea {
    height: 150px;
    resize: none;
    width: 100%;
  }
}

.archiveModal {
  .buttons {
    margin-top: 20px;

    .btn {
      padding: 15px 40px;
    }
  }
}
