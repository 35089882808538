.application-summary {
  ul {
    margin: 0;
    padding: 0;
    text-align: left;

    .--header {
      background-color: #ebebeb;
      font-size: 13px;
      font-weight: 600;
      padding: 4px 20px;
    }

    li {
      background-image: url('/img/icons/arrow_right.svg');
      background-position: calc(100% - 30px) 50%;
      background-repeat: no-repeat;
      background-size: 12px;
      border-bottom: 1px #ebebeb solid;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      margin: 0;
      padding: 15px 90px 15px 0;

      p {
        font-size: 16px;
        margin: 0;
        padding: 0;
      }

      .icon-profile {
        align-items: center;
        display: flex;
        margin: 0 25px;

        .-image {
          background-color: #ddd;
          background-size: cover;
          border-radius: 4px;
          height: 30px;
          width: 30px;
        }
      }

      .icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30px;
        padding: 0 40px;
        text-align: center;

        &.check {
          background-image: url('/img/icons/checkmark.svg');
        }

        &.profile {
          background-image: url('/img/icons/profile-g.svg');
        }

        &.profile-green {
          background-image: url('/img/icons/profile-green.svg');
        }

        &.attachment {
          background-image: url('/img/icons/attachment-g.svg');
          background-size: 28px;
        }

        &.esign-incomplete {
          background-image: url('/img/icons/shield-settings.svg');
          background-size: 30px;
        }

        &.esign-complete {
          background-image: url('/img/icons/shield-settings-complete.svg');
          background-size: 30px;
        }

        &.skills {
          background-image: url('/img/icons/thunder-b.svg');
          background-size: auto 30px;
        }

        &.email {
          background-image: url('/img/icons/profile-email.svg');
          background-size: 28px;
        }

        &.email-alert {
          background-image: url('/img/icons/alert.svg');
          background-size: auto 24px;
        }

        &.phone {
          background-image: url('/img/icons/profile-phone.svg');
          background-size: 28px;
        }
      }

      .content {
        margin: 0;
        padding: 0;

        &.small {
          font-size: 14px;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:before {
        display: none;
      }

      &:hover {
        background-color: darken(#fff, 2%);
      }

      &.attachments {
        .content {
          width: 100%;
        }

        .file {
          background-color: darken(#fff, 2%);
          border: 1px #ededed solid;
          font-size: 12px;
          margin-top: 5px;
          padding: 5px 10px;

          @include border-radius(5px);
        }
      }

      &.disabled {
        background-image: none;
        opacity: 1;
        padding-right: 40px;
        pointer-events: none;
      }
    }
  }

  .actions {
    input {
      display: none;
    }
  }

  .disclaimer {
    background-color: darken(#fff, 4%);
    border-top: 1px #ededed solid;
    display: flex;
    font-size: 14px;
    padding: 20px;
    text-align: left;

    p {
      margin: 0;
      padding: 0;
    }

    .icon {
      background-image: url('/img/icons/exclamation.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 16px;
      margin-right: 10px;
      width: 40px;
    }

    &.yellow {
      background-color: $yellow;
    }

    &.attachments {
      p {
        font-weight: 700;
        text-align: center;
        width: 100%;
      }
    }
  }

  @media (max-width: $sm-width) {
    ul {
      li {
        background-image: none;
        padding-right: 20px;
      }
    }
  }
}
