.manage-skills {
  .ember-modal-dialog {
    padding: 0;
    width: 750px;
    overflow: hidden;
  }
  .manage-skills--container {
    max-height: 650px;
    overflow-y: scroll;
    &.disable-scroll {
      overflow-y: hidden;
    }
  }

  @media(max-width: 768px) {
    .manage-skills--container {
      max-height: none;
      overflow-y: auto;
    }
    .ember-modal-dialog {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
}
