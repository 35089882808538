.translate-banner {
  background: #e3f1f5 url('/img/icons/translate.svg') no-repeat 19px center;
  background-size: auto 24px;
  border: 1px solid darken(#e3f1f5, 10%);
  border-radius: 13px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 60px;

  &.-detail {
    margin-bottom: 20px;
  }

  &.-list {
    margin-top: 20px;
  }

  .--text {
    font-weight: 500;
  }

  .--btns {
    text-align: right;
  }

  .--action {
    background-color: rgba(#fff, 0.0);
    border-radius: 8px;
    color: $blue;
    font-weight: 700;
    margin-left: 13px;
    padding: 5px 10px;

    &:hover {
      background-color: rgba(#fff, 0.8);
    }

    &.--full {
      color: #0faddc;
    }

    &.--gray {
      color: #555;
    }
  }

  &.-translated {
    background-color: #fff5ec;
    background-image: url('/img/icons/translate-orange.svg');
    border-color: darken(#fff5ec, 6%);

    .--action {
      color: $orange;
    }
  }
}
