.products-grid {
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
}
.pricing {
  .pricelist {
    margin-top: 40px;

    .border {
      background-color: #fff;
      border: 1px solid #F3F3F3;
      margin-bottom: 40px;
      min-height: 530px;
      padding: 25px 35px;
      position: relative;
      @include ad-card-shadow();
      @include border-radius(20px);

      p {
        &.small {
          font-size: 16px !important;
          margin: 0 !important;
        }
      }

      .title {
        font-weight: 700 !important;
      }
    }

    .ribbon {
      background: url('/img/ribbon.svg') no-repeat top right;
      height: 140px;
      position: absolute;
      right: -12px;
      text-transform: uppercase;
      top: -12px;
      width: 140px;

      span {
        color: #fff;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        margin: 37px -45px 0 0;

        @include transform(rotate(45deg));
      }
    }

    h3 {
      color: $blue;
      font-size: 25px;
      font-weight: bold;
      margin: 10px 0 20px;
      text-transform: uppercase;
    }

    h4 {
      line-height: normal;
      margin: 5px 0 0;
      padding-top: 5px;
    }

    .price {
      font-size: 70px;
      font-weight: 300;
      line-height: 55px;
      margin-bottom: 10px;

      small {
        font-size: 75%;
      }
    }

    p {
      font-weight: 400;

      &.small {
        font-size: 16px;
      }
    }

    hr {
      margin: 27px 0;
    }

    ul {
      li {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.7;
      }
    }
  }

  &.darker {
    background-color: #FBFAF9;
  }
}

.pricing-disclaimer {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

.pricing--show-more {
  background: none;
  font-size: $ds-text;
  text-decoration: underline;
  color: $blue;
  margin-top: $ds-space-6;
}
