$flexGap: 15px;

.flex-container {
  margin: 0 auto;
  max-width: $lg-width;

  @media (max-width: $lg-width) {
    margin: 0;
    padding: 0 20px;
  }
}

.--flex {
  display: flex;

  .--left {
    flex: 1;
    margin-right: 40px;
  }

  .--right {
    flex: 1;
  }
}

.flex-row {
  display: flex;
  justify-content: space-around;
  flex-flow: row nowrap;
  align-items: flex-start;

  > * {
    flex: 1;
    margin: $flexGap;

    &.flex-grow {
      flex-shrink: 0;
      flex-grow: 1;
    }

    &.flex-shrink {
      flex-shrink: 1;
      flex-grow: 0;
    }
  }

  &.no-gap {
    margin: 0;

    > * {
      margin: 0;
    }
  }

  &.spaced {
    justify-content: space-between;

    > * {
      flex: auto;
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  margin-bottom: -$flexGap;
  margin-top: -$flexGap;

  > * {
    margin-bottom: $flexGap;
    margin-top: $flexGap;
  }
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.no-stretch {
  flex: 0;
}

@media (max-width: 767px) {
  .flex-row {
    > * {
      flex: auto;
    }
  }
}
