.profile-about-me {
  .profile-card-editable-item {
    border-bottom: none;
    @include transition(none);

    &:hover {
      cursor: text;
    }
  }
}
