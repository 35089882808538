.detail-meta {
  .padded {
    padding: 20px;
  }

  .detail-meta--title {
    font-weight: 700;
    text-align: left;
  }

  .detail-meta--tags {
    margin-top: 10px;

    a, span {
      background-color: $orange;
      color: #fff;
      display: inline-block;
      font-size: 14px;
      margin: 2px 1px;
      padding: 9px 12px;
      @include noselect;
      @include border-radius(10px);

      &:hover {
        background-color: darken($orange, 5%);
      }
    }

    &.no-click {
      a, span {
        cursor: auto;

        &:hover {
          background-color: $orange;
        }
      }
    }
  }

  .detail-meta--share {
    padding: 20px 20px 12px;
    margin: 0;
  }

  .detail-meta--buttons {
    display: flex;
    justify-content: left;
  }

  @media(max-width: 1200px) {
    .detail-meta--buttons {
      justify-content: left;
    }
  }

  @media(max-width: $sm-width) {
    .detail-meta--buttons {
      flex-wrap: wrap;

      .btn {
        margin: 5px 0;
        font-size: 14px;

        &:nth-of-type(1) {
          margin-right: 10px;
        }
      }
    }
  }
}
