.header-main--placeholder {
  height: 64px;
  display: block;
}
.header-main--fixed {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100vw;
}
@media(max-width: $sm-width){
  .header-main--placeholder {
    display: none;
  }
}
