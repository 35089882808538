.content--empty {
  align-items: center;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 100px 40px;
  text-align: center;

  @include border-radius(16px);

  .icon {
    height: 100px;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    margin: 15px;
  }

  .description {
    line-height: 22px;
    max-width: 575px;
  }

  .btn {
    margin-top: 40px;
  }
}
