#index {
  background-color: #FDFCFC;

  .fluid-container {
    background-color: #fff;
  }

  header {
    background-color: $orange;

    h1 {
      font-size: 46px;
      margin: 0;
      padding: 50px 15px 0;
    }
  }

  .bg-orange {
    @include gradient(90deg, $orange, #e84f00);
  }

  .bg-gray {
    @include gradient(15deg, #fff, #eee);

    #categories {
      margin: 70px 0 90px;

      a {
        color: #111;

        &:hover {
          color: $orange;
        }
      }

    }
  }

}

#boxApp {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: #fff;

  h2 {
    font-weight: 600;
    line-height: 120%;
  }

  .btn {
    font-weight: 900;
  }

  .wrapbox {
    background-repeat: no-repeat;
    background-position: top center;
    padding-bottom: 105px;
    padding-top: 110px;
    font-size: 20px;
    font-weight: 400;
  }

  &.theme-bg {
    .wrapbox {
      background-image: none !important;
    }
  }

  &.theme-header {
    @include gradient(90deg, $orange, #e84f00, 0%, 100%, !important);

    .wrapbox {
      background-position: 100% -25%;
      background-size: 600px auto;
    }
  }
}

#boxCompanies {
  background-repeat: no-repeat;
  background-position: calc(50% - 500px) center;
  background-size: 900px auto;

  .wrapbox {
    padding-bottom: 105px;
    padding-top: 110px;

    h2 {
      font-weight: 600;
    }

    p {
      font-size: 20px;
      line-height: 1.4;
      font-weight: 400;
    }

    strong {}

    ul {
      li {
        font-weight: 400;
        line-height: 1.4;
      }
    }
  }
}

@media (max-width: 1199px) {
  #index {
    header {
      h1 {
        font-size: 40px;
      }
    }
  }

  #boxApp {
    &.theme-header {
      .wrapbox {
        background-size: 580px auto;
        padding-top: 50px;
      }
    }
  }
}

@media (max-width: 991px) {
  #boxApp {
    &.theme-bg {
      .cover {
        background-color: rgba(#63626e, 0.9);
      }

    }

    &.theme-header {
      .wrapbox {
        background-position: calc(50% - 50px) calc(100% + 700px);
        padding-bottom: 250px;
      }
    }
  }

  #boxCompanies {
    .cover {
      background-color: rgba(#fff, 0.9);
    }

    .wrapbox {
      padding-bottom: 50px;
      padding-top: 50px;
    }
  }
}
