.x-file--input + label {
  outline: none !important;

  &:hover {
    .hover {
      display: block;
    }
  }
}

.ember-power-select-search {
  input {
    outline: none !important;
    width: calc(100% - 12px);
  }
}

.ember-power-select-option--no-matches-message {
  font-size: 14px;
}

.form {
  label {
    color: $text-gray;
    font-size: 14px;
    margin-left: 10px;
    text-transform: uppercase;
  }

  .editor,
  .ember-power-select-trigger,
  input,
  select,
  textarea {
    background-color: #fff;
    background-position: calc(100% - 12px) center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    border: 1px solid $border-gray;
    display: block;
    font-size: 16px;
    line-height: normal;
    margin: 5px 0 15px;
    outline: none;
    padding: 15px 12px;
    width: 100%;

    @include border-radius(8px);
    @include appearance();

    &.checked {
      background-image: url('/img/icons/checkmark.svg');
    }

    &.conflict {
      background-image: url('/img/icons/conflict.svg');
    }

    &:focus {
      border: 1px solid $blue;
    }
  }

  .date-picker {
    display: flex;

    .ember-power-select-trigger {
      padding: 12px 8px;

      &.year {
        margin-right: 10px;
        width: 75%;
      }
    }
  }

  textarea {
    min-height: 200px;

    &.small {
      min-height: 100px;
    }
  }

  input[type='checkbox'] {
    display: inline-block;
    width: auto;

    @include appearance(checkbox);
  }

  .select,
  select {
    background: #fff url('/img/icons/select_blue.svg') no-repeat calc(100% - 20px) center;
    background-size: 9px auto;
    padding-right: 42px;

    &.disabled {
      color: $text-gray;
      opacity: 1;
    }
  }

  .radio {
    border: 1px solid $border-gray;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    margin: -3px 0 0 6px;
    vertical-align: middle;
    width: 25px;

    @include border-radius(50%);

    &.active {
      border-color: darken($border-gray, 9%);
      position: relative;

      &:after {
        background-color: $blue;
        bottom: 3px;
        content: '';
        left: 3px;
        position: absolute;
        right: 3px;
        top: 3px;

        @include border-radius(50%);
      }
    }
  }

  .height-3 {
    height: 259px;
  }

  .input-wrap {
    position: relative;

    em {
      color: $text-gray;
      left: 120px;
      position: absolute;
      top: 15px;
    }
  }

  .btn {
    display: block;
    font-size: 20px;
    margin: 10px 0;
    padding: 15px 20px;
    width: 100%;
  }

  .tel-submit {
    position: relative;

    .act {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 14px;
    }

    &.with-token {
      .act {
        visibility: hidden;
      }

      &:hover {
        .act {
          visibility: visible;
        }
      }
    }
  }

  &.less-pd {
    input,
    select {
      padding: 12px 17px;
    }

    .btn {
      font-size: 16px;
      padding: 13px 17px;

      &.add {
        margin-top: 5px;
        padding: 12px 0;
      }
    }
  }

  .--phone-edit {
    align-items: flex-end;
    display: flex;

    .--code {
      margin-right: 10px;
      // width: 100px;
    }

    .--phone {
      flex: 1;
    }
  }

  .noinput-button {
    background-color: #fff;
    border: 1px solid $border-gray;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    line-height: normal;
    margin: 5px 0 15px;
    padding: 15px 16px;

    @include border-radius(8px);

    .-verified {
      background: url('/img/icons/checkmark.svg') no-repeat center left;
      padding-left: 30px;
    }

    .--action {
      color: $orange;
      font-size: 14px;
    }

    &:hover {
      cursor: pointer;

      .--action {
        text-decoration: underline;
      }
    }
  }
}
