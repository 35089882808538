$skeleton-base-color: lighten(#F7F7F7, 1%);
$skeleton-shine-color: darken($skeleton-base-color, 3.2%);
$skeleton-animation-duration: 1.4s;

@mixin skeleton-background() {
  animation: shine-lines $skeleton-animation-duration ease-in-out infinite;
  background: linear-gradient(90deg, $skeleton-base-color, $skeleton-shine-color, $skeleton-base-color);
}

.job-item-placeholder {
  padding-bottom: 30px;
  opacity: 1;
  animation: fadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;

  .placeholder-content {
    height: 245px !important;
    text-align: center;
    border: 1px solid lighten($border-gray, 2%);
    position: relative;
    color: inherit;
    display: block;
    height: 100%;
    overflow: hidden;
    text-decoration: none;
    @include border-radius(20px);

    .logo {
      width: 100px;
      height: 90px;
      position: relative;
      margin: 20px auto;
      margin-top: 30px;
      overflow: hidden;
      @include border-radius(5px);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 150%;
        height: 150%;
        @include skeleton-background();
      }
    }

    .band {
      height: 25px;
      margin: 8px auto;
      @include border-radius(3px);

      &.placeholder {
        position: relative;
        overflow: hidden;
        background: $skeleton-base-color;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -50%;
          width: 100%;
          height: 100%;
          @include skeleton-background();
        }
      }

      &.no-center {
        margin: 8px 0;

        &.no-top-space {
          margin: 0 0 8px;
        }

        &.right-margin {
          margin-right: 5px;
        }
      }
    }

    h4 {
      font-size: 1.15em;
      margin: 15px 0 5px;
      width: 80%;
      margin: 0 auto;
      margin-top: 20px;
    }

    h5 {
      width: 60%;
      margin: 0 auto;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
