.detail-apply-button {
  .btn {
    background-color: $orange;
    background-position: 0 center;
    display: block;
    position: relative;
    font-size: 20px;
    width: 100%;
    margin: 0;
    text-align: center;

    .btn-content {
      position: relative;
      display: inline-block;
    }

    .icon {
      position: relative;
      display: inline-block;
      background-image: url('/img/icons/pencil-w.svg');
      width: 20px;
      height: 20px;
      top: 2px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 10px;
    }

    &.with-profile {
      background-color: $blue;
      background-position: 0 center;
      position: relative;

      .icon {
        background-image: url('/img/icons/profile-w.svg');
      }

      &:hover {
        background-color: darken($blue, 4%);
      }
    }

    &.deadline {
      background-color: #666;
    }

    &.btn-green {
      background-color: $green;
      &:hover {
        background-color: darken($green, 4%);
      }
    }

    &.btn-orange {
      .icon {
        top: 3px;
        margin-right: 2px;
      }
    }

    &:hover {
      background-color: darken($orange, 2%);
    }
  }

  @media(max-width: $sm-width) {
    .btn {
      .icon {
        display: none;
      }
    }
  }
}
