.detail-skills {
  .detail-skills--title {
    font-weight: 700;
    padding-left: 20px;
    padding-bottom: 5px;
  }
  .flex-wrap {
    padding-left: 10px;
  }
  .ui-card {
    padding: 20px 0;
  }

  .skills-skill {
    border: 1px #f0f0f0 solid;
    @include box-shadow(none);
  }

  .detail-skills--more {
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
    color: $orange;
    text-decoration: underline;
  }
}
