.cee-pricelist {
  padding: 70px 0;

  .btn {
    background-color: $blue;
    display: block;
    margin: 0;
    width: 100%;

    @include border-radius(6px);

    &:hover {
      background-color: darken($blue, 5%);
    }

    &.--contact {
      background-color: #fff;
      border: 1px solid $blue;
      color: $blue;
      padding: 14px 20px;

      &:hover {
        background-color: #fafafa;
      }
    }

    &.--book {
      display: inline-block;
      padding: 14px 20px;
      width: auto;
    }
  }

  .title {
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: $xs-width) {
      font-size: 34px;
    }
  }

  .subtitle {
    font-size: 24px;
  }

  .--space {
    height: 30px;
    width: 30px;
  }

  .--pricing {
    display: flex;
    margin: 60px auto 0;
    max-width: 100%;
    width: 770px;

    @media (max-width: $sm-width) {
      flex-direction: column;
    }

    .-item {
      background-color: #fff;
      border: 1px solid #e6e6e6;
      display: flex;
      flex: 1;
      flex-direction: column;
      font-size: 20px;

      @include ad-card-shadow();
      @include border-radius(8px);
      @include transition();

      &.--blue {
        background-color: $blue;
        border: 1px solid #1ca9d4;
        color: #fff;

        .price {
          border-bottom: 1px solid #1ca9d4;
          border-top: 1px solid #1ca9d4;

          .strong {
            color: #fff;
            font-size: 50px;
          }
        }
      }

      &:hover {
        @include transform(scale(1.02, 1.02));
      }

      .head {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 20px;

        .icon {
          background-color: #1ea6d0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 70%;
          height: 37px;
          margin-bottom: 5px;
          width: 37px;

          @include border-radius(8px);

          &.--boost {
            background-image: url('/img/icons/boost.svg');
          }

          &.--camera {
            background-image: url('/img/icons/camera.svg');
            background-size: 77% auto;
          }
        }

        .strong {
          font-size: 24px;
          font-weight: bold;
        }
      }

      .price-top {
        background-color: #f9f9f9;
        border-top: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
        height: 124px;
        justify-content: center;
        padding: 15px 30px 20px;

        .strong {
          color: $blue;
          font-size: 50px;
          font-weight: bold;
          line-height: 1;
        }

        small {
          margin-top: 4px;
        }
      }

      .price {
        border-bottom: 1px solid #e6e6e6;
        border-top: 1px solid #e6e6e6;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding: 30px 40px;

        .-top {
          margin-bottom: 8px;
        }

        .old {
          align-items: center;
          display: flex;
          justify-content: center;
        }

        .stripe {
          // color: $red;
          font-size: 22px;
          font-weight: 600;
          position: relative;

          &::after {
            background-color: rgba($red, .7);
            content: '';
            height: 3px;
            left: 0;
            position: absolute;
            top: 50%;
            transform: rotate(-18deg);
            width: 100%;
          }
        }

        .discount {
          background-color: #eaf6e6;
          border-radius: 6px;
          color: #32aa05;
          font-size: 14px;
          font-weight: bold;
          margin-left: 10px;
          padding: 2px 5px;
          text-transform: uppercase;
        }

        .strong {
          color: $blue;
          font-size: 45px;
          font-weight: bold;

          small {
            font-size: 30px;
          }
        }
      }

      .bottom {
        padding: 30px;
      }
    }
  }

  .--extras {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0;
    max-width: 100%;
    width: 770px;

    .--space {
      height: 15px;
    }

    .-item {
      background-color: #fff;
      border: 1px solid #e6e6e6;
      display: flex;
      font-size: 20px;
      overflow: hidden;

      @include ad-card-shadow();
      @include border-radius(8px);
      @include transition();

      &:hover {
        @include transform(scale(1.02, 1.02));
      }

      .check {
        background: url('/img/icons/check-list.svg') no-repeat center;
        background-size: auto 26px;
        width: 80px;
      }

      .head {
        background-color: #f9f9f9;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 135px;

        strong {
          color: $text;
          line-height: 1.1;
          margin: 0 15px;
        }
      }

      .text {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 15px 30px 15px 0;
        text-align: left;

        strong {
          font-weight: 600;
        }

        small {
          font-size: 14px;
          margin-top: 4px;
        }
      }

      @media (max-width: $xs-width) {
        flex-direction: column;

        .check {
          height: 60px;
          width: auto;
        }

        .text {
          align-items: center;
          text-align: center;
          padding: 0 15px 15px;
        }
      }
    }
  }

  .--estimate {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    margin-bottom: 30px;
    padding: 30px;

    @include ad-card-shadow();
    @include border-radius(8px);
    @include transition();

    // &:hover {
    //   @include transform(scale(1.02, 1.02));
    // }

    .--slider {
      margin: 25px 50px;

      @media (max-width: $sm-width) {
        margin: 25px 0;
      }

      .noUi-horizontal {
        background: #f3f3f4;
        border: none;
        border-radius: 7px;
        box-shadow: none;
        height: 14px;
      }

      .noUi-connects {
        border-radius: 7px;
      }

      .noUi-base {
        &:after {
          background: $orange;
          bottom: 0;
          box-shadow: 0 0 5px 1px #fff;
          content: '';
          display: block;
          left: var(--left-limit);
          position: absolute;
          top: 0;
          width: 1px;
        }
      }

      .noUi-connect {
        background: $blue;
      }

      .noUi-handle {
        border-radius: 7px;
      }

      .noUi-handle:after,
      .noUi-handle:before {
        background: $blue;
      }

      .noUi-handle,
      .noUi-touch-area {
        &:focus {
          outline: none;
        }
      }
    }

    .--title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .example {
      align-items: flex-start;
      display: flex;
      font-size: 16px;

      .no {
        font-weight: bold;
        margin: 0 15px 0 5px;
      }

      .text {
        margin: 0;
      }
    }
  }

  .--description {
    color: $text-gray;
    font-size: 18px;
    margin-top: 40px;
  }

  .--divider {
    background-color: #e6e6e6;
    height: 1px;
    margin: 60px 0;
  }

  .--more-info {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 18px;
    justify-content: center;
    margin-right: 30px;
    padding: 40px;
  }

  .--pic-list {
    align-items: center;
    margin-top: 30px;

    @media (max-width: $sm-width) {
      flex-direction: column;
    }

    .pic {
      align-self: flex-start;
      flex: 2;
    }

    .list {
      flex: 1;

      p {
        border-left: 4px solid $blue;
        font-size: 18px;
        margin-bottom: 30px;
        padding-left: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .--newsletter {
    background-color: #f9f9f9;
    flex: 2;
    padding: 40px;
    @include border-radius(8px);

    .title {
      font-size: 40px;
    }

    .email {
      margin-right: 20px;
      margin-top: 10px;
      padding: 14px;
      width: 350px;
      @include border-radius(8px);
    }

    .checkbox {
      margin-left: 12px;
    }

    .-inputs {
      text-align: left;
    }

    .--form {
      align-items: flex-end;
      display: flex;
      justify-content: center;

      label {
        cursor: pointer;
        margin-left: 10px;
      }

      a {
        color: $blue;

        &:hover {
          text-decoration: underline;
        }
      }

      .--flex {
        align-items: center;
        font-size: 14px;
      }

      .--book {
        margin-bottom: 1px;
      }
    }
  }
}
