.courses-banner {
  background-color: #fff;
  border: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px 10px;
  border-radius: 13px;
  margin-top: 20px;
  cursor: pointer;
}

.courses-banner--new-label {
  background-color: $green;
  color: #fff;
  font-weight: 700;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 14px;
}

.courses-banner-btn {
  background-color: $orange;
  color: #fff;
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  background-image: url('/img/icons/courses/arrow-right-white.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  padding-right: 22px;
}

.courses-banner-border {
  height: 1px;
  margin: 0 auto;
  margin-top: 25px;
  background-color: #ececec;
}

.courses-banner--hide-md {
  display: inline-block;
}

@media(max-width: 967px) {
  .courses-banner--hide-md {
    display: none;
  }
  .courses-banner-btn {
    padding-right: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    background-image: url('/img/icons/courses/arrow-right-gray.svg');
    background-position: calc(100% - 6px) 50%;
    background-size: 10px;
    border-radius: 6px;
  }
}
