.company-detail {
  h2 {
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    line-height: 1.3;
  }

  p {
    font-size: 20px;
    font-weight: 400;
  }
}

.company-detail--back {
  text-align: center;
  a {
    color: #fff;
    background-color: $blue;
    padding: 15px 30px;
    font-weight: 700;
    text-transform: uppercase;
    @include border-radius(30px);

    &:hover {
      background-color: darken($blue, 4%);
    }
  }
}
