.brand-detail {
  .ember-modal-dialog {
    overflow: hidden;
    padding: 0;

    .default-dialog {
      max-width: 100%;
      padding: 0;
      width: 670px;
    }
  }

  .cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    height: 330px;
    position: relative;

    .gradient {
      background-color: rgba($orange, 0.7);
      height: 100%;
      position: absolute;
      width: 100%;
      @include gradient(-125deg, rgba($orange, 0.5), rgba(#e84f00, 0.9));

      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#59000000',GradientType=0 );
    }

    .social {
      margin: 27px 30px;

      a {
        margin-right: 8px;
      }
    }

    .close {
      position: absolute;
      right: 30px;
      top: 27px;
    }

    .header {
      align-items: flex-end;
      bottom: 0;
      display: flex;
      position: absolute;

      .logo {
        background-color: #fff;
        margin: 0 25px 0 30px;
        padding: 17px 15px 0;

        @include border-radius(20px 20px 0 0);

        img {
          max-height: 100px;
          max-width: 123px;
        }
      }

      .title {
        margin: 0 20px 15px 0;

        h2,
        h3 {
          font-weight: normal;
          margin: 0;
        }

        h2 {
          font-size: 35px;
        }

        h3 {
          font-size: 20px;
          margin-top: 5px;
        }
      }
    }
  }

  .body {
    font-size: 17px;
    padding: 50px;
  }
}
