.detail-location {
  cursor: pointer;
  position: relative;
  @include transition;

  .detail-location--content {
    padding: 20px;
    display: flex;
    align-items: center;

    .pin-icon {
      background-image: url('/img/icons/pin_o.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 25px;
      display: inline-block;
      margin-right: 15px;
    }
  }

  .ui-card {
    background-image: url('/img/icons/arrow-right-o.svg');
    background-position: calc(100% - 20px) 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    &:hover {
      background-color: #fcfcfc;
    }
  }
}
