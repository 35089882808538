.detail-list {
  border-bottom: 1px #F4F4F4 solid;
  padding: 20px 0;
  .detail-list--title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .detail-list--item {
    margin: 3px 15px;
    font-size: 16px;
    line-height: 28px;

    &:before {
      content: '';
      background: url('/img/icons/dot_o.svg') no-repeat;
      background-size: 100% auto;
      display: inline-block;
      height: 9px;
      margin-left: -14px;
      margin-right: 7px;
      width: 7px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &:first-of-type {
    border-top: 1px $border-gray solid;
  }
  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.detail-content--footer {
  margin-top: 20px;
}
