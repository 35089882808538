.application-card {
  ul {
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px #ebebeb solid;
      display: flex;
      font-size: 18px;
      margin: 0;
      padding: 20px;
      text-align: left;

      p {
        margin: 0 0 0 20px;
        position: relative;
        width: calc(100% - 60px);
      }

      .icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 30px;

        &.checkmark {
          background-image: url('/img/icons/checkmark.svg');
        }

        &.message {
          background-image: url('/img/icons/message.svg');
        }

        &.logo {
          background-image: url('/img/alfred.svg');
        }

        &.at {
          background-image: url('/img/icons/at.svg');
        }

        &.layout {
          background-image: url('/img/icons/layout.svg');
        }

        &.anon {
          background-image: url('/img/icons/profile-g.svg');
        }

        &.email {
          background-image: url('/img/icons/email-dark.svg');
        }
      }

      &:before {
        display: none;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
