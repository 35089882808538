input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
figure {
    margin: 0;
}
.img-responsive {
    display: block;
    height: auto;
    max-width: 100%;
}
.img-rounded {
    border-radius: 6px;
}
.img-thumbnail {
         -o-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: inline-block;
    height: auto;
    line-height: 1.42857143;
    max-width: 100%;
    padding: 4px;
            transition: all .2s ease-in-out;
}
.img-circle {
    border-radius: 50%;
}
hr {
    border: 0;
    border-top: 1px solid #ddd;
    margin-bottom: 5px;
    margin-top: 5px;
}
.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
[role='button'] {
    cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: inherit;
    font-weight: 400;
    line-height: 1.1;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    font-weight: 400;
    line-height: 1;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-bottom: 10px;
    margin-top: 20px;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
    font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-bottom: 10px;
    margin-top: 10px;
}
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
    font-size: 75%;
}
h1,
.h1 {
    font-size: 2.2em;
}
h2,
.h2 {
    font-size: 2em;
    margin: 15px 0;
}
h3,
.h3 {
    font-size: 20px;
}
h4,
.h4 {
    font-size: 20px;
    line-height: 1.6;
}
h5,
.h5 {
    font-size: 18px;
}
h6,
.h6 {
    font-size: 14px;
}
p {
    line-height: 1.4;
    margin: 0 0 10px;
}
.lead {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 20px;
}
@media (min-width: 768px) {
    .lead {
        font-size: 21px;
    }
}
small,
.small {
    font-size: 90%;
}
.large {
    font-size: 1.3em;
}
mark,
.mark {
    background-color: #fcf8e3;
    padding: .2em;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-justify {
    text-align: justify;
}
.text-nowrap {
    white-space: nowrap;
}
.text-lowercase {
    text-transform: lowercase;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}
.text-muted {
    color: #777;
}
.text-primary {
    color: #337ab7;
}
a.text-primary:hover,
a.text-primary:focus {
    color: #286090;
}
.text-success {
    color: #3c763d;
}
a.text-success:hover,
a.text-success:focus {
    color: #2b542c;
}
.text-info {
    color: #31708f;
}
a.text-info:hover,
a.text-info:focus {
    color: #245269;
}
.text-warning {
    color: #8a6d3b;
}
a.text-warning:hover,
a.text-warning:focus {
    color: #66512c;
}
.text-danger {
    color: #a94442;
}
a.text-danger:hover,
a.text-danger:focus {
    color: #843534;
}
.bg-primary {
    background-color: #337ab7;
    color: #fff;
}
a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #286090;
}
.bg-success {
    background-color: #dff0d8;
}
a.bg-success:hover,
a.bg-success:focus {
    background-color: #c1e2b3;
}
.bg-info {
    background-color: #d9edf7;
}
a.bg-info:hover,
a.bg-info:focus {
    background-color: #afd9ee;
}
.bg-warning {
    background-color: #fcf8e3;
}
a.bg-warning:hover,
a.bg-warning:focus {
    background-color: #f7ecb5;
}
.bg-danger {
    background-color: #f2dede;
}
a.bg-danger:hover,
a.bg-danger:focus {
    background-color: #e4b9b9;
}
ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}
ol {
    margin-bottom: 10px;
    margin-top: 0;
}
ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}
.list-unstyled {
    list-style: none;
    padding-left: 0;
}
.list-inline {
    list-style: none;
    margin-left: -5px;
    padding-left: 0;
}
.list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}
dl {
    margin-bottom: 20px;
    margin-top: 0;
}
dt,
dd {
    line-height: 1.42857143;
}
dt {
    font-weight: bold;
}
dd {
    margin-left: 0;
}
@media (min-width: 768px) {
    .dl-horizontal dt {
        clear: left;
        float: left;
        overflow: hidden;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 160px;
    }
    .dl-horizontal dd {
        margin-left: 180px;
    }
}
abbr[title],
abbr[data-original-title] {
    border-bottom: 1px dotted #777;
    cursor: help;
}
.initialism {
    font-size: 90%;
    text-transform: uppercase;
}
blockquote {
    font-size: 17.5px;
    margin: 0 0 20px;
    padding: 10px 20px;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
    color: #777;
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: '\2014 \00A0';
}
.blockquote-reverse,
blockquote.pull-right {
    border-left: 0;
    border-right: 5px solid #eee;
    padding-left: 0;
    padding-right: 15px;
    text-align: right;
}
.blockquote-reverse footer:before,
blockquote.pull-right footer:before,
.blockquote-reverse small:before,
blockquote.pull-right small:before,
.blockquote-reverse .small:before,
blockquote.pull-right .small:before {
    content: '';
}
.blockquote-reverse footer:after,
blockquote.pull-right footer:after,
.blockquote-reverse small:after,
blockquote.pull-right small:after,
.blockquote-reverse .small:after,
blockquote.pull-right .small:after {
    content: '\00A0 \2014';
}
address {
    font-style: normal;
    line-height: 1.42857143;
    margin-bottom: 20px;
}
.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after {
    content: ' ';
    display: table;
}
.clearfix:after,
.dl-horizontal dd:after {
    clear: both;
}
