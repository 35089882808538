.profile-badge {
  position: relative;

  .-locales {
    background-color: #fff;
    border: 1px solid $border;
    border-top: none;
    display: flex;
    justify-content: space-around;
    padding: 8px 10px;
    @include border-radius(0 0 13px 13px);

    img {
      border: 2px solid #fff;
      height: 32px;
      width: 32px;
      @include border-radius(50%);

      &.active,
      &:hover {
        border-color: $orange;
        cursor: pointer;
      }
    }
  }

  .--notification {
    background-color: $red;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    margin-left: 20px;
    min-width: 17px;
    padding: 2px 4px;
    text-align: center;

    @include border-radius(4px);

    &.yellow {
      background-color: $yellow;
    }

    &.green {
      background-color: $green;
    }
  }
}

.profile-badge--name {
  align-items: center;
  background: url('/img/icons/nav_dropdown.svg') no-repeat right center;
  background-size: auto 9px;
  color: #fff;
  display: flex;
  font-weight: 600;
  padding-right: 25px;
}

.profile-badge--thumb {
  background-color: $gray;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #fff;
  height: 38px;
  margin: 0 10px 0 0;
  overflow: hidden;
  position: relative;
  width: 38px;

  @include border-radius(50%);

  &:before {
    bottom: 0;
    content: attr(data-initials);
    font-size: 20px;
    left: 0;
    line-height: 36px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }
}

@media(max-width: $md-width) {
  .profile-badge {
    margin: 15px auto;
    max-width: 400px;
    padding: 0 20px;
    width: 100%;

    .profile-badge--name {
      display: none;
    }

    .-drawer {
      box-shadow: none;
      padding: 0;

      a {
        background-color: #fff;
        color: $text;
        display: block;
        font-size: 16px;
        margin: 0;
        padding: 12px;

        &:hover {
          background-color: #fff;
          color: $orange;
        }
      }
    }
  }
}
