.select-items {
    .select-item {
        cursor: pointer;
        text-indent: 35px;
        padding: 15px;
        margin: 0;
        font-size: 18px;
        padding-left: 20px;
        background-position: 22px 50%;
        background-image: url('/img/icons/oval.svg');
        background-repeat: no-repeat;
        background-size: 22px;
        border-bottom: 1px #EBEBEB solid;
        display: flex;
        @include noselect();
        
        &:before {
            display: none;
        }
        &.selected {
            background-color: darken(#fff, 4%);
            font-weight: 700;
            background-image: url('/img/icons/checkmark.svg');
        }
    
        &:last-of-type {
            border-bottom: none;
        }
    }
}