.video {
    select {
        background: #eee url('/img/icons/select_blue.svg') no-repeat calc(100% - 10px) center;
        background-size: auto 8px;
        border: 1px solid #ddd;
        font-size: 13px;
        margin: 15px 0 0;
        outline: none;
        padding: 4px 30px 4px 15px;
        -webkit-appearance: none;
        @include border-radius(30px);
    }

    &.info {
        .video-stream {
            video {
                height: 165px;
            }
        }
    }
}

.video-stream {
    margin: 30px 0;

    video {
        background: url('/img/loader-orange.svg') no-repeat center center;
        border: 1px solid #C7C7C7;
        height: 225px;
        @include border-radius(10px);
    }

    .flip-y {
        @include transform(rotateY(180deg));
    }
}

.sound-test {
    margin-top: 25px;
    margin-bottom: 50px;

    .bit {
        background: #eee;
        border-radius: 6px;
        display: inline-block;
        height: 22px;
        margin: 0 4px;
        position: relative;
        width: 9px;

        @include transition(background-color .1s linear);

        &.orange {
            background-color: $orange;
        }

        &.lblue {
            background-color: lighten($blue, 20%);
        }

        &.dblue {
            background-color: darken($blue, 20%);
        }

        &.blue {
            background-color: $blue;
        }

        &.red {
            background-color: $red;
        }

        &.max {
            background-color: $blue;
        }
        &.maxo {
            background-color: $orange;
        }
        &.maxr {
            background-color: $red;
        }

        &.mid {
            &:after {
                content: '';
                position: absolute;
                border-color: transparent transparent $blue transparent;
                border-style: solid;
                border-width: 5px;
                left: 0;
                top: 100%;
            }
        }
    }
}
