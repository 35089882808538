@media (min-width: $md-width) {
  .close-nav,
  .mobile-nav {
    display: none;
  }
}

@media(max-width: $lg-width) {
  .-hide-lg {
    display: none;
  }
}

@media(max-width: $md-width) {
  .-hide-md {
    display: none;
  }

  .mobile-nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 7px 0;

    .--menu {
      background: url('/img/icons/menu.svg') no-repeat right center;
      background-size: 28px auto;
      height: 50px;
      width: 46px;
    }
  }

  .header--main {
    background: $orange url('/img/');
    overflow: auto;

    .nav {
      background-color: rgba(darken($orange, 3%), .97);
      bottom: 0;
      display: none;
      float: none;
      left: 0;
      margin: 0;
      max-height: 100%;
      overflow-y: auto;
      position: fixed;
      right: 0;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 999;

      @include box-shadow(0 20px 140px rgba(#000, .7));

      &.-show {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .alfred {
        margin-bottom: 15px;
        margin-top: 10px;
      }

      .companies {
        align-self: stretch;
        font-size: 18px;
        margin: 0 20px;
      }

      .-links {
        align-items: stretch;
        flex-direction: column;

        .resources {
          background: none;
          margin: 0;
          padding: 0;

          &:hover {
            font-weight: 300;
          }

          a {
            margin: 0;

            &:hover {
              font-weight: 600;
            }

            &.active {
              color: #fff;
              font-weight: bold;
              letter-spacing: inherit;
            }
          }
        }

        > a {
          font-size: 22px;
          font-weight: 300;
          letter-spacing: 0;
          margin: 0;
          padding: 15px;

          &.profile {
            font-weight: 600;
          }

          &.active {
            background: none;
          }

          &:hover {
            border-color: transparent;
          }

          &.profile {
            margin: 20px 5px;
            padding: 0 15px;

            .dropdown {
              background-color: darken($orange, 6%);
              box-shadow: none;
              display: block;
              padding: 0;
              position: static;

              a {
                color: inherit;
                display: block;
                font-size: 20px;
                font-weight: 300;
                margin: 0;
                padding: 15px;

                &.active {
                  font-weight: 400;
                }

                &:hover {
                  color: inherit;
                }
              }
            }
          }
        }
      }

      .-buttons {
        align-items: stretch;
        flex-direction: column-reverse;
      }

      .close-nav {
        background: url('/img/icons/close.svg') no-repeat center center;
        background-size: 20px auto;
        height: 48px;
        margin: 0;
        position: absolute;
        right: 14px;
        top: 15px;
        width: 48px;
      }
    }
  }
}

@media (max-width: $md-width) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  footer {
    text-align: center;

    .alfred {
      float: none;
    }
  }

  .page-header {
    padding-right: 80px;

    h1 {
      font-size: 36px;
    }
  }

  .wrapbox {
    font-size: 20px;

    h2 {
      font-size: 34px;
    }
  }

  .wrapbox.paddbox {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .paddbox {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

#courses-container {
  position: relative;
  z-index: 0;

  &.header-is-sticky {
    padding-top: 105px;
  }
}

@media (max-width: 767px) {
  .ember-modal-overlay.modal-popup {
    .ember-modal-dialog {
      @include border-radius(0);
    }
  }

  .default-dialog {
    height: 100vh;
    max-height: 100vh;
    padding: 20px 25px;
    position: relative;
    word-break: break-word;

    h2 {
      font-size: 36px;
      margin-bottom: 30px;
    }

    > h2 {
      margin-top: 40px;
    }

    &.wide {
      max-width: 100%;
    }

    h2.text-center {
      text-align: left;
    }

    .form {
      .btn {
        background-image: none;
        font-size: 19px;
        padding: 12px;
      }
    }

    .mob-close {
      background: url('/img/icons/close.svg') no-repeat center center;
      display: block;
      height: 60px;
      position: absolute;
      right: 0;
      top: 0;
      width: 52px;
      z-index: 5;

      &.dark {
        background-image: url('/img/icons/close_b.svg');
      }
    }
  }
}

@media(max-width: 579px) {
  #courses-container {
    position: relative;
    padding-top: 128px;
  }
}
