.alfred {
  svg {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

.header-video {
  height: auto;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 0;

  @include center();
}

.--dropdown {
  position: relative;

  a {
    color: inherit;
  }

  .-drawer {
    display: none;
    left: auto;
    position: absolute;
    right: 50%;
    top: 100%;
    transform: translate(50%, 0);
    width: max-content;
    z-index: 1;

    a {
      background-color: #fff;
      border: 1px solid $border;
      color: $text;
      display: block;
      font-size: 15px;
      font-weight: 400;
      margin: 0;
      padding: 10px 20px;
      text-decoration: none;
      white-space: nowrap;

      &.logout {
        color: $red;
      }

      &:first-child {
        @include border-radius(13px 13px 0 0);
      }

      &:last-child {
        border-top: none;

        @include border-radius(0 0 13px 13px);
      }

      &:not(:first-child):not(:last-child) {
        border-top: none;
      }

      &.active {
        color: $orange;
        font-weight: bold;
      }

      &:hover {
        background-color: #ffead9;
        color: $orange;
      }
    }
  }

  &:hover {
    .-drawer {
      display: block;
      padding-top: 5px;

      @include transition();
    }
  }

  @media (max-width: $md-width) {
    .-drawer {
      display: block;
      position: static;
      transform: none;
      width: 100%;

      a {
        background-color: transparent;
        border: none;
        color: inherit;
        display: block;
        font-size: inherit;
        font-weight: inherit;
        padding: 15px;

        &:hover {
          background-color: transparent;
          color: inherit;
        }
      }
    }

    &:hover {
      .-drawer {
        padding-top: 0;
      }
    }
  }
}

.header--main {
  background-color: $orange;
  background-size: cover;
  color: #fff;
  position: relative;
  z-index: 44;

  h1 {
    small {
      color: rgba(#fff, 0.7);
      font-size: 38px;
      font-weight: 300;
    }
  }

  a {
    &.focus-visible {
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.8);
    }
  }

  &.with-video {
    position: relative;
    z-index: 5;

    background-color: $blue;
  }

  &.-blue {
    background-color: $blue;
  }

  .badge {
    background-color: #eb6b08;
    border: 2px solid #fff;
    font-size: 14px;
    font-weight: bold;
    min-width: 23px;
    position: absolute;
    right: -4px;
    text-align: center;
    top: -5px;

    @include border-radius(20px);
  }

  .blue {
    color: $blue;
  }

  .nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 14px 0;

    .-links {
      display: flex;
      justify-content: center;

      > a {
        color: #fff;
        letter-spacing: 0.235px;
        margin: 0 5px;
        padding: 7px 5px 9px;
        text-align: center;
        white-space: nowrap;

        &:hover {
          font-weight: 600;
          letter-spacing: 0;
        }

        &.active {
          background: url('/img/icons/circle.svg') no-repeat center bottom;
          font-weight: bold;
          letter-spacing: inherit;
        }
      }

      .courses {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;

        &::after,
        &::before {
          content: '';
          height: 100%;
          opacity: 0.7;
          position: absolute;
          top: 0;
          width: 1px;
        }

        &::before {
          background: url('/img/icons/nav_line.svg') no-repeat left center;
          left: 0;
        }

        &::after {
          background: url('/img/icons/nav_line.svg') no-repeat right center;
          right: 0;
        }

        @media (max-width: $md-width) {
          &::after,
          &::before {
            display: none;
          }
        }
      }

      .resources {
        background: url('/img/icons/nav_dropdown.svg') no-repeat right center;
        padding-right: 17px;
      }
    }

    .-buttons {
      align-items: center;
      display: flex;
    }

    .companies {
      background-color: rgba(#000, 0.2);
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      margin-right: 10px;
      padding: 8px 15px;

      @include border-radius(20px);

      &:hover {
        background-color: rgba(#000, 0.25);
      }

      @media (max-width: 400px) {
        margin: 0 15px;
      }
    }
  }
}

.locales {
  flex-shrink: 0;
  margin-right: 5px;
  position: relative;
  overflow: hidden;

  img {
    border: 2px solid #fafafa;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    width: 28px;
    margin: 0 5px;
  }

  .menu {
    background-color: #fff;
    border: 1px solid $border;
    left: auto;
    margin: 0 -1px 0 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 5;

    @include border-radius(13px);

    img {
      border-color: #eee;
      display: block;
      margin: 5px;
      max-width: none;

      &:hover {
        border-color: $orange;
      }
    }
  }

  &:hover {
    overflow: visible;
    > img {
      margin-bottom: 5px;

      @include transition();
    }

    .menu {
      opacity: 1;

      @include transition();
    }
  }

  @media (max-width: $md-width) {
    margin: 10px 0 0;

    .chosen-locale {
      display: none;
    }

    .menu {
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      opacity: 1;
      position: static;
    }
  }
}

.main-footer {
  border-top: 1px solid #eee;

  .socialbox {
    a {
      display: inline-block;
      margin: 10px 20px;

      img {
        height: 65px;
      }
    }
  }

  footer {
    background-color: $orange;
    color: #fff;
    font-size: 14px;
    padding: 30px 0;
    width: 100%;

    .alfred {
      margin: 0;
    }

    p {
      margin-top: 10px;
    }

    a {
      color: #fff;
      display: inline-block;
      margin-left: 25px;
      margin-top: 10px;
      text-transform: uppercase;
    }

    &.sticky {
      bottom: 0;
      position: fixed;
    }
  }
}

.sticky-footer {
  bottom: 20px;
  color: darken(#ddd, 20%);
  left: 20px;
  max-width: 250px;
  position: fixed;
}
