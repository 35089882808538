.testimonials {
  background-color: #fff;

  .grid {
    margin-top: 35px;

    > div {
      margin-top: 15px;
      min-height: 320px;
    }

    .logo {
      line-height: 73px;
      margin-bottom: 5px;
    }

    p {
      font-size: 18px;
      padding: 0 20px;
    }

    .name {
      margin-bottom: 3px;
    }

    span {
      color: $text-gray;
    }
  }

  &.darker {
    background-color: #FBFAF9;
  }
}

.testimonials--signup {
  border-top: 1px
}
