#mark-as-hired {
  img {
    position: relative;
    top: 80px;
  }
}

#companies {
  background-color: #fff;

  .pricing {
    a {
      color: $ds-blue-500;
    } 
  }
  h2 {
    margin-bottom: 30px;
    font-size: 38px;
    font-weight: 600;
    line-height: 1.3;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 15px;
  }

  p {
    font-size: 18px;
    font-weight: 400;

    @media (max-width: $xs-width) {
      font-size: 18px;
    }
  }

  .btn-blue {
    text-transform: uppercase;
    font-size: 16px;
  }

  .top-space {
    margin-top: 30px;
  }

  .header-video {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .capacent-logo {
    display: inline-block;
    position: relative;
    top: 14px;
    margin: 0 5px;
    width: 180px;
    height: 40px;
    background-image: url('/img/capacent.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .header {
    margin: 8vh 0;

    h1 {
      font-size: 47px;
      font-weight: bold;
      margin-left: 140px;
      margin-right: 140px;
      text-shadow: 0 1px 3px rgba(#000, 0.27);

      @media (max-width: $sm-width) {
        font-size: 41px;
        margin-left: 0;
        margin-right: 0;
      }

      @media (max-width: $xs-width) {
        font-size: 36px;
      }
    }

    h2 {
      font-size: 22px;
      text-shadow: 0 1px 2px rgba(#000, 0.14);
    }

    .btn {
      font-size: 20px;
      padding: 13px 40px;

      &:hover {
        @include transition();
        @include transform(scale(1.04, 1.04));
      }
    }

    .btn-white {
      color: $blue;
      margin: 0 15px;
      @include box-shadow(0 3px 6px rgba(#000, 0.2));

      @media (max-width: 540px) {
        margin-right: 0;
      }
    }

    .btn-brd {
      background: rgba(54, 169, 226, 0.8) url('/img/icons/viplay.svg') no-repeat
        23px center;
      border: 2px solid #fff;
      padding: 11px 35px 11px 50px;
      &.no-bg {
        background-image: none;
        padding-left: 35px;
      }
    }

    .btn-log {
      background: rgba(#36a9e2, 0.8);
      border: 2px solid #fff;
      padding: 11px 35px;
    }

    .btn-meet {
      border: 2px solid #fff;
      background: none;
      position: relative;
      top: 10px;

      &:hover {
        background: $blue;
      }
    }
  }

  .visir {
    background-color: #fbfaf9;
    border-top: 1px solid #efefef;
  }

  .box-logos {
    background-color: $light-gray;
    height: 120px;
    overflow: hidden;
    padding: 25px 0;
    position: relative;
    width: 100%;

    .marquee {
      animation: marquee 140s linear infinite;
      display: block;
      height: 70px;
      overflow: hidden;
      position: absolute;
      width: 6900px;

      img {
        float: left;
        height: 70px;
        margin: 0 20px;
        opacity: 0.5;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    @keyframes marquee {
      0% {
        left: 0;
      }

      100% {
        left: -3450px;
      }
    }
  }

  .box-reports {
    .img-1 {
      width: 565px;
    }
  }

  footer {
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.signupModal {
  .ember-modal-dialog {
    overflow: hidden;
    padding: 0;

    .default-dialog {
      padding: 0;
    }
  }

  .pd {
    padding: 25px 60px 0;
  }

  .left-side {
    background: url('/img/gradient_blue.jpg') no-repeat -175px center;
    background-size: cover;
    color: #fff;
    min-height: 740px;

    h3 {
      line-height: 2.2rem;
      font-weight: 600;
    }
    .alfred {
      margin-bottom: 25px;
    }

    .space {
      img {
        margin-top: 7px;
      }
    }

    .list {
      font-size: 17px;
      margin-top: 38px;

      .row {
        margin-bottom: 30px;
        margin-top: 30px;
      }

      img {
        vertical-align: top;
      }
    }
  }

  .form {
    input {
      padding: 14px 20px;
    }

    button {
      padding: 15px;
    }

    .alert {
      font-size: 14px;
      margin: -5px 10px 5px;
      text-align: right;
    }

    .terms {
      margin: $ds-space-2 0;
      display: flex;
      align-items: center;
      a:hover {
        text-decoration: underline;
      }

      label {
        cursor: pointer;
        margin: 0;
        margin-left: $ds-space-3;
        text-transform: none;
      }
    }
  }

  .success {
    img {
      margin: 65px 0 15px;
    }

    p {
      font-size: 23px;
      font-weight: 300;

      strong {
        font-weight: normal;
      }
    }
  }

  .loader-wrap {
    background-color: rgba(#fff, 0.6);
    bottom: 0;
    left: 0;
    min-height: 550px;
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 20;

    .loader {
      left: 50%;
      position: absolute;
      top: 50%;

      @include center();
    }
  }
}

.video-modal {
  .ember-modal-dialog {
    background-color: #000;
    overflow: hidden;
    padding: 0;

    .default-dialog {
      height: 45vw;
      padding: 0;
      position: relative;
      width: 80vw;

      .close {
        z-index: 10;
      }

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}

.company-form {
  input {
    padding: 15px 18px;
  }

  .input-wrap {
    &.required {
      &::after {
        content: '*';
        font-size: 26px;
        float: left;
        color: $orange;
        right: 20px;
        position: absolute;
        top: 14px;
        z-index: 5;
      }
    }
  }

  .btn {
    margin: 25px auto 0;
    padding: 10px 30px;
    width: auto;
  }
}
.companies--section {
  &:nth-of-type(2n) {
    border: 1px solid $border-gray;
    background-color: #fbfaf9;
  }
}

@media (max-width: 991px) {
  .signupModal {
    .alfred {
      display: none;
    }

    .right-side {
      padding-bottom: $ds-space-4;
    }

    .left-side {
      display: none;
    }
  }
  #companies {
    .header {
      margin: 5vh 0;
    }

    .signupModal {
      .pd {
        padding: 25px 40px;
      }
    }
  }
}

@media (max-width: 540px) {
  .btn-white {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .btn-header {
    width: 100%;
  }
}
