.pika-label {
  background: #fff url('/img/icons/select_blue.svg') no-repeat calc(100% - 5px) center;
  background-size: 7px auto;
  padding: 3px 17px 3px 7px;

  &:hover {
    background-color: #eee;
    @include border-radius(15px);
  }
}

.pika-next,
.pika-prev {
  opacity: 0.2;
}

.date-picker {
  display: flex;

  .ember-power-select-trigger {
    padding: 14px 10px;
  }
}
