@import './tailwind';
@import './react-slick';
@import 'company-profile-widget';
@import 'ember-modal-dialog/ember-modal-structure';
@import 'ember-modal-dialog/ember-modal-appearance';

@import 'base/variables';
@import 'helpers/mixins';
@import 'helpers/normalize';
@import 'helpers/helpers';
@import 'base/grid24';
@import 'base/type';
@import 'base/flex';
@import 'base';
// @import 'components/alfred';
@import 'components/main';
@import 'components/env-strip';
@import 'components/mobile-banner';
@import 'components/notifications';
@import 'components/login-button';
@import 'components/search-bar';
@import 'components/check-box';
@import 'components/pickers';
@import 'components/form';
@import 'components/tables';
@import 'components/loader';
@import 'components/screen-loader';
@import 'components/categories';
@import 'components/jobs';
@import 'components/videoscreen';
@import 'components/video';
@import 'components/upload-progress';
@import 'components/cookies';
@import 'components/profile-login';
@import 'components/mobile-categories';
@import 'components/job-apply';
@import 'components/translate-banner';
@import 'components/apply/incomplete-profile';
@import 'components/apply/update-profile';
@import 'components/apply/application-summary';
@import 'components/apply/application-questions';
@import 'components/apply/progress-bar';
@import 'components/apply/select-item';
@import 'components/apply/apply-processing';
@import 'components/apply/application-card';
@import 'components/apply/application-summary-skills';
@import 'components/logo-placeholder';
@import 'components/job-item-placeholder';
@import 'components/profile-thumb';
@import 'components/vi-features';
@import 'components/apply/e-sign';
@import 'components/job-filter';
@import 'components/filters-tags';
@import 'components/hire-package';
@import 'components/hire-question';
@import 'components/country-code';
@import 'components/companies/box-alfrello';
@import 'components/companies/image-section';
@import 'components/companies/box-vi';
@import 'components/companies/testimonials';
@import 'components/companies/pricing';
@import 'components/companies/pricing-item';
@import 'components/companies/cee-pricelist';
@import 'components/companies/company-features';
@import 'components/categories-header-is';
@import 'components/summerjobs-btn';
@import 'components/job-filters';
@import 'components/sticky-nav';
@import 'components/detail/detail-breadcrumbs';
@import 'components/detail/detail-content';
@import 'components/ui/ui-card';
@import 'components/ui/empty-message';
@import 'components/detail/brand-detail';
@import 'components/detail/detail-info';
@import 'components/detail/detail-apply-button';
@import 'components/detail/detail-location';
@import 'components/detail/detail-meta';
@import 'components/detail/detail-more-jobs';
@import 'components/detail/detail-skills';
@import 'components/detail/detail-list';
@import 'components/detail/company-profile-card';
@import 'components/detail/language-requirements';

@import 'components/user/empty';
@import 'components/user/watch';
@import 'components/user/inbox';
@import 'components/user/profile';
@import 'components/user/settings';

@import 'components/job-item';
@import 'components/skills/manage-skills';
@import 'components/skills/manage-skills-header';
@import 'components/skills/manage-skills-list';
@import 'components/skills/manage-skills-list-item';
@import 'components/skills/skills-skill';
@import 'components/skills/skills-search-results';
@import 'components/skills/skills-context-switcher';
@import 'components/skills/own-skills';
@import 'components/skills/empty-screen';
@import 'components/profile/profile-actions';
@import 'components/profile/profile-card';
@import 'components/profile/profile-card-add-action';
@import 'components/profile/profile-card-editable-item';
@import 'components/profile/profile-bio';
@import 'components/profile/profile-attachment';
@import 'components/profile/profile-links';
@import 'components/profile/profile-languages';
@import 'components/profile/profile-experiences';
@import 'components/profile/profile-education';
@import 'components/profile/profile-about-me';
@import 'components/profile/profile-skills';
@import 'components/profile/profile-skills-empty';
@import 'components/favorite-job/favorite-job';
@import 'components/ui/animated-modal';
@import 'components/header/header-main';

@import 'components/announcements/announcement-modal';
@import 'components/header/profile-badge';

@import 'components/application-message/remote-interview';
@import 'components/application-message/message-attachment';
@import 'components/application-message/message-attachment-meta';
@import 'components/application-message/message-attachment-actions';

@import 'components/newsletter-signup';

@import 'components/company-detail';
@import 'components/courses-banner';

@import 'components/christmasjobs-btn';

@import 'page/index';
@import 'page/category';
@import 'page/job-detail';
@import 'page/profile';
@import 'page/companies';
@import 'page/videointerviews';
@import 'page/app';
@import 'page/about';
@import 'page/faq';
@import 'page/summerjobs';
@import 'page/watch-unsubscribe';
@import 'page/company-profiles';
@import 'page/brands';
@import 'page/courses';
@import 'page/brand-jobs';

@import 'mobile/base';
$ember-power-select-selected-background: $border-gray;
$ember-power-select-highlighted-background: $blue;
$ember-power-select-border-color: $border-gray;
@import "ember-power-select";

.ember-power-select-status-icon {
  right: 15px;
}

.ember-power-select-options {
  li {
    font-size: 15px;
    margin: 0;

    &:before {
      display: none;
    }
  }
}
