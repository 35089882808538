.profile-skills {
  .flex-wrap {
    margin: 19px;
  }

  .skills-skill {
    border: 1px #f0f0f0 solid;
  }

  .profile-skills--show-all {
    text-align: center;
    color: $orange;
    margin-bottom: 20px;

    span {
      cursor: pointer;
    }

    &:hover {
      color: darken($orange, 5%);
    }
  }
}
