.job-apply {
  .ember-modal-dialog {
    overflow: hidden;
    padding: 0;

    .default-dialog {
      padding: 0;
    }
  }

  .pd {
    padding: 40px;
  }

  .cover {
    background-size: cover;
    color: #fff;
    padding: 35px 15px 25px;
    position: relative;
    //background-color: $orange;

    @include gradient(0deg, $orange, lighten($orange, 8%));

    h2 {
      color: #fff;
      font-size: 26px;
      margin: 0;
    }

    p {
      font-size: 18px;
      margin: 10px 0 3px;
    }

    .images {
      position: relative;
    }

    .image {
      display: inline-block;
      margin: 0 0 10px;
    }

    .thumb {
      background-color: #fff;
      z-index: 2;

      @include box-shadow(0 0 4px 0 rgba(0, 0, 0, .04));

      &.brand {
        margin-left: -40px;
        position: relative;
        z-index: 1;
      }
    }

    &.green {
      @include gradient(90deg, #32aa05, #52cb25);
    }

    &.orange {
      @include gradient(90deg, #d86305, #f37916);
    }
  }

  .content {
    padding: 40px 55px;

    h2 {
      font-size: 30px;
      font-weight: normal;
      margin: 0 0 15px;
    }

    .btn {
      font-size: 16px;
      padding: 13px;
    }

    .stats {
      font-size: 20px;
      margin: 15px 0 20px;
    }
  }

  .profile-icon {
    margin-bottom: 10px;
    width: 35px;
  }

  .red {
    color: $red;
  }

  .questions {
    .go-back {
      float: left;
      margin-left: -16px;

      img {
        width: 16px;
      }
    }

    h2 {
      margin-top: 25px;
    }

    textarea {
      min-height: 200px;
      outline: none;
      padding: 14px 20px;
      width: 100%;

      @include border-radius(8px);
    }

    .date {
      background: #fff url('/img/icons/select_blue.svg') no-repeat
      calc(100% - 20px) center;
      background-size: 9px auto;
      padding: 14px 42px 14px 20px;

      @include border-radius(8px);
    }

    .options {
      li {
        margin: 2px 0;

        a {
          color: inherit;
          display: inline-block;
          padding: 5px 15px;

          @include border-radius(20px);

          &.active {
            background-color: $blue;
            color: #fff;
          }
        }

        &:before {
          display: none;
        }
      }
    }

    button {
      margin-top: 30px;
    }
  }

  .with-profile-logout {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 80% auto;
    padding-bottom: 190px;

    h2 {
      font-weight: 30px;
      font-weight: normal;
    }

    h3 {
      font-weight: 26px;
      font-weight: normal;
    }

    .btn-ico {
      font-size: 26px;
      width: 85%;
    }

    .lined {
      margin: 25px 0;
    }
  }

  .actions {
    border-top: 1px #ebebeb solid;

    .action-btn,
    button,
    label {
      background: none;
      color: #c3c3c3;
      cursor: pointer;
      float: left;
      font-weight: 700;
      outline: none;
      padding: 20px 0;
      text-transform: uppercase;
      width: 50%;

      &:first-of-type {
        border-right: 1px #ebebeb solid;
      }

      &.blue {
        color: $orange;
      }

      &.green {
        color: $green;
      }

      &.dark-gray {
        color: #454545;
      }

      &:hover {
        background-color: darken(#fff, 2%);
      }
    }

    &.single {
      button {
        width: 100%;
      }
    }
  }

  @media (max-width: $sm-width) {
    .ember-modal-dialog {
      border-radius: 20px !important;
    }

    .default-dialog {
      height: auto;
      max-width: 350px;
    }
  }
}
