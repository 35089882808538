.upload-progress {
    margin: 40px 6vw;

    .progress-bar {
        background-color: #eee;
        height: 15px;
        overflow: hidden;
        position: relative;
        width: 100%;

        @include border-radius(20px);

        .progress {
            background-color: $blue;
            bottom: 0;
            left: 0;
            position: absolute;
            top: 0;

            @include border-radius(20px);
        }

        &.done {
            .progress {
                background-color: $green;
            }
        }
    }

    p {
        display: inline-block;
        margin: 20px;

        &.done {
            background: url('/img/icons/checked.svg') no-repeat left center;
            background-size: auto 26px;
            padding-left: 40px;
        }
    }
}
