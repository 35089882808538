#_Old_about {
  min-height: 100vh;

  .header {
    margin: 10vh 0;
  }

  .tables {
    margin-bottom: 80px;
    margin-top: 20px;

    .help {
      margin: 30px 0;
      margin-bottom: 20px;

      a {
        color: inherit;
        display: block;
      }

      h4,
      p {
        margin: 0;
      }

      .icon {
        padding-right: 0;
        text-align: center;

        &.help-icon {
          img {
            display: inline-block;
            position: relative;
            top: 2px;
          }
        }

        &.mail-icon {
          img {
            display: inline-block;
            position: relative;
            top: 6px;
          }
        }
      }
    }

    .links {
      img {
        width: 30px;
      }
    }

    .logos {
      img {
        height: 45px;
      }

      a {
        color: $text-gray;
        margin-left: 10px;
        text-decoration: underline;
      }
      li {
        padding-bottom: 25px;
      }
    }
  }
}

@media (max-width: 991px) {
  .help {
    .col-md-11 {
      &:nth-of-type(2) {
        margin-top: 20px;
      }
    }
  }
}
