.social-list {
  .ui-card {
    overflow: hidden;
  }
  .icon-list--item-left {
    top: 0;
  }
  .icon-list--item {
    align-items: center;
    padding: 20px 15px;
    background-image: url('/img/icons/arrow-right.svg');
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: #fafafa;
    }
  }
  .icon-list--item-title {
    font-weight: 400;
    font-size: 18px;
  }

  .icon-list--item-right {
    padding-left: 13px;
  }
}
