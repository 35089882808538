.skills-own-skills {
  background-color: #f4fbfd;
  border-top: 1px rgb(234, 241, 243) solid;
  padding: 10px 20px 20px;
  position: relative;

  .overlay {
    background-color: #f4fbfd;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .skills-skill {
    border: 1px #f0f0f0 solid;

    @include box-shadow(none);
  }

  .skills-own-skills--category {
    font-weight: 700;
    margin: 15px 0;

    span {
      font-weight: 300;
    }
  }

  .skills-own-skills--container {
    display: flex;
    flex-wrap: wrap;
  }

  &.no-background {
    background-color: #fff;
  }
}
