.box-vi {
  h4 {
    margin-top: 90px;
  }
  p {
    max-width: 920px;
    margin: 0 auto;

    &.top-space {
      margin-top: 25px;
    }
  }

  .disclaimer {
    margin: 10px 0;
    font-size: 15px;
  }

  &.darker {
    background-color: #FBFAF9;
    border-top: 1px solid #efefef;
  }

  @media (max-width: 991px) {
    .box-vi {
      img {
        margin-bottom: 40px;
      }
    }
  }
}
