.profile-thumb {
  margin: 2px 0 0 2px;

  .thumb {
    background-color: #e7e7e7;
    border: 1px $gray solid;
    height: 40px;
    margin: 0;
    max-width: 40px;
    position: relative;
    top: -2px;
    width: 100%;

    &:after {
      bottom: 0;
      content: attr(data-initials);
      font-size: 16px;
      left: 0;
      line-height: 36px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
    }
  }
}
