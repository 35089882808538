.video-screen {
    background-color: #FCFCFC;
    min-height: 100vh;

    h1 {
        font-size: 44px;
        font-weight: 300;
        margin: 50px 0 35px;
    }

    h2 {
        font-size: 22px;
        font-weight: bold;
        margin: 0 0 30px;
    }

    h3 {
        font-size: 30px;
        font-weight: 400;
        margin: 0;
    }

    p {
        font-size: 20px;
        font-weight: 400;
    }

    .wrap {
        background-color: #fff;
        border: 1px solid #EFEFEF;
        padding: 55px 65px;

        @include border-radius(6px);
        @include box-shadow(0 1px 2px rgba(#eaeaea, .5));

        .center-box {
            display: table;
            height: 200px;
            width: 100%;

            .center {
                display: table-cell;
                vertical-align: middle;
            }
        }

        .grid {
            margin-bottom: 25px;
            margin-top: 40px;
            text-align: left;

            .icon {
                background-position: 22px 6px;
                background-repeat: no-repeat;
                margin: 25px 0;
                min-height: 95px;
                padding-left: 90px;
            }

            .i-video {
                background-image: url('/img/icons/i-video.svg');
            }
            .i-light {
                background-image: url('/img/icons/i-light.svg');
            }
            .i-wifi {
                background-image: url('/img/icons/i-wifi.svg');
            }
            .i-time {
                background-image: url('/img/icons/i-time.svg');
            }
        }

        .info-text {
            font-size: 14px;
            margin-bottom: 20px;
        }

        .demo-link {
            color: inherit;
            display: inline-block;
            margin: 30px 0 0;
        }

        .buttons {
            margin-top: 45px;

            .btn {
                min-width: 160px;
                vertical-align: top;
            }

            .timer {
                display: inline-block;
                font-size: 30px;
                margin: 14px 35px 0 0;
                min-width: 68px;
            }
        }

        .permission-check, .permission-denied {
            h3 {
                margin-bottom: 15px;
            }

            img {
                border: 1px solid #ddd;
                margin-top: 30px;
                padding: 10px;
                @include border-radius(6px);
            }

            .check {
                height: 260px;
            }

            .chrome {
                height: 300px;
            }
        }

        .browsers {
            > a {
                color: $text;
                background-position: center top;
                background-repeat: no-repeat;
                background-size: auto 100px;
                display: inline-block;
                font-size: 20px;
                margin: 20px 20px;
                min-width: 100px;
                padding-top: 115px;

                &:hover {
                    color: $orange;
                }
            }
            .chrome {
                background-image: url('/img/icons/chrome.svg');
            }
            .firefox {
                background-image: url('/img/icons/firefox.svg');
            }
            .opera {
                background-image: url('/img/icons/opera.svg');
            }
            .edge {
                background-image: url('/img/icons/edge.svg');
            }
        }

        &.interview {
            padding-top: 0;

            .delay {
                font-size: 46px;
            }

            .video-stream {
                margin: 0 0 45px;

                video {
                    height: 165px;
                }
            }
        }
    }

    .demo-marker {
        background-color: $yellow;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        left: 50%;
        padding: 7px 35px;
        position: fixed;
        text-align: center;
        text-transform: uppercase;
        top: 0;

        @include border-radius(0 0 40px 40px);
        @include center(-50%, 0);
    }
}
