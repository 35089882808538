.logo-placeholder {
  background-color: #f7f7f7;
  border: 2px #eee solid;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 80px;

  @include border-radius(10px);

  .--initials {
    color: #474747;
    font-weight: 700;
    letter-spacing: -2px;
    line-height: 75px;
    text-align: center;
    text-transform: uppercase;

    @include noselect();
  }

  .--logo {
    background-image: url('/img/alfred-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -2px;
    height: 24px;
    left: -3px;
    position: absolute;
    transform: rotate(10deg);
    width: 24px;
  }
}
