.vi-features {
  text-align: left;
  .grid {
    margin-top: 75px;
    margin-bottom: 45px;

    img {
      width: 286px;
      float: right;
    }

    @media(max-width: $sm-width) {
      margin-top: 0;
      h4 {
        margin-top: 40px;
      }
      img {
        display: none;
      }
    }
  }

  .ol-list {
    li {
      font-size: 18px;
      margin: 0;
      padding: 10px 0 10px 40px;
      position: relative;

      em {
        background-color: $blue;
        color: #fff;
        font-style: normal;
        font-weight: bold;
        left: 0;
        line-height: 26px;
        position: absolute;
        text-align: center;
        width: 26px;
        @include border-radius(50%);
      }

      &:before {
        display: none;
      }
    }
  }

  .btn {
    font-size: 20px;
    font-weight: bold;
    padding: 15px 40px;
    background-color: $blue;

    &:hover {
      background-color: darken($blue, 5%);
    }
  }

  &.less-space {
    .grid {
      margin-top: 20px;
    }
  }
}
