.watch-unsubscribe {
  max-width: 450px;
  min-width: 420px;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  top: 50%;
  left: 50%;
  position: absolute;
  @include center();
  @include border-radius(10px);
  @include box-shadow();

  img {
    max-height: 40px;
  }
}

.watch-unsubscribe--content {
  margin-top: 20px;
  margin-bottom: 10px;
}

.watch-unsubscribe--link {
  margin-top: 5px;
}

@media(max-width: $sm-width) {
  .watch-unsubscribe {
    top: 0;
    left: 0;
    min-width: 100%;
    @include border-radius(0);
    @include center(0, 0);
  }
}
