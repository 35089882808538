.animated-modal--overlay {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  opacity: 0.6;
}

.animated-modal--container {
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  min-width: 420px;
  opacity: 0;
  z-index: 9999;
  overflow: hidden;
  @include center();
  @include border-radius(20px);

  &.slide-in {
    animation: slideIn 0.3s ease-in;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }

  &.slide-out {
    animation-fill-mode: forwards;
    animation: slideOut 0.2s ease-in;
    .announcement-modal--action {
      pointer-events: none;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes slideOut {
  0% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: calc(50% - 250px);
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    top: calc(100% + 150px);
    opacity: 0;
  }
  100% {
    top: 50%;
    opacity: 1;
  }
}

@media(max-width: $sm-width) {
  .animated-modal--container {
    opacity: 1;

    &.slide-in {
      animation: none;
    }
  }
}
