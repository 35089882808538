.title-card--title {
  font-size: 20px;
  font-weight: 700;
  padding: 20px;
  border-bottom: 1px #E6E6E6 solid;
}
.title-card--action {
  font-weight: 600;
  font-size: 16px;
  background-color: #F9F9F9;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 10px;
  float: right;
  position: relative;
  top: -5px;
  &:hover {
    background-color: darken(#F9F9F9, 2%);
  }
}
