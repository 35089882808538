.manage-skills-list-item {
  .manage-skills-list-item--content {
    background-color: rgba($orange, .015);
  }

  .manage-skills-list-item--title {
    background-image: url('/img/icons/arrow-down-b.svg');
    background-position: calc(100% - 25px) 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    border-top: 1px #eaf1f3 solid;
    cursor: pointer;
    font-weight: 600;
    padding: 20px 30px;
    transition: background-color 0.4s ease;

    @include noselect;

    &:hover {
      background-color: rgba($orange, .05);
    }

    &.has-selected-items {
      font-weight: 700;

      span {
        color: $blue;
      }
    }
  }

  .manage-skills-list-item--skills-list {
    display: flex;
    flex-wrap: wrap;
  }

  .icon-bars {
    background-image: url('/img/icons/bars.svg');
    background-repeat: no-repeat;
    background-size: 15px 15px;
    display: none;
    height: 16px;
    left: 0;
    position: relative;
    top: 3px;
    width: 14px;
  }

  &:last-of-type {
    border-bottom: 1px #f8f8f8 solid;
  }

  &.active {
    .icon-bars {
      display: inline-block;
      padding-right: 22px;
    }

    > .manage-skills-list-item--title {
      background-color: $orange;
      background-image: url('/img/icons/arrow-up.svg');
      color: #fff;
      font-weight: bold;
      padding-left: 24px;

      span {
        color: #fff;
      }
    }
  }
}

.manage-skills-list-item--sub-item {
  .manage-skills-list-item--content {
    padding: 0 20px 20px;
  }

  .icon-bars {
    background-image: url('/img/icons/subcategory.svg');
    top: 2px;
  }

  .manage-skills-list-item--title {
    font-weight: normal;

    &.has-selected-items {
      font-weight: 400;

      span {
        color: inherit;
      }
    }
  }

  &.active {
    .manage-skills-list-item--title {
      background-color: rgba($orange, .015);
      background-image: url('/img/icons/arrow-up-b.svg');
      color: $text;
      font-weight: 700;
    }

    .icon-bars {
      background-image: url('/img/icons/subcategory.svg');
    }
  }
}
