.tables {
  h5 {
    color: $text-gray;
    font-size: 14px;
    margin: 40px 20px 15px;
  }

  .edit-form {
    background-color: #fff;
    border: 1px solid #F4F4F4;
    margin: 10px 0;
    padding: 15px;
    word-break: break-word;

    @include border-radius(20px);

    .editable {
      border: 2px solid transparent;
      margin: 0;
      padding: 20px 15px;

      @include border-radius(20px);

      &:hover {
        background-color: #fffafa;
        border-color: #f7f7f7;
        cursor: pointer;
      }
    }

    p {
      margin: 10px;

      &.info-text {
        margin: 20px 10px 0 0;
        font-size: 12px;
        text-align: left;
        color: darken($gray, 25%);
      }
    }

    ul {
      margin: 0;

      li {
        border-bottom: 1px solid $border-gray;
        font-size: 16px;
        margin: 0 15px;
        padding: 30px 0;
        position: relative;

        img {
          display: inline-block;
          margin: -3px 12px 0 0;
          // max-height: 19px;
        }

        > div {
          padding: 0;
        }

        .actions {
          display: none;
          font-size: 13px;
          position: absolute;
          right: 0;
          top: 0;

          a {
            display: block;
            padding: 7px 0 4px 15px;

            &:hover {
              text-decoration: underline;
            }
          }

          img {
            height: 16px;
            margin-right: 4px;
          }

          .remove {
            color: $red;
          }
        }

        &:last-child {
          border: none;
        }

        &:before {
          display: none;
        }

        &:hover {
          cursor: pointer;

          .actions {
            display: block;
          }

          &:before {
            background: #fffafa;
            border: 2px solid $border-gray;
            bottom: -2px;
            content: '';
            display: block;
            height: auto;
            left: -15px;
            margin: 0;
            position: absolute;
            right: -15px;
            top: -2px;
            width: auto;

            @include border-radius(20px);
          }
        }

        &.add {
          input {
            display: none;
          }

          label {
            background: url('/img/icons/add_item.svg') no-repeat left center;
            cursor: pointer;
            display: block;
            line-height: 29px;
            padding-left: 45px;

            &:hover {
              background-image: url('/img/icons/add_item_blue.svg');
              color: $blue;
            }
          }

          &:before {
            display: none;
          }
        }
      }

      &.nohover {
        li {
          &:hover {
            &:before {
              display: none;
            }
          }
        }
      }
    }

    .table {
      .content {
        .image {
          margin-top: 18px;
        }
      }
    }
  }

  .attachments,
  .links {
    a {
      color: inherit;
      display: block;

      img {
        width: 19px;
      }

      strong {
        display: inline-block;
        margin: 0;
      }
    }
  }
}
