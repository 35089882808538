.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter;
    padding-right: $gutter;

    @supports(padding: max(0px)) {
        padding-left: calc(max(15px, env(safe-area-inset-left)));
        padding-right: calc(max(15px, env(safe-area-inset-right)))
    }
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: $md-width;
    }
}
@media (min-width: 1200px) {
    .container {
        width: $lg-width;
    }
}
.container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter;
    padding-right: $gutter;

    @supports(padding: max(0px)) {
        padding-left: calc(max(15px, env(safe-area-inset-left)));
        padding-right: calc(max(15px, env(safe-area-inset-right)))
    }
}
.row {
    margin-left: -$gutter;
    margin-right: -$gutter;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12,
.col-xs-13,
.col-sm-13,
.col-md-13,
.col-lg-13,
.col-xs-14,
.col-sm-14,
.col-md-14,
.col-lg-14,
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15,
.col-xs-16,
.col-sm-16,
.col-md-16,
.col-lg-16,
.col-xs-17,
.col-sm-17,
.col-md-17,
.col-lg-17,
.col-xs-18,
.col-sm-18,
.col-md-18,
.col-lg-18,
.col-xs-19,
.col-sm-19,
.col-md-19,
.col-lg-19,
.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20,
.col-xs-21,
.col-sm-21,
.col-md-21,
.col-lg-21,
.col-xs-22,
.col-sm-22,
.col-md-22,
.col-lg-22,
.col-xs-23,
.col-sm-23,
.col-md-23,
.col-lg-23,
.col-xs-24,
.col-sm-24,
.col-md-24,
.col-lg-24 {
    min-height: 1px;
    padding-left: $gutter;
    padding-right: $gutter;
    position: relative;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-13,
.col-xs-14,
.col-xs-15,
.col-xs-16,
.col-xs-17,
.col-xs-18,
.col-xs-19,
.col-xs-20,
.col-xs-21,
.col-xs-22,
.col-xs-23,
.col-xs-24 {
    float: left;
}
.col-xs-24 {
    width: 100%;
}
.col-xs-23 {
    width: 95.83333333%;
}
.col-xs-22 {
    width: 91.66666667%;
}
.col-xs-21 {
    width: 87.5%;
}
.col-xs-20 {
    width: 83.33333333%;
}
.col-xs-19 {
    width: 79.16666667%;
}
.col-xs-18 {
    width: 75%;
}
.col-xs-17 {
    width: 70.83333333%;
}
.col-xs-16 {
    width: 66.66666667%;
}
.col-xs-15 {
    width: 62.5%;
}
.col-xs-14 {
    width: 58.33333333%;
}
.col-xs-13 {
    width: 54.16666667%;
}
.col-xs-12 {
    width: 50%;
}
.col-xs-11 {
    width: 45.83333333%;
}
.col-xs-10 {
    width: 41.66666667%;
}
.col-xs-9 {
    width: 37.5%;
}
.col-xs-8 {
    width: 33.33333333%;
}
.col-xs-7 {
    width: 29.16666667%;
}
.col-xs-6 {
    width: 25%;
}
.col-xs-5 {
    width: 20.83333333%;
}
.col-xs-4 {
    width: 16.66666667%;
}
.col-xs-3 {
    width: 12.5%;
}
.col-xs-2 {
    width: 8.33333333%;
}
.col-xs-1 {
    width: 4.16666667%;
}
.col-xs-pull-24 {
    right: 100%;
}
.col-xs-pull-23 {
    right: 95.83333333%;
}
.col-xs-pull-22 {
    right: 91.66666667%;
}
.col-xs-pull-21 {
    right: 87.5%;
}
.col-xs-pull-20 {
    right: 83.33333333%;
}
.col-xs-pull-19 {
    right: 79.16666667%;
}
.col-xs-pull-18 {
    right: 75%;
}
.col-xs-pull-17 {
    right: 70.83333333%;
}
.col-xs-pull-16 {
    right: 66.66666667%;
}
.col-xs-pull-15 {
    right: 62.5%;
}
.col-xs-pull-14 {
    right: 58.33333333%;
}
.col-xs-pull-13 {
    right: 54.16666667%;
}
.col-xs-pull-12 {
    right: 50%;
}
.col-xs-pull-11 {
    right: 45.83333333%;
}
.col-xs-pull-10 {
    right: 41.66666667%;
}
.col-xs-pull-9 {
    right: 37.5%;
}
.col-xs-pull-8 {
    right: 33.33333333%;
}
.col-xs-pull-7 {
    right: 29.16666667%;
}
.col-xs-pull-6 {
    right: 25%;
}
.col-xs-pull-5 {
    right: 20.83333333%;
}
.col-xs-pull-4 {
    right: 16.66666667%;
}
.col-xs-pull-3 {
    right: 12.5%;
}
.col-xs-pull-2 {
    right: 8.33333333%;
}
.col-xs-pull-1 {
    right: 4.16666667%;
}
.col-xs-pull-0 {
    right: auto;
}
.col-xs-push-24 {
    left: 100%;
}
.col-xs-push-23 {
    left: 95.83333333%;
}
.col-xs-push-22 {
    left: 91.66666667%;
}
.col-xs-push-21 {
    left: 87.5%;
}
.col-xs-push-20 {
    left: 83.33333333%;
}
.col-xs-push-19 {
    left: 79.16666667%;
}
.col-xs-push-18 {
    left: 75%;
}
.col-xs-push-17 {
    left: 70.83333333%;
}
.col-xs-push-16 {
    left: 66.66666667%;
}
.col-xs-push-15 {
    left: 62.5%;
}
.col-xs-push-14 {
    left: 58.33333333%;
}
.col-xs-push-13 {
    left: 54.16666667%;
}
.col-xs-push-12 {
    left: 50%;
}
.col-xs-push-11 {
    left: 45.83333333%;
}
.col-xs-push-10 {
    left: 41.66666667%;
}
.col-xs-push-9 {
    left: 37.5%;
}
.col-xs-push-8 {
    left: 33.33333333%;
}
.col-xs-push-7 {
    left: 29.16666667%;
}
.col-xs-push-6 {
    left: 25%;
}
.col-xs-push-5 {
    left: 20.83333333%;
}
.col-xs-push-4 {
    left: 16.66666667%;
}
.col-xs-push-3 {
    left: 12.5%;
}
.col-xs-push-2 {
    left: 8.33333333%;
}
.col-xs-push-1 {
    left: 4.16666667%;
}
.col-xs-push-0 {
    left: auto;
}
.col-xs-offset-24 {
    margin-left: 100%;
}
.col-xs-offset-23 {
    margin-left: 95.83333333%;
}
.col-xs-offset-22 {
    margin-left: 91.66666667%;
}
.col-xs-offset-21 {
    margin-left: 87.5%;
}
.col-xs-offset-20 {
    margin-left: 83.33333333%;
}
.col-xs-offset-19 {
    margin-left: 79.16666667%;
}
.col-xs-offset-18 {
    margin-left: 75%;
}
.col-xs-offset-17 {
    margin-left: 70.83333333%;
}
.col-xs-offset-16 {
    margin-left: 66.66666667%;
}
.col-xs-offset-15 {
    margin-left: 62.5%;
}
.col-xs-offset-14 {
    margin-left: 58.33333333%;
}
.col-xs-offset-13 {
    margin-left: 54.16666667%;
}
.col-xs-offset-12 {
    margin-left: 50%;
}
.col-xs-offset-11 {
    margin-left: 45.83333333%;
}
.col-xs-offset-10 {
    margin-left: 41.66666667%;
}
.col-xs-offset-9 {
    margin-left: 37.5%;
}
.col-xs-offset-8 {
    margin-left: 33.33333333%;
}
.col-xs-offset-7 {
    margin-left: 29.16666667%;
}
.col-xs-offset-6 {
    margin-left: 25%;
}
.col-xs-offset-5 {
    margin-left: 20.83333333%;
}
.col-xs-offset-4 {
    margin-left: 16.66666667%;
}
.col-xs-offset-3 {
    margin-left: 12.5%;
}
.col-xs-offset-2 {
    margin-left: 8.33333333%;
}
.col-xs-offset-1 {
    margin-left: 4.16666667%;
}
.col-xs-offset-0 {
    margin-left: 0;
}
@media (min-width: 768px) {
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-13,
    .col-sm-14,
    .col-sm-15,
    .col-sm-16,
    .col-sm-17,
    .col-sm-18,
    .col-sm-19,
    .col-sm-20,
    .col-sm-21,
    .col-sm-22,
    .col-sm-23,
    .col-sm-24 {
        float: left;
    }
    .col-sm-24 {
        width: 100%;
    }
    .col-sm-23 {
        width: 95.83333333%;
    }
    .col-sm-22 {
        width: 91.66666667%;
    }
    .col-sm-21 {
        width: 87.5%;
    }
    .col-sm-20 {
        width: 83.33333333%;
    }
    .col-sm-19 {
        width: 79.16666667%;
    }
    .col-sm-18 {
        width: 75%;
    }
    .col-sm-17 {
        width: 70.83333333%;
    }
    .col-sm-16 {
        width: 66.66666667%;
    }
    .col-sm-15 {
        width: 62.5%;
    }
    .col-sm-14 {
        width: 58.33333333%;
    }
    .col-sm-13 {
        width: 54.16666667%;
    }
    .col-sm-12 {
        width: 50%;
    }
    .col-sm-11 {
        width: 45.83333333%;
    }
    .col-sm-10 {
        width: 41.66666667%;
    }
    .col-sm-9 {
        width: 37.5%;
    }
    .col-sm-8 {
        width: 33.33333333%;
    }
    .col-sm-7 {
        width: 29.16666667%;
    }
    .col-sm-6 {
        width: 25%;
    }
    .col-sm-5 {
        width: 20.83333333%;
    }
    .col-sm-4 {
        width: 16.66666667%;
    }
    .col-sm-3 {
        width: 12.5%;
    }
    .col-sm-2 {
        width: 8.33333333%;
    }
    .col-sm-1 {
        width: 4.16666667%;
    }
    .col-sm-pull-24 {
        right: 100%;
    }
    .col-sm-pull-23 {
        right: 95.83333333%;
    }
    .col-sm-pull-22 {
        right: 91.66666667%;
    }
    .col-sm-pull-21 {
        right: 87.5%;
    }
    .col-sm-pull-20 {
        right: 83.33333333%;
    }
    .col-sm-pull-19 {
        right: 79.16666667%;
    }
    .col-sm-pull-18 {
        right: 75%;
    }
    .col-sm-pull-17 {
        right: 70.83333333%;
    }
    .col-sm-pull-16 {
        right: 66.66666667%;
    }
    .col-sm-pull-15 {
        right: 62.5%;
    }
    .col-sm-pull-14 {
        right: 58.33333333%;
    }
    .col-sm-pull-13 {
        right: 54.16666667%;
    }
    .col-sm-pull-12 {
        right: 50%;
    }
    .col-sm-pull-11 {
        right: 45.83333333%;
    }
    .col-sm-pull-10 {
        right: 41.66666667%;
    }
    .col-sm-pull-9 {
        right: 37.5%;
    }
    .col-sm-pull-8 {
        right: 33.33333333%;
    }
    .col-sm-pull-7 {
        right: 29.16666667%;
    }
    .col-sm-pull-6 {
        right: 25%;
    }
    .col-sm-pull-5 {
        right: 20.83333333%;
    }
    .col-sm-pull-4 {
        right: 16.66666667%;
    }
    .col-sm-pull-3 {
        right: 12.5%;
    }
    .col-sm-pull-2 {
        right: 8.33333333%;
    }
    .col-sm-pull-1 {
        right: 4.16666667%;
    }
    .col-sm-pull-0 {
        right: auto;
    }
    .col-sm-push-24 {
        left: 100%;
    }
    .col-sm-push-23 {
        left: 95.83333333%;
    }
    .col-sm-push-22 {
        left: 91.66666667%;
    }
    .col-sm-push-21 {
        left: 87.5%;
    }
    .col-sm-push-20 {
        left: 83.33333333%;
    }
    .col-sm-push-19 {
        left: 79.16666667%;
    }
    .col-sm-push-18 {
        left: 75%;
    }
    .col-sm-push-17 {
        left: 70.83333333%;
    }
    .col-sm-push-16 {
        left: 66.66666667%;
    }
    .col-sm-push-15 {
        left: 62.5%;
    }
    .col-sm-push-14 {
        left: 58.33333333%;
    }
    .col-sm-push-13 {
        left: 54.16666667%;
    }
    .col-sm-push-12 {
        left: 50%;
    }
    .col-sm-push-11 {
        left: 45.83333333%;
    }
    .col-sm-push-10 {
        left: 41.66666667%;
    }
    .col-sm-push-9 {
        left: 37.5%;
    }
    .col-sm-push-8 {
        left: 33.33333333%;
    }
    .col-sm-push-7 {
        left: 29.16666667%;
    }
    .col-sm-push-6 {
        left: 25%;
    }
    .col-sm-push-5 {
        left: 20.83333333%;
    }
    .col-sm-push-4 {
        left: 16.66666667%;
    }
    .col-sm-push-3 {
        left: 12.5%;
    }
    .col-sm-push-2 {
        left: 8.33333333%;
    }
    .col-sm-push-1 {
        left: 4.16666667%;
    }
    .col-sm-push-0 {
        left: auto;
    }
    .col-sm-offset-24 {
        margin-left: 100%;
    }
    .col-sm-offset-23 {
        margin-left: 95.83333333%;
    }
    .col-sm-offset-22 {
        margin-left: 91.66666667%;
    }
    .col-sm-offset-21 {
        margin-left: 87.5%;
    }
    .col-sm-offset-20 {
        margin-left: 83.33333333%;
    }
    .col-sm-offset-19 {
        margin-left: 79.16666667%;
    }
    .col-sm-offset-18 {
        margin-left: 75%;
    }
    .col-sm-offset-17 {
        margin-left: 70.83333333%;
    }
    .col-sm-offset-16 {
        margin-left: 66.66666667%;
    }
    .col-sm-offset-15 {
        margin-left: 62.5%;
    }
    .col-sm-offset-14 {
        margin-left: 58.33333333%;
    }
    .col-sm-offset-13 {
        margin-left: 54.16666667%;
    }
    .col-sm-offset-12 {
        margin-left: 50%;
    }
    .col-sm-offset-11 {
        margin-left: 45.83333333%;
    }
    .col-sm-offset-10 {
        margin-left: 41.66666667%;
    }
    .col-sm-offset-9 {
        margin-left: 37.5%;
    }
    .col-sm-offset-8 {
        margin-left: 33.33333333%;
    }
    .col-sm-offset-7 {
        margin-left: 29.16666667%;
    }
    .col-sm-offset-6 {
        margin-left: 25%;
    }
    .col-sm-offset-5 {
        margin-left: 20.83333333%;
    }
    .col-sm-offset-4 {
        margin-left: 16.66666667%;
    }
    .col-sm-offset-3 {
        margin-left: 12.5%;
    }
    .col-sm-offset-2 {
        margin-left: 8.33333333%;
    }
    .col-sm-offset-1 {
        margin-left: 4.16666667%;
    }
    .col-sm-offset-0 {
        margin-left: 0;
    }
}
@media (min-width: 992px) {
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-13,
    .col-md-14,
    .col-md-15,
    .col-md-16,
    .col-md-17,
    .col-md-18,
    .col-md-19,
    .col-md-20,
    .col-md-21,
    .col-md-22,
    .col-md-23,
    .col-md-24 {
        float: left;
    }
    .col-md-24 {
        width: 100%;
    }
    .col-md-23 {
        width: 95.83333333%;
    }
    .col-md-22 {
        width: 91.66666667%;
    }
    .col-md-21 {
        width: 87.5%;
    }
    .col-md-20 {
        width: 83.33333333%;
    }
    .col-md-19 {
        width: 79.16666667%;
    }
    .col-md-18 {
        width: 75%;
    }
    .col-md-17 {
        width: 70.83333333%;
    }
    .col-md-16 {
        width: 66.66666667%;
    }
    .col-md-15 {
        width: 62.5%;
    }
    .col-md-14 {
        width: 58.33333333%;
    }
    .col-md-13 {
        width: 54.16666667%;
    }
    .col-md-12 {
        width: 50%;
    }
    .col-md-11 {
        width: 45.83333333%;
    }
    .col-md-10 {
        width: 41.66666667%;
    }
    .col-md-9 {
        width: 37.5%;
    }
    .col-md-8 {
        width: 33.33333333%;
    }
    .col-md-7 {
        width: 29.16666667%;
    }
    .col-md-6 {
        width: 25%;
    }
    .col-md-5 {
        width: 20.83333333%;
    }
    .col-md-4 {
        width: 16.66666667%;
    }
    .col-md-3 {
        width: 12.5%;
    }
    .col-md-2 {
        width: 8.33333333%;
    }
    .col-md-1 {
        width: 4.16666667%;
    }
    .col-md-pull-24 {
        right: 100%;
    }
    .col-md-pull-23 {
        right: 95.83333333%;
    }
    .col-md-pull-22 {
        right: 91.66666667%;
    }
    .col-md-pull-21 {
        right: 87.5%;
    }
    .col-md-pull-20 {
        right: 83.33333333%;
    }
    .col-md-pull-19 {
        right: 79.16666667%;
    }
    .col-md-pull-18 {
        right: 75%;
    }
    .col-md-pull-17 {
        right: 70.83333333%;
    }
    .col-md-pull-16 {
        right: 66.66666667%;
    }
    .col-md-pull-15 {
        right: 62.5%;
    }
    .col-md-pull-14 {
        right: 58.33333333%;
    }
    .col-md-pull-13 {
        right: 54.16666667%;
    }
    .col-md-pull-12 {
        right: 50%;
    }
    .col-md-pull-11 {
        right: 45.83333333%;
    }
    .col-md-pull-10 {
        right: 41.66666667%;
    }
    .col-md-pull-9 {
        right: 37.5%;
    }
    .col-md-pull-8 {
        right: 33.33333333%;
    }
    .col-md-pull-7 {
        right: 29.16666667%;
    }
    .col-md-pull-6 {
        right: 25%;
    }
    .col-md-pull-5 {
        right: 20.83333333%;
    }
    .col-md-pull-4 {
        right: 16.66666667%;
    }
    .col-md-pull-3 {
        right: 12.5%;
    }
    .col-md-pull-2 {
        right: 8.33333333%;
    }
    .col-md-pull-1 {
        right: 4.16666667%;
    }
    .col-md-pull-0 {
        right: auto;
    }
    .col-md-push-24 {
        left: 100%;
    }
    .col-md-push-23 {
        left: 95.83333333%;
    }
    .col-md-push-22 {
        left: 91.66666667%;
    }
    .col-md-push-21 {
        left: 87.5%;
    }
    .col-md-push-20 {
        left: 83.33333333%;
    }
    .col-md-push-19 {
        left: 79.16666667%;
    }
    .col-md-push-18 {
        left: 75%;
    }
    .col-md-push-17 {
        left: 70.83333333%;
    }
    .col-md-push-16 {
        left: 66.66666667%;
    }
    .col-md-push-15 {
        left: 62.5%;
    }
    .col-md-push-14 {
        left: 58.33333333%;
    }
    .col-md-push-13 {
        left: 54.16666667%;
    }
    .col-md-push-12 {
        left: 50%;
    }
    .col-md-push-11 {
        left: 45.83333333%;
    }
    .col-md-push-10 {
        left: 41.66666667%;
    }
    .col-md-push-9 {
        left: 37.5%;
    }
    .col-md-push-8 {
        left: 33.33333333%;
    }
    .col-md-push-7 {
        left: 29.16666667%;
    }
    .col-md-push-6 {
        left: 25%;
    }
    .col-md-push-5 {
        left: 20.83333333%;
    }
    .col-md-push-4 {
        left: 16.66666667%;
    }
    .col-md-push-3 {
        left: 12.5%;
    }
    .col-md-push-2 {
        left: 8.33333333%;
    }
    .col-md-push-1 {
        left: 4.16666667%;
    }
    .col-md-push-0 {
        left: auto;
    }
    .col-md-offset-24 {
        margin-left: 100%;
    }
    .col-md-offset-23 {
        margin-left: 95.83333333%;
    }
    .col-md-offset-22 {
        margin-left: 91.66666667%;
    }
    .col-md-offset-21 {
        margin-left: 87.5%;
    }
    .col-md-offset-20 {
        margin-left: 83.33333333%;
    }
    .col-md-offset-19 {
        margin-left: 79.16666667%;
    }
    .col-md-offset-18 {
        margin-left: 75%;
    }
    .col-md-offset-17 {
        margin-left: 70.83333333%;
    }
    .col-md-offset-16 {
        margin-left: 66.66666667%;
    }
    .col-md-offset-15 {
        margin-left: 62.5%;
    }
    .col-md-offset-14 {
        margin-left: 58.33333333%;
    }
    .col-md-offset-13 {
        margin-left: 54.16666667%;
    }
    .col-md-offset-12 {
        margin-left: 50%;
    }
    .col-md-offset-11 {
        margin-left: 45.83333333%;
    }
    .col-md-offset-10 {
        margin-left: 41.66666667%;
    }
    .col-md-offset-9 {
        margin-left: 37.5%;
    }
    .col-md-offset-8 {
        margin-left: 33.33333333%;
    }
    .col-md-offset-7 {
        margin-left: 29.16666667%;
    }
    .col-md-offset-6 {
        margin-left: 25%;
    }
    .col-md-offset-5 {
        margin-left: 20.83333333%;
    }
    .col-md-offset-4 {
        margin-left: 16.66666667%;
    }
    .col-md-offset-3 {
        margin-left: 12.5%;
    }
    .col-md-offset-2 {
        margin-left: 8.33333333%;
    }
    .col-md-offset-1 {
        margin-left: 4.16666667%;
    }
    .col-md-offset-0 {
        margin-left: 0;
    }
}
@media (min-width: 1200px) {
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-13,
    .col-lg-14,
    .col-lg-15,
    .col-lg-16,
    .col-lg-17,
    .col-lg-18,
    .col-lg-19,
    .col-lg-20,
    .col-lg-21,
    .col-lg-22,
    .col-lg-23,
    .col-lg-24 {
        float: left;
    }
    .col-lg-24 {
        width: 100%;
    }
    .col-lg-23 {
        width: 95.83333333%;
    }
    .col-lg-22 {
        width: 91.66666667%;
    }
    .col-lg-21 {
        width: 87.5%;
    }
    .col-lg-20 {
        width: 83.33333333%;
    }
    .col-lg-19 {
        width: 79.16666667%;
    }
    .col-lg-18 {
        width: 75%;
    }
    .col-lg-17 {
        width: 70.83333333%;
    }
    .col-lg-16 {
        width: 66.66666667%;
    }
    .col-lg-15 {
        width: 62.5%;
    }
    .col-lg-14 {
        width: 58.33333333%;
    }
    .col-lg-13 {
        width: 54.16666667%;
    }
    .col-lg-12 {
        width: 50%;
    }
    .col-lg-11 {
        width: 45.83333333%;
    }
    .col-lg-10 {
        width: 41.66666667%;
    }
    .col-lg-9 {
        width: 37.5%;
    }
    .col-lg-8 {
        width: 33.33333333%;
    }
    .col-lg-7 {
        width: 29.16666667%;
    }
    .col-lg-6 {
        width: 25%;
    }
    .col-lg-5 {
        width: 20.83333333%;
    }
    .col-lg-4 {
        width: 16.66666667%;
    }
    .col-lg-3 {
        width: 12.5%;
    }
    .col-lg-2 {
        width: 8.33333333%;
    }
    .col-lg-1 {
        width: 4.16666667%;
    }
    .col-lg-pull-24 {
        right: 100%;
    }
    .col-lg-pull-23 {
        right: 95.83333333%;
    }
    .col-lg-pull-22 {
        right: 91.66666667%;
    }
    .col-lg-pull-21 {
        right: 87.5%;
    }
    .col-lg-pull-20 {
        right: 83.33333333%;
    }
    .col-lg-pull-19 {
        right: 79.16666667%;
    }
    .col-lg-pull-18 {
        right: 75%;
    }
    .col-lg-pull-17 {
        right: 70.83333333%;
    }
    .col-lg-pull-16 {
        right: 66.66666667%;
    }
    .col-lg-pull-15 {
        right: 62.5%;
    }
    .col-lg-pull-14 {
        right: 58.33333333%;
    }
    .col-lg-pull-13 {
        right: 54.16666667%;
    }
    .col-lg-pull-12 {
        right: 50%;
    }
    .col-lg-pull-11 {
        right: 45.83333333%;
    }
    .col-lg-pull-10 {
        right: 41.66666667%;
    }
    .col-lg-pull-9 {
        right: 37.5%;
    }
    .col-lg-pull-8 {
        right: 33.33333333%;
    }
    .col-lg-pull-7 {
        right: 29.16666667%;
    }
    .col-lg-pull-6 {
        right: 25%;
    }
    .col-lg-pull-5 {
        right: 20.83333333%;
    }
    .col-lg-pull-4 {
        right: 16.66666667%;
    }
    .col-lg-pull-3 {
        right: 12.5%;
    }
    .col-lg-pull-2 {
        right: 8.33333333%;
    }
    .col-lg-pull-1 {
        right: 4.16666667%;
    }
    .col-lg-pull-0 {
        right: auto;
    }
    .col-lg-push-24 {
        left: 100%;
    }
    .col-lg-push-23 {
        left: 95.83333333%;
    }
    .col-lg-push-22 {
        left: 91.66666667%;
    }
    .col-lg-push-21 {
        left: 87.5%;
    }
    .col-lg-push-20 {
        left: 83.33333333%;
    }
    .col-lg-push-19 {
        left: 79.16666667%;
    }
    .col-lg-push-18 {
        left: 75%;
    }
    .col-lg-push-17 {
        left: 70.83333333%;
    }
    .col-lg-push-16 {
        left: 66.66666667%;
    }
    .col-lg-push-15 {
        left: 62.5%;
    }
    .col-lg-push-14 {
        left: 58.33333333%;
    }
    .col-lg-push-13 {
        left: 54.16666667%;
    }
    .col-lg-push-12 {
        left: 50%;
    }
    .col-lg-push-11 {
        left: 45.83333333%;
    }
    .col-lg-push-10 {
        left: 41.66666667%;
    }
    .col-lg-push-9 {
        left: 37.5%;
    }
    .col-lg-push-8 {
        left: 33.33333333%;
    }
    .col-lg-push-7 {
        left: 29.16666667%;
    }
    .col-lg-push-6 {
        left: 25%;
    }
    .col-lg-push-5 {
        left: 20.83333333%;
    }
    .col-lg-push-4 {
        left: 16.66666667%;
    }
    .col-lg-push-3 {
        left: 12.5%;
    }
    .col-lg-push-2 {
        left: 8.33333333%;
    }
    .col-lg-push-1 {
        left: 4.16666667%;
    }
    .col-lg-push-0 {
        left: auto;
    }
    .col-lg-offset-24 {
        margin-left: 100%;
    }
    .col-lg-offset-23 {
        margin-left: 95.83333333%;
    }
    .col-lg-offset-22 {
        margin-left: 91.66666667%;
    }
    .col-lg-offset-21 {
        margin-left: 87.5%;
    }
    .col-lg-offset-20 {
        margin-left: 83.33333333%;
    }
    .col-lg-offset-19 {
        margin-left: 79.16666667%;
    }
    .col-lg-offset-18 {
        margin-left: 75%;
    }
    .col-lg-offset-17 {
        margin-left: 70.83333333%;
    }
    .col-lg-offset-16 {
        margin-left: 66.66666667%;
    }
    .col-lg-offset-15 {
        margin-left: 62.5%;
    }
    .col-lg-offset-14 {
        margin-left: 58.33333333%;
    }
    .col-lg-offset-13 {
        margin-left: 54.16666667%;
    }
    .col-lg-offset-12 {
        margin-left: 50%;
    }
    .col-lg-offset-11 {
        margin-left: 45.83333333%;
    }
    .col-lg-offset-10 {
        margin-left: 41.66666667%;
    }
    .col-lg-offset-9 {
        margin-left: 37.5%;
    }
    .col-lg-offset-8 {
        margin-left: 33.33333333%;
    }
    .col-lg-offset-7 {
        margin-left: 29.16666667%;
    }
    .col-lg-offset-6 {
        margin-left: 25%;
    }
    .col-lg-offset-5 {
        margin-left: 20.83333333%;
    }
    .col-lg-offset-4 {
        margin-left: 16.66666667%;
    }
    .col-lg-offset-3 {
        margin-left: 12.5%;
    }
    .col-lg-offset-2 {
        margin-left: 8.33333333%;
    }
    .col-lg-offset-1 {
        margin-left: 4.16666667%;
    }
    .col-lg-offset-0 {
        margin-left: 0;
    }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    content: ' ';
    display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
    clear: both;
}
.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.pull-right {
    float: right !important;
}
.pull-left {
    float: left !important;
}
.show {
    display: block !important;
}
.invisible {
    visibility: hidden;
}
.text-hide {
    background-color: transparent;
    border: 0;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
}
.hidden {
    display: none !important;
}
.affix {
    position: fixed;
}
@-ms-viewport {
    width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}
@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
    table.visible-xs {
        display: table !important;
    }
    tr.visible-xs {
        display: table-row !important;
    }
    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }
}
@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
    }
}
@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
    }
}
@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
    table.visible-sm {
        display: table !important;
    }
    tr.visible-sm {
        display: table-row !important;
    }
    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-block {
        display: block !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline {
        display: inline !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }
    table.visible-md {
        display: table !important;
    }
    tr.visible-md {
        display: table-row !important;
    }
    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-block {
        display: block !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline {
        display: inline !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
    table.visible-lg {
        display: table !important;
    }
    tr.visible-lg {
        display: table-row !important;
    }
    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}
@media (min-width: 1200px) {
    .visible-lg-block {
        display: block !important;
    }
}
@media (min-width: 1200px) {
    .visible-lg-inline {
        display: inline !important;
    }
}
@media (min-width: 1200px) {
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}
@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}
.visible-print {
    display: none !important;
}
@media print {
    .visible-print {
        display: block !important;
    }
    table.visible-print {
        display: table !important;
    }
    tr.visible-print {
        display: table-row !important;
    }
    th.visible-print,
    td.visible-print {
        display: table-cell !important;
    }
}
.visible-print-block {
    display: none !important;
}
@media print {
    .visible-print-block {
        display: block !important;
    }
}
.visible-print-inline {
    display: none !important;
}
@media print {
    .visible-print-inline {
        display: inline !important;
    }
}
.visible-print-inline-block {
    display: none !important;
}
@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}
@media print {
    .hidden-print {
        display: none !important;
    }
}
