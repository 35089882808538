.detail-info {
  .detail-info--list-item {
    padding: 20px;

    &.with-spacer {
      border-bottom: 1px #F4F4F4 solid;
    }
  }
}

.detail-info--share {
  text-align: center;
  position: relative;
  margin-bottom: 10px;
  margin-top: -10px;
  .btn-messenger {
    background-image: url('/img/icons/messenger-b.svg');
    border: none;
    margin: 0;
    padding-left: 45px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
