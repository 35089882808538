.ui-footer {
  border-top: 1px #EAEAEA solid;
  margin-top: 20px;
  padding: 30px 0;
  text-align: center;
}

.ui-footer--logo {
  img {
    width: 120px;
    cursor: pointer;
  }
  div {
    margin-top: 15px;
  }
}
