.perks-list {
  .icon-list--item-right {
    padding-left: 15px;
  }
  .icon-list--item-title {
    //font-weight: 400;
  }
  .icon-list--item-content {
    color: #848484;
    font-size: 16px;
    //line-height: 22px;
    margin-top: 0px;
  }
}
