$btn-height: 50px;
$btn-border-width: 1px;
.ui-button {
  display: inline-flex;
  position: relative;
  padding: 0 $gutter;
  height: $btn-height;

  border: $btn-border-width solid #F1F1F1;
  border-radius: $btn-border-width;

  cursor: pointer;

  line-height: ($btn-height - 2 * $btn-border-width);
  vertical-align: middle;
  text-align: center;
  text-decoration: none;

  background-color: #fff;
  color: #0A0A0A;
  font-weight: 600;

  transition: 180ms;
  transition-property: border-color, background-color, color, opacity;
  @include box-shadow();
  @include border-radius(10px);

  img {
    margin-right: 10px;
  }

  & + & {
    margin-left: $gutter / 2;
  }
  &:hover,
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fafafa;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
    background-color: #bbb;
  }

  &.blue {
    background-color: $blue;
    border: $btn-border-width solid $blue;
    color: #fff;
    &:hover {
      background-color: darken($blue, 5%);
    }
  }
}

.ui-button--svg-icon{
  position: relative;
  display: inline-block;
  top: 5px;
  margin-right: 10px;
}
