.hire-package-modal {
  .ember-modal-dialog {
    overflow: hidden;
    padding: 0;

    .default-dialog {
      padding: 0;
      width: 550px;
    }
  }

  .pd {
    padding: 40px;
  }

  .loading {
    background-image: url('/img/loader-orange.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 30px;
    margin: 30px auto 20px;
    width: 30px;
  }

  .close {
    background-image: url('/img/icons/close_b.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 17px;
    position: absolute;
    right: 25px;
    top: 25px;
    width: 17px;

    @include transition();

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }

  .body {
    padding: 20px;

    h2 {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      margin: 0;
      padding-top: 20px;
    }

    .info-box {
      display: flex;
      margin: 20px auto 30px;
      max-width: 80%;
      padding-left: 20px;
      text-align: left;

      img {
        height: 60px;
        margin-right: 12px;
      }

      &.padded {
        padding: 15px;
      }

      @media(max-width: $sm-width) {
        margin: 20px 0 30px;
        max-width: none;
        padding-left: 0;
        text-align: center;

        img {
          display: none;
        }
      }
    }

    .item {
      background-color: #fff;
      margin-top: 20px;
      padding: 0;
      position: relative;
      text-align: left;
      z-index: 2;

      li {
        margin-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  .cover {
    color: $text;
  }

  .btn {
    background-position: 0 center;
    margin-bottom: 20px;
    margin-top: 30px;
    padding: 13px 40px;

    @include border-radius(18px);
    @include box-shadow(0 0 14px rgba(0,0,0,.1));

    &.green {
      @include gradient(45deg, #59a832, #70c444);

      &:hover {
        @include gradient(45deg, darken(#59a832, 2%), darken(#70c444, 5%));
      }
    }
  }
}

.hire-package {
  .btn {
    padding: 8px 0;
    width: 100%;

    @include border-radius(10px);
  }
}
