@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  background-color: #FDFCFC;
  color: $text;
  font-family: 'Source Sans Pro',sans-serif;
  font-size: 16px;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.with-modal {
    overflow: hidden;
  }
}

p {
  margin-top: 10px;
  line-height: 26px;
}

.tooltipstered {
  background: url('/img/icons/question.svg') no-repeat center center;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin-left: 5px;
  position: relative;
  top: 3px;
  width: 16px;

  &.blue,
  &:hover {
    background-image: url('/img/icons/question_b.svg');
  }

  &.noicon {
    background: none;
    height: auto;
    margin: 0;
    position: static;
    width: auto;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}
