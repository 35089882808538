.check-box {
  align-items: center;
  display: flex;
  width: 100%;

  .label {
    flex: 1;
  }

  .checkbox {
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid $text-gray;
    border-radius: 4px;
    flex: none;
    height: 15px;
    margin-right: 12px;
    width: 15px;
  }

  &.checked {
    .checkbox {
      background-color: $orange;
      background-image: url('/img/icons/white_check.svg');
      border-color: $orange;
    }
  }

  &:hover {
    cursor: pointer;

    .checkbox {
      border-color: $orange;
    }
  }
}
