.e-sign {
  .input-container {
    padding: 10px;
    input {
      width: 100%;
      background-color: #F8F8F8;
      color: #8C8C8C;
      outline: none;
      text-align: center;
      font-size: 35px;
      padding: 25px 0;
      border: none;
      @include border-radius(20px);

      &::placeholder {
        color: lighten(#8C8C8C, 30%);
      }
    }
  }

  .pending {
    font-size: 30px;
    font-weight: 300;
  }

  .logo {
    width: 35px;
    height: 35px;
    background-image: url('/img/alfred.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin: 25px auto 10px;
  }

  .security-number {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }

}
