.hire-question {
  padding: 5px 20px;

  label {
    display: block;
    float: left;
    width: 150px;
    font-size: 15px;
    color: $text-gray;
    text-align: right;
    margin-right: 15px;
    position: relative;
    top: 10px;

    &.required {
      &:before {
        position: absolute;
        content: '*';
        font-weight: 700;
        top: -2px;
        right: -8px;
        color: $red;
      }
    }
    @media(max-width: $sm-width) {
      width: auto;
      display: block;
      float: none;
      top: 0;
      text-align: left;
      left: 5px;
      margin-bottom: 10px;

      &.required {
        &:before {
          left: -10px;
          top: 0;
        }
      }
    }
  }

  input {
    width: calc(100% - 180px);
    border: 1px darken($light-gray, 5%) solid;
    font-size: 15px;
    text-align: left;
    outline: none;
    padding: 10px 15px;
    font-weight: 700;
    @include border-radius(5px);

    &::placeholder {
      font-weight: 400;
      color: lighten($text-gray, 25%);
    }

    &:focus {
      border: 1px darken($border-gray, 25%) solid;
    }

    @media(max-width: $sm-width) {
      width: 100%;
    }
  }

  .clear-input {
    float: right;
    margin-right: 10px;
    font-size: 12px;
    color: $blue;
    text-decoration: underline;

    &:hover {
      color: darken($blue, 10%);
    }
  }

  .ember-power-select-trigger {
    position: relative;
    outline: none;
    display: inline-block;
    font-size: 15px;
    padding: 5px 10px;
    width: calc(100% - 175px);
    border: 1px darken($light-gray, 5%) solid;

    &:focus {
      border: 1px darken($border-gray, 25%) solid;
    }

    @media(max-width: $sm-width) {
      width: 100%;
    }
  }

  .ember-power-select-trigger--active {
    border: 1px darken($border-gray, 25%) solid;
  }

  .ember-power-select-placeholder {
    color: lighten($text-gray, 25%);
  }
  .ember-power-select-selected-item, .ember-power-select-placeholder {
    margin-left: 0;
  }

  .ember-power-select-status-icon {
    border-color: $blue transparent transparent transparent;
    border-width: 4px 4px 0 4px;
  }

  .select-input {
    position: relative;
  }

  .is-bank {
    input {
      padding: 10px;
      width: 90px;
      margin-right: 5px;
      @include border-radius(5px);

      &::placeholder {
        color: lighten($text-gray, 25%);
      }

      &:last-of-type {
        width: calc(100% - 370px);
        margin-right: 0;
      }
    }

    @media(max-width: $sm-width) {
      float: none;
      input {
        &:first-of-type {
          width: 30%;
        }
        &:nth-of-type(2) {
          width: 20%;
        }
        &:last-of-type {
          margin-right: 0;
          width: calc(50% - 18px);
        }
      }
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  &.valid {
    input {
      background-position: calc(100% - 10px) 50%;
      background-repeat: no-repeat;
    }
  }
}
