.profile-actions {
  border-top: 1px solid #eee;
  justify-content: center;
  margin: 30px auto;
  padding-top: 30px;

  .-space {
    width: 20px;
  }

}
