.env-strip {
  height: 75px;
  left: -5px;
  overflow: hidden;
  position: absolute;
  text-align: right;
  top: -5px;
  width: 75px;
  z-index: 99;

  span {
    background: $red;
    background: linear-gradient($red, 20%, darken($red, 30%) 100%);
    box-shadow: 0 0 10px -5px rgba(0, 0, 0, 1);
    color: #fff;
    display: block;
    font-size: 11px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
    text-align: center;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;

    position: absolute;
    top: 19px;
    left: -21px;
  }

  &.stage {
    span {
      background: #da1e00;
      background: linear-gradient(darken($orange, 6%) 20%, #da1e00 100%);
    }
  }
}
