.mobile-banner {
  .google-play-banner {
    align-items: stretch;
    display: flex;
    @include gradient(180deg, #F6F6F6, #E0E0E0, 20%);

    .app-close {
      background: url('/img/icons/close_b.svg') no-repeat center center;
      background-size: auto 15px;
      display: block;
      flex: 0 0 38px;
    }

    a {
      align-items: center;
      color: inherit;
      display: flex;
      flex: 1;
      font-size: 16px;

      .app-logo {
        height: 64px;
        width: 64px;
      }

      .app-info {
        flex-grow: 2;
        padding: 14px 0 14px 10px;

        h4 {
          font-size: 17px;
          font-weight: 600;
          line-height: 1.3;
          margin: 0;
        }

        p {
          font-size: 15px;
          line-height: 1.2;
          margin: 0;
        }
      }

      .app-open {
        .app-btn {
          color: $blue;
          padding: 10px;
          text-transform: uppercase;
        }
      }
    }

  }
}
