.message-attachment-actions {
  min-width: 280px;
}

.message-attachment-actions--responses {
  border-top: 1px solid #E6E6E6;
  text-align: center;
  padding: 15px 0;
}

.message-attachment-actions--btn {
  background-color: transparent;
  outline: none;
  text-align: center;
  width: 90px;

  .icon {
    background-color: #eee;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 22px;
    border-radius: 15px;
    display: inline-block;
    height: 50px;
    width: 50px;
  }

  &.decline {
    .icon {
      background-color: $red;
      background-image: url('/img/icons/decline.svg');
    }
  }

  &.accept {
    .icon {
      background-color: $green;
      background-image: url('/img/icons/accept.svg');
    }
  }

  &.reschedule {
    .icon {
      background-color: $yellow;
      background-image: url('/img/icons/reschedule.svg');
      background-size: auto 27px;
    }
  }

  &:hover {
    .icon {
      @include transition(all 300ms);
      @include transform(scale(1.06,1.06));
    }
  }
}

.message-attachment-actions--status-icon {
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  position: relative;
  top: 4px;
  margin-right: 6px;
}

.message-attachment-actions--status {
  padding: 20px;
  font-weight: 600;
  border-top: 1px solid #E6E6E6;

  &.declined {
    .message-attachment-actions--status-icon {
      width: 14px;
      height: 14px;
      top: 2px;
      background-image: url('/img/icons/message-attachment/declined.svg');
    }
  }

  &.accepted {
    .message-attachment-actions--status-icon {
      background-image: url('/img/icons/message-attachment/accepted.svg');
    }
  }

  &.reschedule {
    .message-attachment-actions--status-icon {
      background-image: url('/img/icons/message-attachment/reschedule.svg');
    }
  }

  &.canceled {
    .message-attachment-actions--status-icon {
      background-image: url('/img/icons/message-attachment/canceled.svg');
    }
  }
}
