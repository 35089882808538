.skills-context-switcher {
  display: flex;
  background-color: #fff;
  color: #A2A1A1;
  @include box-shadow(0 0 10px 0 rgba(0,0,0,0.15));
  @include border-radius(20px);

  .skills-context-switcher--item {
    cursor: pointer;
    padding: 6px 12px 6px 20px;
    border: 2px #fff solid;
    @include border-radius(20px);
    @include transition;
    @include noselect;
    &.selected {
      font-weight: 700;
      border: 2px #898989 solid;
      color: #fff;
      padding-right: 20px;
      padding-left: 35px;
      background-color: #454545;
      background-repeat: no-repeat;
      background-position: 10px 50%;
      background-size: 20px;
    }

    &:nth-of-type(1) {
      &.selected {
        background-color: $orange;
        border: 2px #CD5B00 solid;
        background-image: url('/img/icons/profile-w.svg');
      }
    }

    &:nth-of-type(2) {
      padding-right: 20px;
      &.selected {
        background-size: 16px;
        background-position: 12px 50%;
        background-image: url('/img/icons/categories.svg');
      }
    }
  }
}
