.profile-company-header {
  display: flex;
  align-items: center;
  padding: 30px 0;
}

.profile-company-header--image {
  height: 300px;
  position: relative;
  top: 21px;
  background-size: cover;
  background-position: center;
  @include border-radius(4px);
}

.profile-company-header--left, .profile-company-header--right {
  width: 100%;
  flex: 1;
}

.profile-company-header--title {
  font-size: 60px;
  font-weight: 900;
  margin: 20px 0;
  line-height: 75px;
  padding-right: 120px;
}

.profile-company-header--logo-container {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.profile-company-header--logo-image-container {
  background-color: #fff;
  border: 1px #F1F1F1 solid;
  margin-right: 20px;
  padding: 5px;
  @include box-shadow();
  @include border-radius(6px);

  img {
    display: inline-block;
    max-height: 70px;
  }
}

.profile-company-header--name {
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: $lg-width) {
  .profile-company-header--title {
    font-size: 48px;
    line-height: 60px;
    padding-right: 40px;
  }
  .profile-company-header--image {
    height: 320px;
  }
}

@media (max-width: $md-width) {
  .profile-company-header--title {
    font-size: 35px;
    line-height: 44px;
    padding-right: 40px;
  }
  .profile-company-header--image {
    height: 280px;
  }

  .profile-company-header--logo-container {
    font-size: 16px;
  }
}

@media (max-width: $sm-width) {
  .profile-company-header {
    flex-direction: column;
  }
  .profile-company-header--image {
    width: 100%;
  }
  .profile-company-header--actions {
    margin-bottom: 30px;
  }
  .profile-company-header--title {
    padding-right: 0;
    margin-bottom: 30px;
  }
  .profile-company-header--logo-container {
  }
}
