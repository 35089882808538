.skills-skill {
  background-color: #fff;
  border: 1px solid #eee;
  margin: 5px;
  padding: 8px;
  position: relative;

  @include border-radius(10px);
  @include noselect;
  @include transition;

  .delete-skill {
    background-image: url('/img/icons/trash.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px;
    border-right: 1px #f5f4f4 solid;
    cursor: pointer;
    float: left;
    height: 42px;
    margin-right: 8px;
    width: 35px;

    @include transition;

    &:hover {
      background-size: 17px;
    }
  }

  .skill-add-score-scores {
    padding: 5px;
  }

  .skill-add-score {
    background-color: #fff;
    position: absolute;
    right: 0;
    top: -55px;
    width: 266px;
    z-index: 9999;

    @include border-radius(13px);
    @include box-shadow(0 2px 15px rgba(0,0,0,.1));

    .skills-score {
      cursor: pointer;
      float: left;
      margin-right: 5px;

      @include transition(background 0.5s ease);

      &:hover {
        border: 1px $orange solid;

        &.thunder {
          background-image: url('/img/icons/thunder-o.svg');
        }
      }
    }

    &.binary {
      width: 140px;
    }
  }

  .skills-score {
    align-items: center;
    background-color: #f7f6f6;
    border: 1px #f7f6f6 solid;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 10px;
    min-width: 30px;
    padding: 0 5px;
    width: min-content;

    @include border-radius(8px);
    @include transition;

    &.selected {
      background-color: #FFF1E6;
      font-weight: 600;
    }

    &.thunder {
      background-image: url('/img/icons/thunder-g.svg');
      background-position: 5px 50%;
      background-repeat: no-repeat;
      padding-left: 15px;
    }

    &.orange-thunder {
      background-color: #FFF1E6;
      background-image: url('/img/icons/thunder-o.svg');
      font-weight: 600;
      color: $orange;
    }

    &.read-only {
      background-color: #FFF1E6;
      background-image: url('/img/icons/thunder-o.svg');
      background-position: 50% 50%;
      font-weight: 700;
    }

    &.empty {
      background-image: url('/img/icons/plus.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 11px;
    }

    &.binary {
      white-space: nowrap;
    }
  }

  .skills-skill-content {
    align-items: center;
    cursor: pointer;
    display: flex;

    &.read-only {
      cursor: default;
    }
  }

  &.no-blur {
    z-index: 2;
  }
}
