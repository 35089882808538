.infinity-loader {
  margin-bottom: 70px;
  text-align: center;

  .dot-loader {
    height: 16px;
    margin: auto;
    position: relative;
    width: 132px;
  }

  .dot {
    -moz-animation-direction: normal;
    -moz-animation-duration: 0.905s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-name: bounce_dot;
    -moz-border-radius: 10px;
    -moz-transform: scale(.3);
    -webkit-animation-direction: normal;
    -webkit-animation-duration: 0.905s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: bounce_dot;
    -webkit-border-radius: 10px;
    -webkit-transform: scale(.3);
    animation-direction: normal;
    animation-duration: 0.905s;
    animation-iteration-count: infinite;
    animation-name: bounce_dot;
    background-color: $orange;
    border-radius: 10px;
    height: 16px;
    position: absolute;
    top: 0;
    transform: scale(.3);
    width: 16px;
  }

  .dot_1 {
    -moz-animation-delay: 0.366s;
    -webkit-animation-delay: 0.366s;
    animation-delay: 0.366s;
    left: 0;
  }

  .dot_2 {
    -moz-animation-delay: 0.4525s;
    -webkit-animation-delay: 0.4525s;
    animation-delay: 0.4525s;
    left: 16px;
  }

  .dot_3 {
    -moz-animation-delay: 0.549s;
    -webkit-animation-delay: 0.549s;
    animation-delay: 0.549s;
    left: 33px;
  }

  .dot_4 {
    -moz-animation-delay: 0.6355s;
    -webkit-animation-delay: 0.6355s;
    animation-delay: 0.6355s;
    left: 49px;
  }

  .dot_5 {
    -moz-animation-delay: 0.732s;
    -webkit-animation-delay: 0.732s;
    animation-delay: 0.732s;
    left: 66px;
  }

  .dot_6 {
    -moz-animation-delay: 0.8185s;
    -webkit-animation-delay: 0.8185s;
    animation-delay: 0.8185s;
    left: 82px;
  }

  .dot_7 {
    -moz-animation-delay: 0.905s;
    -webkit-animation-delay: 0.905s;
    animation-delay: 0.905s;
    left: 99px;
  }

  .dot_8 {
    -moz-animation-delay: 1.0015s;
    -webkit-animation-delay: 1.0015s;
    animation-delay: 1.0015s;
    left: 115px;
  }

  @keyframes bounce_dot {
    0% {
      background-color: $orange;
      transform: scale(1);
    }

    100% {
      background-color: rgb(255,255,255);
      transform: scale(.3);
    }
  }

  @-webkit-keyframes bounce_dot {
    0% {
      -webkit-transform: scale(1);
      background-color: $orange;
    }

    100% {
      -webkit-transform: scale(.3);
      background-color: rgb(255,255,255);
    }
  }

  @-moz-keyframes bounce_dot {
    0% {
      -moz-transform: scale(1);
      background-color: $orange;
    }

    100% {
      -moz-transform: scale(.3);
      background-color: rgb(255,255,255);
    }
  }

  &.reached-infinity {
    .dot-loader {
      display: none;
    }
  }
}
