.profile-statistics {
  .ui-card--content {
    padding-top: 20px;
    text-align: center;
  }
}

.profile-statistics--number {
  font-size: 60px;
  font-weight: 900;
}

.profile-statistics--title {
  font-size: 25px;
}
.profile-statistics--slider {
  margin-top: 15px;
}
.profile-statistics--slider-item {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 2px;
  background-color: #E6E6E6;
  cursor: pointer;
  @include border-radius(50%);
}

.profile-statistics--transition {
  &.fadeIn {
    animation: fadeIn 0.2s ease-in-out forwards;
    opacity: 0;
  }

  &.fadeOut {
    animation: fadeOut 0.2s ease-in-out forwards;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
