.application-summary-skills {
  .flex-wrap {
    padding: 20px;
    padding-top: 10px;
  }
  .application-summary-skills--container {
    max-height: 290px;
    overflow-y: scroll;
  }
  .application-summary-skills--title {
    font-weight: 700;
    padding-top: 20px;
    text-align: center;
    max-width: 400px;
    line-height: 24px;
    margin: 0 auto;
  }
  .skills-skill {
    min-width: calc(50% - 10px);
    border: 1px #f0f0f0 solid;
    @include box-shadow(none);
  }
}
