.manage-skills-header {
  padding: 20px;
  position: relative;

  .manage-skills-header--search {
    left: 20px;
    position: absolute;
    top: 50%;

    @include center(0, -50%);

    input {
      background-color: #f1f1f1;
      background-image: url('/img/icons/search-g.svg');
      background-position: 12px 50%;
      background-repeat: no-repeat;
      background-size: 16px;
      border: none;
      outline: none;
      padding: 8px 40px;
      width: 180px;

      @include border-radius(20px);

      &::placeholder {
        color: #b1b1b1;
      }
    }
  }

  .cancel-search {
    background-image: url('/img/icons/delete.svg');
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    display: inline-block;
    height: 12px;
    position: absolute;
    right: 20px;
    top: 12px;
    width: 12px;
  }

  .manage-skills-header--switcher {
    position: absolute;
    right: 70px;
    top: 16px;
  }

  .manage-skills-header--title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;

    span {
      background-image: url('/img/icons/thunder-o.svg');
      background-position: 0 center;
      background-repeat: no-repeat;
      background-size: 14px;
      display: inline-block;
      padding-left: 22px;
    }
  }

  .icon-close {
    background-image: url('/img/icons/close-g.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 50%;
    width: 25px;

    @include center(0, -50%);
    @include transition;
  }

  @media(max-width: $sm-width) {
    padding: 18px 0;

    .manage-skills-header--search {
      left: 0;
      position: relative;
      top: 0;

      input {
        width: calc(100% - 60px);

        @include border-radius(0);
      }
    }

    .icon-close {
      right: 20px;
      top: 20px;
    }

    .cancel-search {
      right: 70px;
    }

    .manage-skills-header--switcher {
      margin: 0 auto;
      max-width: 320px;
      position: relative;
      right: 0;
      top: 0;

      .skills-context-switcher {
        margin-bottom: 15px;
        margin-top: 20px;
      }

      .skills-context-switcher--item {
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        width: 50%;

        &.selected {}
      }
    }
  }
}
