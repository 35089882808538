.profile-bio {
  .--flex {
    align-items: center;
  }

  .avatar {
    margin: 0 20px;
  }

  .name {
    font-weight: bold;
    margin-bottom: 3px;
  }

  .email {
    margin: 1px 0;
  }

  .verify-link {
    display: inline-block;
    position: relative;
    font-size: $ds-text-sm;
    top: -1px;
    left: 2px;
    color: $ds-orange-500;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: $ds-orange-600;
    }
  }

  .-content {
    flex: 1;
  }

  .-verified {
    background: url('/img/icons/checkmark.svg') no-repeat center left;
    background-size: 16px;
    padding-left: 24px;
  }
}
