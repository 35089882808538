.job-item {
  text-align: center;
  height: 280px;

  &.unread {
    > a {
      background-color: #FFFEFE;
      border-color: rgba($orange, 0.5);
    }
  }

  > a {
    align-items: center;
    background-color: #fff;
    border: 1px solid $border-gray;
    color: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    @include border-radius(16px);
    @include transition(all 300ms);

    h4 {
      line-height: 125%;
      font-size: 15px;
      font-weight: 600;
      margin: 10px 20px 5px;
    }

    .icons {
      position: absolute;
      right: 15px;
      top: 15px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      border-color: $orange;
      background-color: #FFFCFA;
    }

    .new {
      color: $orange;
      font-size: 12px;
      font-weight: 600;
      left: 15px;
      position: absolute;
      text-transform: uppercase;
      top: 17px;
    }
  }

  .logo {
    height: 80px;

    img {
      max-height: 100%;
    }
  }

  h5 {
    color: $orange;
    font-size: 15px;
    font-weight: 400;
    margin: 5px 15px 3px;
  }

  span {
    color: #C6C6C6;
    font-size: 13px;
    margin-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .job-item {
    h4 {
      font-size: 1em;
    }
  }
}

@media (max-width: 767px) {
  .job-item {
    height: min-content;

    > a {
      padding: 20px 15px;
    }
  }
}
