.profile-card {
  .--header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 35px 10px 10px;

    .--title {
      font-weight: bold;
    }

    .--actions {
      font-weight: 400;

      .--action {
        background: url('/img/icons/add.svg') no-repeat calc(100% - 1px) center;
        font-size: 14px;
        padding-right: 23px;

        &:hover {
          color: $orange;
        }
      }
    }
  }

  .profile-card--content {
    background-color: #fff;
    border: 1px solid #F4F4F4;
    margin: 10px 0;
    word-break: break-word;

    @include border-radius(20px);
  }
}
