.box-alfrello {
  background-color: #fff;

  .img-1 {
    margin: 50px 0 90px;
    width: 1098px;
  }

  .grid {
    p {
      font-size: 18px;
      margin-bottom: 20px;
      min-height: 130px;
    }

    .a1 {
      width: 302px;
    }

    .a2 {
      width: 261px;
    }

    .a3 {
      width: 328px;
    }
  }

  &.darker {
    background-color: #FBFAF9;
  }

  @media(max-width: 991px) {
    .grid {
      p {
        min-height: 0;
      }

      img {
        margin-bottom: 40px;
      }
    }
  }
}
