.location-pin {
  .ui-card--content {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .location-pin--address {
    font-size: 20px;
    margin-top: 15px;
  }
  .location-pin--action {
    margin-top: 20px;
  }
}

