.--christmas-header {
  background: url('/img/christmas-header.png') no-repeat left bottom;
  background-size: auto 100%;

  @media (max-width: $lg-width) {
    width: auto;

    .header {
      h1 {
        margin-right: 20px !important;
      }
    }
  }

  @media (max-width: $md-width) {
    background-size: auto 70%;
  }

  @media (max-width: $sm-width) {
    background: none;

    .header {
      text-align: center;
    }
  }

  @media (max-width: $sm-width) {
    .header {
      h1 {
        font-size: 34px !important;
      }
    }
  }
}

.company-features {
  overflow: auto;

  hr {
    margin: 70px 0;
  }

  h2 {
    margin-top: 45px;
  }

  .p {
    font-size: 25px;
    margin-top: 35px;
  }

  .price-table {
    border: 1px solid rgba($blue, 0.5);
    border-radius: 20px;
    font-size: 19px;
    margin: 35px 80px;
    overflow: hidden;

    .-row {
      align-items: stretch;
      border-top: 1px solid rgba($blue, 0.5);
      display: flex;

      &:first-child {
        border-top: none;
        font-size: 120%;
      }

      .-item {
        align-items: center;
        border-left: 1px solid rgba($blue, 0.5);
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 12px 15px;

        &:first-child {
          border-left: none;
          font-size: 18px;
          justify-content: flex-start;
          padding-left: 20px;
          padding-right: 20px;
          text-align: left;
        }

        &.-blue {
          background-color: rgba($blue, 0.1);
        }

        &.--noflex {
          display: block;
          text-align: center;
        }

        small {
          font-size: 14px;

          .star {
            color: $orange;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }

    .--yes {
      background: url('/img/icons/check_blue.svg') no-repeat center;
      background-size: auto 100%;
      height: 27px;
      width: 27px;
    }

    .--no {
      background: url('/img/icons/delete.svg') no-repeat center;
      background-size: auto 100%;
      height: 20px;
      width: 20px;
    }

    .old-price {
      display: flex;
      justify-content: center;

      .-price {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 12px;
      }
    }

    .stripe {
      // color: $red;
      font-size: 22px;
      font-weight: 600;
      position: relative;

      &::after {
        background-color: rgba($red, .7);
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: rotate(-18deg);
        width: 100%;
      }
    }

    @media (max-width: $sm-width) {
      font-size: 16px;
      margin: 35px 0;

      .-row {
        .-item {
          padding: 10px 5px;
        }
      }

      .old-price {
        .-price {
          margin-right: 5px;
        }
      }

      .stripe {
        font-size: 18px;
      }
    }

    @media (max-width: $xs-width) {
      font-size: 14px;

      .-row {
        .-item {
          padding: 5px 4px;

          &:first-child {
            padding-left: 12px;
            padding-right: 12px;
          }
        }
      }
    }
  }

  .-contact {
    background-color: $blue;
    font-size: 22px;
    margin: 20px 0 45px;
  }

  .-limited {
    font-size: 16px;
  }

  .-christmas {
    background: $blue url('/img/icons/christmas.svg') no-repeat 17px top;
    background-size: auto 50px;
    font-size: 22px;
    margin: 20px 0 25px;
    padding-left: 80px;
  }

  .feature-col {
    display: flex;
    margin: 0 45px;

    @media (max-width: $sm-width) {
      flex-direction: column;
    }

    .-item {
      flex: 1;
      margin: 10px 30px;

      img {
        border-radius: 14px;
        box-shadow: 0 0 7px rgba(#000, 0.2);
        height: 150px;
        margin-bottom: 30px;

        @include transition();

        &:hover {
          @include transform(scale(1.5, 1.5));
          box-shadow: 0 0 20px rgba(#000, 0.4);
        }
      }

      .-text {
        font-size: 19px;
      }

      &:first-child {
        img {
          height: 200px;
        }
      }

      &:nth-child(3) {
        img {
          height: 174px;
        }
      }

    }
  }

  .feature-row {
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    max-width: 100%;
    text-align: left;
    width: 640px;

    .--space {
      height: 60px;
    }

    .-item {
      align-items: center;
      display: flex;

      .-space {
        width: 40px;
      }

      img {
        border-radius: 14px;
        box-shadow: 0 0 7px rgba(#000, 0.2);
        width: 250px;
        @include transition();

        &:hover {
          @include transform(scale(1.6, 1.6));
          box-shadow: 0 0 20px rgba(#000, 0.4);
        }
      }

      &:first-child {
        img {
          &:hover {
            @include transform(scale(2.3, 2.3));
          }
        }
      }

      .-text {
        font-size: 23px;
        flex: 1;

        strong {
          display: block;
          font-size: 27px;
          margin-bottom: 10px;
        }
      }

      @media (max-width: $sm-width) {
        flex-direction: column;
        text-align: center;

        img {
          margin: 20px 0;
        }

        .-text {
          font-size: 18px;
        }
      }
    }
  }

  .client-logos {
    margin: 40px auto;
    max-width: 100%;
    width: 640px;

    .-row {
      display: flex;
      align-items: center;

      .-item {
        flex: 1;
        margin: 20px;
      }
    }
  }

  .contact-form {
    margin: 35px 0 100px;

    input {
      border-radius: 10px;
      font-size: 18px;
      margin-right: 10px;
      padding: 16px 18px;
      width: 250px;
    }

    .submit {
      background-color: $blue;
      border-radius: 6px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      padding: 7px 15px;
    }
  }
}
