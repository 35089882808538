.detail-content--top {
  margin-bottom: 20px;
}
.detail-content {
  .detail-content--header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    //border-bottom: 1px #F4F4F4 solid;

    h1 {
      font-size: 26px;
      font-weight: 700;
      line-height: 40px;
      margin: 0;
      padding-right: 120px;
    }
  }

  .detail-content--logo {
    margin-right: 20px;
    min-width: 100px;

    img {
      width: 100px;
    }
  }

  .detail-content--content {
    padding: 20px 0;
    padding-top: 0px;
    margin: 0 20px;
    p {
      line-height: 1.6;
      margin: 0 0 15px;
      font-size: 16px;
    }

    ul {
      margin: 10px 0 15px 15px;

      li {
        font-size: 16px;
        margin: 8px 14px;
      }
    }
  }

  .detail-content--brand {
    display: inline-block;
    background: url('/img/icons/arrow_right.svg') no-repeat right calc(50% + 1px);
    background-size: 7px;
    padding-right: 12px;
    color: $orange;
    cursor: pointer;
  }

  @media(max-width: 768px) {
    .detail-content--title-smaller {
      padding-right: 0;
    }
    .detail-content--header {
      flex-direction: column;
      align-items: flex-start;
      h1 {
        padding-right: 60px;
        font-size: 22px;
        line-height: 32px;
      }
    }

    .detail-content--logo {
      margin-right: 0;
    }

    .detail-content--title {
    }

    .detail-content--brand {
      display: block;
      background-image: none;
      text-align: center;
      font-weight: 700;
      margin-top: 10px;
    }
  }
}
