.login-dialog {
  width: 400px;

  h2 {
    margin-bottom: 20px;

    .back {
      background: url('/img/icons/back.svg') no-repeat center;
      background-size: auto 24px;
      float: left;
      height: 42px;
      margin: 0 5px 0 -12px;
      width: 40px;
    }
  }
}

.profile-login {
  .login-methods {
    display: flex;
    flex-direction: column;

    .--or {
      margin: 15px 0;
      text-align: center;
      opacity: 0.8;
    }

    .other-methods {
      text-align: center;

      .btn {
        background-position: center;
        background-size: 23px 23px;
        display: inline-block;
        height: 52px;
        margin: 0 8px;
        padding: 0;
        width: 52px;
        @include border-radius(7px);

        &.btn-fb {
          background-color: $fb;
          background-image: url('/img/icons/login_fb.svg');
          background-size: auto 25px;

          &:hover {
            background-color: darken($fb, 5%);
          }
        }

        &.btn-google {
          background-color: $google;
          background-image: url('/img/icons/login_google.svg');

          &:hover {
            background-color: darken($google, 5%);
          }
        }

        &.btn-apple {
          background-color: #222;
          background-image: url('/img/icons/apple_w.svg');
          background-position: center 13px;
          background-size: auto 24px;

          &:hover {
            background-color: #000;
          }
        }
      }
    }
  }

  .btn {
    margin: 6px 0;
    padding: 17px 0 17px 78px;
    position: relative;
    text-align: left;
    text-transform: none;
    width: 100%;

    @include border-radius(30px);

    .icon {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 23px 23px;
      height: 30px;
      left: 28px;
      position: absolute;
      top: 50%;
      width: 30px;

      @include center(0, -50%);
    }

    &.loading {
      background-image: url('/img/loader_w.svg');
      background-position: center;
      background-size: 25px;

      .icon {
        display: none;
      }
    }

    &.submit {
      padding-left: 0;
      text-align: center;
      text-transform: uppercase;

      &.loading {
        background-image: url('/img/loader_w.svg');
        background-position: center;
        background-size: 20px;
        text-indent: -9999px;
      }
    }

    &.btn-mail {
      background-color: $yellow;

      &:hover {
        background-color: darken($yellow, 5%);
      }

      .icon {
        background-image: url('/img/icons/login_email.svg');
      }
    }

    &.btn-phone {
      background-color: $green;

      &:hover {
        background-color: darken($green, 5%);
      }

      .icon {
        background-image: url('/img/icons/login_phone.svg');
        background-size: auto 21px;
      }
    }
  }

  .--flex {
    align-items: flex-end;
  }

  .--code {
    margin-right: 15px;

    .--selected {
      padding: 12px 10px;
    }
  }

  .terms {
    color: $text-gray;
    font-size: 14px;
    margin-top: 30px;
  }
}
