.ui-card--content {
  padding: 0 20px;
  padding-bottom: 20px;
}
.general-text--title {
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: -10px;
}

.general-text--paragraph {
  line-height: 24px;
  margin: 0;
  margin-top: 20px;
  white-space: pre-wrap;
}

.general-text--list {
  margin-top: 20px;
}
.general-text--spacer {
  height: 1px;
  background-color: #E6E6E6;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 20px;
}

.general-text--link {
  margin-top: 5px;
  a {
    text-decoration: none;
  }
}

.general-text--list-item {
  margin: 10px 20px;
  line-height: 28px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.general-text--list-item-bullet {
  position: absolute;
  top: 0;
  left: -15px;
}
