.experience-description {
  color: $text-gray;
  font-size: 14px;
  margin-top: 4px;
}

.update-profile {
  .logo {
    margin: 0 auto 20px;
    width: 50px;
  }

  .content {
    padding: 0;
    text-align: left;

    input {
      border: 1px #ebebeb solid;
      border-bottom: none;
      outline: none;
      padding: 15px 25px;
      position: relative;
      width: 100%;
    }

    textarea {
      border: none;
      height: 140px;
      outline: none;
      padding: 15px;
      resize: none;
      width: 100%;
    }

    .done,
    .required {
      position: relative;

      &:after {
        color: $red;
        content: '*';
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        position: absolute;
        right: 40px;
        top: 50%;

        @include center(0, -50%);
      }
    }

    .done {
      &:after {
        background-image: url('/img/icons/checked.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 15px;
        width: 15px;
      }
    }
  }

  .profile-info {
    list-style-type: none;
    margin: 0;
    text-align: left;

    li {
      background-image: url('/img/icons/arrow_right.svg');
      background-position: calc(100% - 20px) 50%;
      background-repeat: no-repeat;
      background-size: 10px;
      border-bottom: 1px #ebebeb solid;
      cursor: pointer;
      font-size: 16px;
      list-style-type: none;
      margin: 0;
      padding: 20px;

      div {
        color: #9f9f9f;
        font-size: 16px;
      }

      strong {
        font-size: 18px;
        font-weight: 400;
      }

      &:before {
        display: none;
      }

      &:hover {
        background-color: darken(#fff, 2%);
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    .-alert {
      background-color: rgba($orange,0.1);
      border-top: 1px solid #ebebeb;
      color: $orange;
      font-size: 14px;
      padding: 15px 80px;
      text-align: center;
    }
  }

  .edit-picture {
    color: $blue;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .edit-form {
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      border-bottom: 1px #ebebeb solid;
      cursor: pointer;
      font-size: 18px;
      list-style-type: none;
      margin: 0;
      padding: 20px;
      position: relative;
      text-align: left;

      .date {
        position: absolute;
        right: 15px;
        top: 20px;
      }

      .gray {
        font-size: 16px;
      }

      &:before {
        display: none;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: darken(#fff, 1%);
      }
    }

    .actions {
      border: none;
      bottom: 15px;
      font-size: 16px;
      position: absolute;
      right: 15px;

      a {
        display: flex;

        &:hover {
          font-weight: bold;
        }
      }

      .remove {
        color: $red;

        img {
          margin-right: 5px;
        }
      }
    }
  }

  .no-items {
    font-weight: 300;
    padding: 20px;

    .icon {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 30px;
      margin: 10px auto;
      width: 30px;

      &.experience {
        background-image: url('/img/icons/suitcase.svg');
      }

      &.education {
        background-image: url('/img/icons/school.svg');
      }

      &.languages {
        background-image: url('/img/icons/languages.svg');
      }
    }

    .new-btn {
      background-color: $blue;
      color: #fff;
      font-weight: 700;
      margin: 10px 0 20px;
      outline: none;
      padding: 10px 25px;

      @include border-radius(10px);

      &:hover {
        background-color: darken($blue, 10%);
      }
    }
  }
}
