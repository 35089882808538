.filter-tags-button {
  .btn {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    color: $text;
    font-weight: 400;
    margin: 0;
    padding: 10px 20px;
    @include border-radius(13px);

    &:hover {
      background-color: darken(#fff, 5%);
    }

    &.active {
      background-color: $orange;
      color: #fff;
    }
  }
}

.filter-tags-modal {
  .ember-modal-dialog {
    width: 1100px;
  }

  .default-dialog,
  .ember-modal-dialog {
    padding: 0;
  }

  .default-dialog {
    display: flex;
    flex-direction: column;
    max-height: 694px;
    overflow: hidden;
    width: 100%;
  }

  .head {
    border-bottom: 1px solid #eee;
    padding: 20px;

    strong {
      font-size: 20px;
    }

    .close {
      background-size: 16px auto;
      right: 12px;
      top: 12px;
    }
  }

  .search {
    margin: 15px 15px 20px;
    position: relative;

    input {
      background-color: #fff;
      font-size: 14px;
      opacity: 0.7;
      outline: none;
      padding: 6px 10px;
      width: 100%;
      @include border-radius(16px);

      &:focus,
      &:hover {
        opacity: 1;
      }
    }

    .remove {
      background: url('/img/icons/remove.svg') no-repeat center center;
      background-size: 16px auto;
      border: none;
      height: 16px;
      right: 10px;
      opacity: 0.5;
      outline: none;
      position: absolute;
      top: 8px;
      width: 16px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .window {
    background-color: #fafafa;
    display: flex;
    min-height: 0;
    text-align: left;

    h3 {
      font-size: 16px;
      font-weight: 700;
    }

    .left {
      flex: 1;
      max-width: 290px;
      overflow-y: auto;

      h3 {
        margin: 15px 20px 10px 18px;
      }
    }

    .right {
      align-content: flex-start;
      align-self: stretch;
      background-color: #fff;
      display: flex;
      flex: 2;
      flex-wrap: wrap;
      overflow-y: auto;
      padding: 18px 15px;
    }

    .menu-item {
      cursor: pointer;
      font-weight: 600;
      padding: 10px 20px;

      strong {
        color: $orange;
      }

      &:hover {
        color: #000;
      }

      &.active {
        background-color: $orange;
        color: #fff;
        font-weight: 700;

        strong {
          color: #fff;
          opacity: 0.8;
        }
      }
    }

    &.searching {
      .menu-item,
      h3 {
        opacity: 0.3;
        pointer-events: none;
      }

      .right {
        flex-direction: column;
        flex-wrap: nowrap;

        .cat {
          width: 100%;

          strong {
            display: none;
            width: 100%;

            &:first-child {
              display: block;
              margin-bottom: 7px;
            }
          }

          .item:last-child {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  .item {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 8px 10px;
    width: 50%;

    span {
      font-size: 16px;
      margin-left: 15px;
    }

    &:hover {
      font-weight: 600;
    }

    &.all {
      font-weight: 600;
      width: 100%;
    }
  }

  .tags {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    min-width: 240px;
    overflow-y: auto;
    padding: 15px;
    width: min-content;

    i {
      font-size: 14px;
      padding: 20px;
      width: 200px;
    }

    h3 {
      margin: 0 0 12px 3px;
      width: 100%;
    }

    .tag {
      background-color: $orange;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      margin: 3px;
      max-width: 250px;
      overflow: hidden;
      padding: 3px 10px;
      text-align: center;
      white-space: nowrap;
      @include border-radius(12px);

      &:hover {
        background-color: $red;
        cursor: pointer;
      }
    }
  }

  .foot {
    border-top: 1px solid #eee;
    padding: 12px 15px;
    text-align: right;

    .btn {
      margin: 0 0 0 20px;
      padding: 10px 30px;
    }
  }

  @media(max-width: $sm-width) {
    .default-dialog {
      height: 100vh;
    }

    .tags {
      display: none;
    }
  }

  @media(max-width: 620px) {
    font-size: 14px;
  }

  @media(max-width: 520px) {
    font-size: 11px;
  }
}
