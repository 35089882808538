.country-code {
  margin-bottom: 15px;
  position: relative;

  .--selected {
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    display: flex;
    padding: 9px 10px;

    img {
      margin-right: 10px;
      width: 26px;
    }

    &:hover {
      border-color: $orange;
      cursor: pointer;
    }
  }

  .--dropdown {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-top: 5px;
    max-height: 250px;
    overflow-y: auto;
    padding: 5px 0;
    position: absolute;
    width: max-content;
    z-index: 99;

    hr {
      margin: 5px 0;
    }

    .-code {
      align-items: center;
      display: flex;
      font-size: 15px;
      padding: 5px 10px;

      img {
        width: 25px;
      }

      .-dial {
        min-width: 42px;
        margin: 0 15px;
        text-align: right;
      }

      .-name {
        max-width: 120px;
      }

      &:hover {
        background-color: #eee;
        cursor: pointer;
      }
    }
  }
}
