@mixin border-box {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin border-radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin ad-card-shadow($shadow: 0 0 10px rgba(0,0,0,.03)) {
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin noselect {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  -khtml-user-select: none;
  -webkit-touch-callout: none;
}

@mixin center($x: -50%, $y: -50%) {
  transform: translate($x, $y);
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
}

@mixin shadow-none {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@mixin box-shadow($shadow: 0 2px 8px rgba(0,0,0,0.03)) {
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin transition($trans: all 0.15s linear) {
  -moz-transition: $trans;
  -ms-transition: $trans;
  -o-transition: $trans;
  -webkit-transition: $trans;
  transition: $trans;
}

@mixin animation($anim) {
  -moz-animation: $anim;
  -ms-animation: $anim;
  -o-animation: $anim;
  -webkit-animation: $anim;
  animation: $anim;
}

@mixin transform($trans) {
  -moz-transform: $trans;
  -ms-transform: $trans;
  -o-transform: $trans;
  -webkit-transform: $trans;
  transform: $trans;
}

@mixin appearance($appearance: none) {
  -webkit-appearance: $appearance;
  -moz-appearance: $appearance;
  appearance: $appearance;
}

@mixin gradient($radius: 0deg, $color, $color2, $x: 0%, $y: 100%, $extra: false) {
  @if $extra {
    background-image: -webkit-linear-gradient($radius, $color $x, $color2 $y) $extra;
    background-image: -moz-linear-gradient($radius, $color $x, $color2 $y) $extra;
    background-image: linear-gradient($radius, $color $x, $color2 $y) $extra;

  } @else {
    background-image: -webkit-linear-gradient($radius, $color $x, $color2 $y);
    background-image: -moz-linear-gradient($radius, $color $x, $color2 $y);
    background-image: linear-gradient($radius, $color $x, $color2 $y);
  }
}
