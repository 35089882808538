.profile-grid {
  padding: 20px 0;
  padding-bottom: 40px;
}

.masonry {
  column-count: 3;
  column-gap: 20px;
}

.masonry-item {
  break-inside: avoid;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media(max-width: $lg-width) {
  .masonry {
    column-count: 2;
  }
}

@media(max-width: $sm-width) {
  .masonry {
    column-count: 1;
  }
}
