.favorite-job--heart {
  display: inline-block;
  position: absolute;
  width: 22px;
  height: 20px;
  left: 15px;
  top: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/img/icons/heart-o.svg');
  @include transition(all 0.1s ease-in-out);
  @include center(0, -50%);
}

.btn-brd-gray {
  &.favorite-job {
    background-color: #fff;
    padding-left: 45px;
    position: relative;
    margin: 0;
    border: none;
    padding-bottom: 12px;
    padding-top: 12px;
    @include noselect;
    @include transition();

    &.active {
      background-color: #F5524F;
      color: #fff;

      .favorite-job--heart {
        background-image: url('/img/icons/favorite-selected-w.svg');
      }
    }

    &:hover {
      &.active {
        background-color: #F5524F;
      }
    }
  }
}
