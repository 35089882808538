.newest-jobs {
  .ui-card--content {
    padding: 0;
  }
}

.newest-jobs--container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.newest-jobs--logo {
  height: 50px;
}
.newest-jobs--brand {
 font-size: 16px;
}
.newest-jobs--title {
  font-weight: 600;
  margin: 5px 0;
  font-size: 16px;
}
.newest-jobs--date {
  font-size: 14px;
  color: #848484;
  margin-top: 5px;
}
.newest-jobs--empty-logo {
  width: 100%;
  text-align: center;

  img {
    width: 80px;
  }
}
.newest-jobs--item {
  padding: 15px;
  width: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:nth-of-type(1) {
    border-right: 1px #E6E6E6 solid;
  }

  &:nth-of-type(3) {
    border-top: 1px #E6E6E6 solid;
    border-right: 1px #E6E6E6 solid;
  }

  &:nth-of-type(4) {
    border-top: 1px #E6E6E6 solid;
  }

  &.full-width {
    width: 100%;
    &:nth-of-type(1) {
      border-right: none;
      border-bottom: none;
    }
    &:nth-of-type(3) {
      border-right: none;
      border-top: 1px #E6E6E6 solid;
    }
  }
}
