#profile {
  background-color: $white-gray;
  min-height: 100vh;

  header {
    background-color: $orange;
    @include gradient(130deg, $orange, darken($orange, 10%), 10%);
  }

  .header {
    .notready-box {
      background-color: #fff;
      color: $text;
      margin-top: 20px;
      padding: 15px 15px 15px 5px;

      @include border-radius(8px);

      div {
        margin: 0;
        padding: 0;
      }

      .tool {
        background: url('/img/icons/tool.svg') no-repeat center center;
        height: 78px;
        padding: 0;
      }

      p {
        font-size: 14px;
        margin: 0;

        a {
          text-decoration: underline;
        }
      }

      strong {
        color: $orange;
      }
    }

    .submenu {
      margin: 81px 20px 0 0;
      text-align: right;

      a {
        color: #fff;
        display: inline-block;
        padding: 2px 15px;
        position: relative;
        text-transform: uppercase;

        .badge {
          right: -17px;
          top: -7px;
        }

        &.with-badge {
          margin-right: 20px;
        }

        &.active {
          font-weight: bold;
        }

        &:last-child {
          padding-right: 5px;
        }
      }
    }
  }
}

.edit-experience {
  textarea {
    resize: none;

    &::placeholder {
      color: lighten($text-gray, 10%);
    }
  }
}

.termsModal {
  .logo {
    height: 50px;
  }

  p {
    margin: 0 16px 15px;
  }

  .buttons {
    margin-top: 35px;

    button {
      display: block;
      width: 100%;
    }
  }

  h2 {
    font-size: 33px;
    font-weight: 600;
    margin-top: 25px;
  }

  h3 {
    font-weight: 600;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  table {
    margin-bottom: 30px;
    width: 100%;

    tr {
      td {
        padding: 5px 0;

        label {
          cursor: pointer;
          display: block;
          font-size: 17px;
        }

        &.check {
          text-align: right;
          width: 50px;

          input {
            cursor: pointer;
          }
        }
      }

      &.small {
        font-size: 13px;
        opacity: 0.6;

        td {
          padding-top: 0;
        }
      }
    }
  }
}

.skillsModal {
  .found-skills {
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-top-color: #eee;
    margin: -15px 7px 0;
    max-height: 111px;
    overflow: auto;

    ul {
      margin: 3px 10px;

      li {
        border-bottom: 1px solid #ddd;
        font-size: 14px;
        margin: 0;
        padding: 5px;

        &:hover {
          color: $blue;
          cursor: pointer;
        }

        &:last-child {
          border: none;
        }

        &:before {
          display: none;
        }
      }
    }
  }
}
