.cookies-bar {

    .full {
        background-color: #333;
        bottom: 0;
        color: #fff;
        left: 0;
        padding: 15px 0;
        position: fixed;
        width: 100%;
        z-index: 49;

        .container {
            width: 100vw;
        }

        @media (min-width: 1280px) {
            .container {
                width: 1279px;
            }
        }

        .sm {
            font-size: 14px;
            display: inline-block;
            margin: 9px 0 0;

            a {
                color: $blue;
            }
        }

        .settings {
            border-bottom: 1px solid #999;
            color: inherit;
            display: inline-block;
            margin-top: 9px;
            padding-bottom: 1px;
        }

        .btn {
            margin: 0;
            max-width: 210px;
            padding: 8px 5px;
            width: 100%;
        }

        @media (max-width: 1255px) {
            .sm {
                margin-top: 0px;
            }
        }

        @media (max-width: 767px) {
            .sm {
                font-size: 13px;
                margin-bottom: 15px;
            }
        }

        @media (max-width: 480px) {
            .sm {
                font-size: 12px;
            }
        }
    }
}

.cookiesModal {
    .default-dialog {
        padding: 30px 40px;
        width: 520px;
    }

    a {
        color: $blue;
    }

    .boxes {
        margin: 16px 0 40px;
    }

    .checkbox {
        background: url('/img/icons/checkbox.svg') no-repeat left center;
        background-size: auto 18px;
        color: inherit;
        display: inline-block;
        font-size: 13px;
        line-height: 18px;
        margin: 7px 10px 0 0;
        padding-left: 25px;

        &:last-child {
            margin-right: 0;
        }

        &.checked {
            background-image: url('/img/icons/checkbox_ch.svg');
        }
    }

    .btn {
        background-color: transparent;
        border: 2px solid #fff;
        color: inherit;
        margin: 0;
        padding: 10px;
        width: 100%;
        @include border-radius(4px);
        @include transition;

        &:hover {
            background-color: #eee;
        }

        &.all {
            border-color: $green;
            color: $green;

            &:hover {
                background-color: $green;
                color: #fff;
            }
        }
    }
}
