.nav-bar {
  margin-top: 40px;
  border-bottom: 1px #E6E6E6 solid;
  padding-bottom: 20px;
  a {
    text-decoration: none;
    color: #848484;
    font-size: 22px;
    margin-right: 25px;
    display: inline-block;
    position: relative;

    &.active {
      font-weight: 700;
      color: $text;
    }
  }

  .nav-bar--jobs-count {
    position: absolute;
    top: -10px;
    min-width: 25px;
    text-align: center;
    right: -30px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    padding: 2px 4px;
    @include border-radius(50%);
  }
}

@media(max-width: $sm-width) {
  .nav-bar {
    margin-top: 20px;
  }
}
