.ui-card {
  background-color: #fff;
  border: 1px #F4F4F4 solid;
  overflow: hidden;
  @include box-shadow(none);
  @include border-radius(16px);
}

.ui-card--collapsed {
  cursor: pointer;
  background-image: url('/img/icons/arrow-down-o.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  background-size: 15px;
  &:hover {
    background-color: #fcfcfc;
  }
}
