#faq {
  h1 {
    padding: 80px 0;
    padding-top: 40px;
    text-transform: uppercase;
    font-weight: 300;
  }

  .faq-content {
    border-top: 15px $border-gray solid;
    border-bottom: 5px $border-gray solid;
    position: relative;
    top: -10px;
    max-width: 750px;
    margin: 0 auto;
    padding: 40px;
    padding-bottom: 20px;
    @include box-shadow(0 0 20px 0 rgba(0,0,0,0.05));
    @include border-radius(10px);
    p {
      line-height: 28px;
      padding-bottom: 5px;
      margin-bottom: 15px;
    }
    strong {
      margin-bottom: 5px;
      display: block;
    }
  }
}
