.flex-jobs {
  display: flex;
  flex-direction: column;

  .group-title {
    border-bottom: 1px solid $border;
    font-size: 16px;
    margin: 25px 6px 0;
    padding: 0 0 14px;
    text-align: center;
  }

  .empty {
    text-align: center;

    p {
      padding: 10vh 20px;
    }
  }
}

.jobs--grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  grid-gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 20px;

  @media(max-width: $lg-width) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media(max-width: 920px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: $sm-width) {
    grid-template-columns: 1fr;
  }
}
