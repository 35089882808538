.login-button--white {
  background: #fff url('/img/icons/login.svg') no-repeat 16px center;
  background-size: auto 13px;
  border: none;
  color: $orange;
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 8px 16px 8px 33px;
  position: relative;
  z-index: 5;
  cursor: default;
  @include border-radius(20px);
}
.login-button {
  @media (max-width: $md-width) {
    margin: 15px 20px;

    .login-button--white {
      display: block;
      width: 100%;
      padding: 12px 20px;

      @include border-radius(20px 20px 0 0);
    }

    .-drawer {
      a {
        background-color: rgba(#fff, 0.7);
        color: $text;
        font-size: 18px;
        padding: 15px;

        &:first-child {
          @include border-radius(0);
        }

        &:hover {
          background-color: rgba(#fff, 0.7);
          color: $orange;
        }
      }
    }
  }
}
