.announcement-modal {
  .announcement-modal--actions {
    display: flex;
  }
  .animated-modal--container {
    max-width: 420px;
    z-index: 9999999;
  }

  .announcement-modal--container {
    padding: 20px;
  }

  .announcement-modal--image {
    text-align: center;
    img {
      max-height: 280px;
    }
  }
  .announcement-modal--title {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
  }
  .announcement-modal--content {
    text-align: center;
    margin-top: 10px;
  }
  .announcement-modal--action {
    color: $ds-gray-600;
    flex: 1;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    border-top: 1px #f3f3f3 solid;
    padding: 20px 0;
    cursor: pointer;
    border-right: 1px $ds-gray-200 solid;
    @include noselect;
    &:hover {
      background-color: darken(#fff, 2%);
    }
    &:last-of-type {
      border-right: none;
    }
    &.announcement-modal--action-orange {
      color: $ds-orange-500;
    }
  }
  @media (max-width: $sm-width) {
    .animated-modal--container {
      max-width: none;
    }
  }
}
