.application-questions {
    .info {
        background-color: #F5F5F5;
        display: flex;
        max-width: 80%;
        margin: 0 auto;
        margin-top: 25px;
        padding: 10px;
        font-size: 14px;
        @include border-radius(10px);

        .icon {
            width: 30px;
            margin: 0 5px;
            background-image: url('/img/icons/info.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .content {
        &.no-padding {
            padding: 0;
        }

        .question-top {
            padding: 10px;

            p {
                margin: 5px;
                font-size: 20px;
                strong {
                    font-size: 18px;
                }
            }
        }

        .select-items {
            li {
                &:first-of-type {
                    border-top: 1px #EBEBEB solid;
                }
            }
        }

        textarea, input {
            resize: none;
            width: calc(100% - 80px);
            background-color: #F8F8F8;
            border: none;
            outline: none;
            padding: 20px;
            height: 150px;
            margin: 10px 0;
            margin-top: 0px;
            @include border-radius(10px);
        }

        input {
            height: auto;
            padding: 8px;
            cursor: pointer;
            max-width: 180px;
            text-align: center;
            border: 1px darken(#F8F8F8, 8%) solid;
            &:hover {
                background-color: darken(#F8F8F8, 2%);
            }
        }
    }

    @media(max-width: $sm-width) {
        .info {
            max-width: 100%;
            .icon {
                display: none;
            }
        }
    }
}