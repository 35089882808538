.remote-interview {
  .ember-modal-dialog {
    background-color: #232323;
    padding: 0;
    max-width: calc(100% - 80px);
    @media (max-width: $md-width) {
      max-width: 100%;
    }
  }
  .default-dialog {
    width: 1300px;
  }

  iframe {
    width: 100%;
    height: 580px;
    border: none;
    margin-top: 40px;
  }

  .close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.remote-interview--top {
  color: #fff;
  text-align: left;
  margin-top: -10px;
  margin-left: -50px;
  margin-right: -50px;
  border-bottom: 1px #4b4b4b solid;
  padding-bottom: 25px;
  padding-top: 8px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remote-interview--title {
  text-align: center;
  width: 33%;
  font-weight: 700;
  font-size: 28px;
}
.remote-interview--close {
  height: 20px;
  width: 33%;
  background-repeat: no-repeat;
  background-image: url('/img/icons/close.svg');
  background-size: contain;
  background-position: 100% 0;
  cursor: pointer;
}
.remote-interview--profile {
  display: flex;
  align-items: center;
  width: 33%;
  @media(max-width: $md-width) {
    display: none;
  }
}
.remote-interview--profile-picture {
  border: 2px #fff solid;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 15px;
  @include border-radius(50%);
}
.remote-interview--status {
  border-top: 1px #E6E6E6 solid;
  padding: 20px;
}
.remote-interview--button {
  color: #fff;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  font-weight: 700;
  background-image: linear-gradient(85deg, $orange, darken($orange, 5%));
  margin-top: 15px;
  @include border-radius(10px);
  .arrow-right {
    display: inline-block;
    position: relative;
    top: 4px;
    left: 2px;
    width: 15px;
    height: 15px;
    background-image: url('/img/icons/arrow-right-white.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover {
    background-image: linear-gradient(85deg, $orange, darken($orange, 8%));
  }
}

.remote-interview--status-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}

.remote-interview--status-description {
  line-height: 21px;
}
