.ui-language-selector {
  display: inline-block;
  margin: 6px 0 0;
  position: relative;
  vertical-align: top;

  img {
    border: 2px solid #fafafa;
    border-radius: 50%;
    cursor: pointer;
    height: 34px;
    margin: 5px;
  }

  .menu {
    background-color: #fff;
    border-radius: 15px;
    display: none;
    left: 0;
    margin: 0;
    position: absolute;
    z-index: 5;

    img {
      border-color: #eee;
      display: block;

      &:hover {
        border-color: $blue;
      }
    }
  }

  &:hover {
    .menu {
      display: block;
    }
  }

  @media(max-width: 1201px) {
    .chosen-locale {
      display: none;
    }
    .menu {
      background-color: transparent;
      position: relative;
      display: block;
      img {
        float: left;
      }
    }
  }
}
