.message-attachment-meta {
  background-color: #F9F9F9;
  padding: 15px;
  border-top: 1px #E6E6E6 solid;
  color: #040404;
}

.message-attachment-meta--icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &.date-icon {
    background-image: url('/img/icons/message-attachment/calendar.svg');
  }

  &.location-icon {
    margin-top: 6px;
    background-image: url('/img/icons/message-attachment/pin.svg');
  }

  &.camera-icon {
    margin-top: 6px;
    background-image: url('/img/icons/message-attachment/camera.svg');
  }
}
