.categories-header-is {
  .categories-header-is--title {
    font-weight: 300;
    font-size: 46px;
    line-height: 1.1;
    padding: 40px 0;
  }

  .categories-header-is--categories {
    text-align: center;
    padding-bottom: 50px;
  }

  .categories-header-is--category {
    color: #fff;
    opacity: 0.7;
    display: inline-block;
    font-size: 17px;
    margin: 5px;
    padding: 13px 15px;
    text-decoration: none;

    @include transition(all 300ms);
    @include border-radius(40px);

    strong {
      display: inline-block;
      margin-right: 4px;
      font-weight: 600;
    }

    span {
      font-weight: 400;
      opacity: 0.7;
    }

    &:hover, &.active {
      opacity: 1;
    }

    &.active {
      background-color: rgba(#fff, .10);
      border: 2px solid rgba(#fff, .8);
      box-shadow: 0 0 9px rgba(#000, .05);
    }
  }

  .categories-header-is--jobtypes {
    padding: 30px 0;
    padding-top: 20px;
    text-align: center;
  }

  .categories-header-is--jobtype {
    margin: 10px;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px;
    color: #fff;
    @include border-radius(20px);
    @include transition();

    &:hover {
      opacity: 1;
      transform: scale(1.05);
    }
  }

  @media(max-width: $lg-width) {
    .categories-header-is--title {
      font-size: 40px;
    }
  }

  @media(max-width: $sm-width) {
    .categories-header-is--title {
      padding-bottom: 40px;
      padding-top: 20px;
    }
    .categories-header-is--jobtypes {
      display: none;
    }
  }
}
