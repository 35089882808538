#mc_embed_signup form {
  text-align: right;
}
.mc-field-group {
  display: inline-block;
} /* positions input field horizontally */
#mc_embed_signup input.email {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, Verdana,
    sans-serif;
  font-size: 15px;
  border: 1px solid #abb0b2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #343434;
  background-color: #fff;
  box-sizing: border-box;
  height: 32px;
  padding: 0px 0.4em;
  display: inline-block;
  margin: 0;
  width: 350px;
  vertical-align: top;
}
#mc_embed_signup label {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
}
#mc_embed_signup .clear {
  display: inline-block;
} /* positions button horizontally in line with input */
#mc_embed_signup .button {
  font-size: 13px;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  letter-spacing: 0.03em;
  color: #fff;
  background-color: #aaa;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
  padding: 0 18px;
  display: inline-block;
  margin: 0;
  transition: all 0.23s ease-in-out 0s;
}
#mc_embed_signup .button:hover {
  background-color: #777;
  cursor: pointer;
}
#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em 0.5em 0em 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}
#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}
#mc_embed_signup #mce-error-response {
  display: none;
}
#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}
#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0;
}
@media(max-width: 1200px) {
  #mc_embed_signup form {
    text-align: left;
  }
}
@media(max-width: 992px) {
  #mc_embed_signup .clear {
    display: block !important;
  }
}
@media (max-width: 768px) {
  #mc_embed_signup input.email {
    width: 100%;
    margin-bottom: 5px;
  }
  #mc_embed_signup .clear {
    display: block;
    width: 100%;
  }
  #mc_embed_signup .button {
    width: 100%;
    margin: 0;
  }
}
