.manage-skills-list {
  position: relative;
  .overlay {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
    z-index: 1;
  }
}
