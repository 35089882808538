.job-filter-container {
  &.active {
    .job-filter {
      background-color: $orange;
      background-image: url('/img/icons/select_white.svg');
      border-color: $orange;

      strong {
        color: #fff;
        font-weight: bold;

        small {
          color: #fff;
        }
      }

      &.hide-arrow {
        background-image: none;
      }

      &.active {
        background-image: url('/img/icons/select_upside_white.svg');
      }
    }
  }
}

.job-filter {
  background-color: #fff;
  background-image: url('/img/icons/select.svg');
  background-position: calc(100% - 16px) calc(50% + 1px);
  background-repeat: no-repeat;
  background-size: 8px;
  border: 1px solid #f4f4f4;
  cursor: pointer;
  display: inline-block;
  margin-top: 0;
  padding: 10px 40px 10px 20px;
  position: relative;

  @include border-radius(13px);

  strong {
    font-weight: 400;

    small {
      color: $text-gray;
      font-weight: 400;
      margin-left: 5px;
    }
  }

  .options {
    background-color: #fff;
    list-style-type: none;
    position: absolute;
    right: 0;
    top: 40px;
    width: 210px;
    z-index: 999;

    @include box-shadow(0 0 15px 2px rgba(0, 0, 0, .15));
    @include border-radius(10px);

    .li {
      border-bottom: 1px $border-gray solid;
      font-size: 14px;
      margin-left: 0;
      text-align: left;

      &:before {
        display: none;
      }

      &:not(.strong):hover {
        background-color: darken(#fff, 5%);
      }

      &:first-of-type {
        @include border-radius(10px 10px 0 0);
      }

      &:last-of-type {
        @include border-radius(0 0 10px 10px);
      }

      &.round-all {
        @include border-radius(10px);
      }

      &.strong {
        font-size: 15px;
        font-weight: 700;
        padding: 10px 20px 10px 15px;
      }

      &.padded {
        padding: 10px 20px;
      }

      .check-box {
        padding: 10px 20px;
      }
    }

    &.left {
      left: 0;
      right: auto;
    }

    a {
      color: inherit;
      display: block;
      font-weight: 600;
      padding: 8px 10px;

      @include border-radius(10px);

      small {
        color: $text-gray;
        font-weight: 400;
        margin-left: 4px;
      }

      &:hover {
        background-color: darken(#fff, 5%);
      }

      &.active {
        color: $orange;
        font-weight: 600;
      }
    }

    &.list {
      padding: 10px;
    }

    &.grid {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      max-width: 100vw;
      padding: 10px;
      width: 800px;

      a {
        width: 33.3%;
      }

      @media(max-width: $sm-width) {
        width: 100%;

        a {
          width: 50%;
        }
      }
    }
  }

  .clear {
    background-image: url('/img/icons/delete_white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 11px;
    display: inline-block;
    height: 16px;
    position: absolute;
    right: 15px;
    top: 10px;
    width: 16px;

    @include transition(all 0.05s linear);

    &:hover {
      transform: scale(1.2);
    }

    @media(max-width: $sm-width) {
      background-size: 8px;
      top: 8px;
    }
  }

  &.active,
  &:hover {
    background-color: darken(#fff, 5%);
  }

  &.active {
    background-image: url('/img/icons/select_upside.svg');
  }

  @media(max-width: $sm-width) {
    display: block;
    width: 100%;

    .options {
      left: 0;
    }
  }
}
