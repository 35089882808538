.profile-card-editable-item {
  border: 2px solid transparent;
  border-bottom: 1px solid #eee;
  padding: 20px 25px;
  position: relative;
  @include transition;

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  p {
    margin: 0;
  }

  .remove-action {
    position: absolute;
    top: 10px;
    right: 20px;
    color: $red;
    font-size: 13px;
    cursor: pointer;
    display: none;

    img {
      position: relative;
      left: -4px;
      top: -2px;
      width: 14px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    background-color: #FFF1E6;
    cursor: pointer;

    .remove-action {
      display: block;
    }
  }

  &.removable {
    &:hover {
      padding-top: 35px;
    }
  }
  .gray {
    line-height: 22px;
    font-size: 14px;
  }
}

.profile-card-editable-item--title {
  font-weight: 600;
}
.profile-card-editable-item--subtitle {
  margin: 5px 0;
}
