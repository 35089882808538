.icon-list {
  .ui-card--content {
    padding: 0;
  }
}
.icon-list--item {
  display: flex;
  padding: 20px;
  border-bottom: 1px #E6E6E6 solid;
  &:last-of-type {
    border-bottom: none;
  }
}
.icon-list--item-content {
  line-height: 24px;
  margin-top: 5px;
}
.icon-list--item-image {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  @include border-radius(4px);
}

.icon-list--item-left {
  text-align: center;
  padding-top: 5px;
  img {
    max-width: 40px;
  }
}

.icon-list--item-title {
  font-weight: 700;
  font-size: 18px;
  //margin-bottom: 5px;
}

.icon-list--item-right {
  padding-left: 20px;
}
