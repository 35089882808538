.summerjobs, .christmasjobs {
  .categories-header-is--categories {
    a {
      border: 1px #fff solid;
    }
  }
}

.subpage--user--title {
  font-weight: 700;
}
