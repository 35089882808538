.language-requirements--content {
  padding: 20px;
}

.language-requirements--title {
  font-weight: 700;
}

.language-requirements--icon {
  width: 30px;
  height: 30px;
  background-size: contain;
  margin-right: 15px;
}

.language-requirements--item-left {
  display: flex;
  align-items: center;
}

.language-requirements--item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #F4F4F4 solid;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.language-requirements--item-right {
  color: #848484;
}
