#app {
  background-color: #fff;

  .header {
    margin: 10vh 0 0;

    h1 {
      font-weight: 600;
      margin-left: 140px;
      margin-right: 140px;
    }

    button {
      background-color: #fff;
      color: $blue;
      font-size: 19px;
      padding: 15px 40px;
    }

    .app-download {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      .btn {
        background-color: #fff;
        color: $text;
      }

      .-middle {
        margin: 0 30px;
        width: 430px;
      }

      .-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .-android,
      .-ios {
        margin-bottom: 10px;

        img {
          height: 44px;
        }
      }

      .qr {
        img {
          border-radius: 10px;
          height: 100px;
        }
      }
    }
  }

  .paddbox {
    &.short {
      h2 {
        max-width: 650px;
        margin: 0 auto;
      }
    }
  }
}

#boxAppScreens {
  padding-top: 0;
  margin-bottom: 120px;
  text-align: center;

  img {
    display: inline-block;
    width: 1300px;
  }

  &.padded {
    padding-top: 150px;
  }

  &.no-margin {
    margin-bottom: 0;
  }
}

.dwnModal {
  .qr {
    margin-top: 35px;

    img {
      border: 1px solid #eee;
      border-radius: 10px;
      padding: 6px;
      width: 130px;
    }
  }
}

@media (max-width: 767px) {
  #app {
    .header {
      margin: 5vh 0;

      h1 {
        margin-left: 20px;
        margin-right: 20px;
      }

      .app-download {
        .-item:first-child {
          margin-right: 20px;
        }

        .-middle {
          display: none;
        }
      }
    }
  }

  .dwnModal {
    .form {
      margin-top: 10vh;
    }
  }
}
