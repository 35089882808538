.quadrant-stats {
  .ui-card--content {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    overflow: hidden;
  }
}

.quadrant-stats--item {
  width: 50%;
  min-width: 50%;
  //border: 1px #E6E6E6 solid;
  text-align: center;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.with-icon {
    flex-direction: row;
    align-items: center;
  }

  &:nth-of-type(1) {
    border-right: 1px #E6E6E6 solid;
  }

  &:nth-of-type(3) {
    border-top: 1px #E6E6E6 solid;
    border-right: 1px #E6E6E6 solid;
  }

  &:nth-of-type(4) {
    border-top: 1px #E6E6E6 solid;
  }

  &.full-width {
    width: 100%;
    &:nth-of-type(1) {
      border-right: none;
      border-bottom: none;
    }
    &:nth-of-type(3) {
      border-right: none;
      border-top: 1px #E6E6E6 solid;
    }
  }
}
.quadrant-stats--item-icon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: inline-block;
  background-size: 100%;
}

.quadrant-stats--item-title {
  font-size: 26px;
  font-weight: 700;
}

.quadrant-stats--item-content {
  color: #848484;
}
