.mobile-categories {
  padding-bottom: 30px;
  text-align: center;

  .cat-button {
    display: inline-block;
    position: relative;

    &.toggle {
      background-color: #fff;
      background-image: url('/img/icons/select.svg');
      background-position: calc(100% - 20px) 50%;
      background-repeat: no-repeat;
      background-size: 16px;
      border: none;
      color: $blue;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin: 10px auto 0;
      padding: 10px 60px 10px 40px;
      position: relative;
      z-index: 5;

      @include border-radius(20px);
      @include noselect();
    }

    .drawer {
      box-shadow: 0 4px 17px 3px rgba(#000, .1);
      display: none;
      height: 0;
      left: 0;
      margin: 0 auto;
      max-height: 250px;
      max-width: 250px;
      overflow: scroll;
      position: absolute;
      right: 0;
      text-align: left;
      z-index: 1;

      @include noselect();
      @include border-radius(20px);

      a {
        background-color: rgba(#fff, .8);
        border-bottom: 1px solid rgba(99, 96, 20, .15);
        color: $text;
        display: block;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        padding: 10px 20px;

        &:first-child {
          padding-top: 47px;
        }

        &:last-child {
          border: none;
        }

        &:hover {
          // background-color: rgba(#fff, 0.83);
          color: $blue;
        }
      }
    }

    &:hover {
      .drawer {
        height: auto;
        opacity: 1;

        @include transition();
      }
    }

    @media (max-width: 991px) {
      display: block;
      margin: 0 auto;
      max-width: 400px;
      padding: 0 15px 15px;

      .btn {
        background-position: calc(100% - 20px) center;
        display: block;
        padding: 8px 20px;

        //@include border-radius(20px 20px 0 0);
      }

      .drawer {
        height: auto;
        opacity: 1;
        position: static;

        @include border-radius(0 0 20px 20px);

        a {
          &:first-child {
            padding-top: 15px;
          }
        }
      }
    }

    &.toggled {
      .cat-button {
        background-image: url('/img/icons/select_upside.svg');
        display: block;
        max-width: 250px;

        @include border-radius(20px 20px 0 0);
      }

      .drawer {
        display: block;
      }
    }
  }
}
