.link-button {
  a {
    display: inline-block;
    text-decoration: none;
    background-color: #F9F9F9;
    padding: 10px;
    font-weight: 600;
    @include border-radius(10px);

    &:hover {
     background-color: darken(#F9F9F9, 2%);
    }
  }
}
