.detail-breadcrumbs {
  padding-left: 20px;
  li {
    background: url('/img/icons/arrow_right.svg') no-repeat right center;
    color: $text-gray;
    display: inline-block;
    font-size: 16px;
    margin-right: 2px;
    margin-left: 0;
    padding-right: 14px;
    text-transform: uppercase;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    &:before {
      display: none;
    }

    &:last-child {
      background: none;
    }
  }

  @media(max-width: $sm-width) {
    text-align: center;
    padding-left: 0;
    li {
      font-size: 14px;
    }
  }
}
