.categories-header {
  .--title {
    font-weight: 300;
    font-size: 40px;
    line-height: 1.1;
    padding: 40px 0;
  }

  .--search {
    margin: 0 240px;
    padding: 0 0 50px;
    position: relative;
    text-align: center;

    @media (max-width: 1200px) {
      margin: 0 100px;
    }

    @media (max-width: 991px) {
      margin: 0;
    }

    input {
      background-color: rgba(#fff,0.95);
      background-image: url('/img/icons/search-g.svg');
      background-position: 20px 50%;
      background-repeat: no-repeat;
      background-size: 18px;
      border: 3px solid rgba($orange,0.5);
      color: #888;
      margin-left: 5px;
      outline: none;
      padding: 14px 40px 14px 60px;
      width: calc(100% - 6px);

      @include border-radius(40px);

      &:focus,
      &:hover {
        background-color: #fff;
      }
    }

    .remove {
      background: url('/img/icons/remove.svg') no-repeat center center;
      background-size: 21px auto;
      border: none;
      height: 21px;
      right: 18px;
      opacity: 0.5;
      outline: none;
      position: absolute;
      top: 16px;
      width: 21px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

#categories {
  margin: 40px 20px 80px;

  h1 {
    font-size: 46px;

    strong {
      font-weight: 900;
    }
  }

  .categories {
    margin: 40px 0;
    text-align: center;

    .cat {
      display: inline-block;

      a {
        border: 2px solid rgba(#fff, .1);
        color: #fff;
        display: inline-block;
        font-size: 15px;
        margin: 5px;
        padding: 13px 15px;
        text-decoration: none;

        @include transition(all 300ms);
        @include border-radius(40px);

        strong {
          display: inline-block;
          margin-right: 4px;
          font-weight: 600;
        }

        span {
          font-weight: 400;
          opacity: 0.7;
        }

        &:hover {
          background-color: rgba($orange, .15);
          border: 2px solid rgba(#fff, .1);
          box-shadow: 0 0 9px rgba(#000, .05);
        }
      }

      &.xs a {
        font-size: 11px;
      }

      &.s a {
        font-size: 12px;
      }

      &.m a {
        font-size: 15px;
      }

      &.l a {
        font-size: 16px;
      }

      &.ml a {
        font-size: 18px;
      }

      &.xl a {
        font-size: 20px;
      }

      &.xxl a {
        font-size: 22px;

        &.active {
          font-size: 22px;
        }
      }

      &.subcat {
        a {
          opacity: 0.85;
          border: none;
          margin: 10px;
          padding: 0;
          font-size: 17px;

          &:hover {
            opacity: 1;
            background-color: transparent;
            @include box-shadow(none);
          }
        }
      }
    }

    &.size-m {
      a {
        font-size: 15px;
        padding: 10px 20px;
      }
    }

    &.size-s {
      a {
        border: none;
        font-size: 14px;
        margin: 10px;
        opacity: 0.75;
        padding: 0;

        &:hover {
          border: none;
          box-shadow: none;
          opacity: 1;
        }

        &.active {
          font-size: 20px;
          opacity: 1;

          strong {
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    margin: 0 0 60px;
  }
}

@media (max-width: 991px) {
  #categories {
    .categories {
      a {
        display: inline-block;
        font-size: 15px;
        margin: 5px;
        padding: 10px 20px;
      }
    }
  }
}

@media (max-width: 480px) {
  #categories {
    margin: 30px 0;

    .categories {
      margin: 20px 0;

      a {
        border: none;
        font-size: 13px;
        margin: 2px;
        padding: 5px;

        strong {
          text-transform: none;
        }
      }
    }
  }
}
