.notifications-alerts {
  &.dark-top {
    background-color: rgba(#000, 0);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    // > div {
    //   margin: 20px auto 15px;
    //   max-width: 400px;
    //   width: 80%;
    // }
    ._c-notification_of6y9q {
      border-radius: 0;
      margin-bottom: 2px;
    }

    ._c-notification__icon_of6y9q {
      display: none;
    }

    ._c-notification__content_of6y9q {
      display: block;
      padding: 1rem;
      text-align: center;
    }

    ._c-notification__close_of6y9q {
      float: right;
    }
  }
}
