.user--settings {
  display: flex;

  .left {
    flex: 1;
    margin-right: 40px;
  }

  .right {
    flex: 1;
  }

  .edit-form {
    padding: 0;

    .--link {
      background: url('/img/icons/arrow-right-o.svg') no-repeat left center;
      background-size: auto 12px;
      padding-left: 24px;

      &:hover {
        text-decoration: underline;
      }

      &.black {
        color: inherit;
        font-weight: 600;
      }

      &.delete {
        color: inherit;
        font-weight: 600;
      }
    }

    &.ico-secure {
      background-image: url('/img/icons/secure.svg');
      background-position: 30px 30px;
      background-repeat: no-repeat;
      padding: 15px 20px 15px 80px;
    }
  }

  .--row {
    align-items: center;
    border-bottom: 1px solid #efefef;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;

    &:last-child {
      border: none;
    }

    .--icon {
      align-items: center;
      display: flex;
    }

    .icon {
      margin-right: 15px;

      img {
        height: 30px;
      }
    }

    .title {
      font-weight: 600;
    }

    label {
      cursor: pointer;
      display: block;
      font-weight: 600;
    }

    small {
      color: $text-gray;
      display: block;
      font-size: 14px;
      margin-right: 40px;
      margin-top: 2px;

      @media (min-width: $sm-width) and (max-width: $lg-width) {
        display: none;
      }
    }
  }

  @media (max-width: $sm-width) {
    flex-direction: column;

    .left {
      margin-right: 0;
    }

    .--row {
      align-items: flex-start;
      flex-direction: column;

      small {
        margin-bottom: 5px;
      }

      .multi-check {
        align-self: flex-end;
        margin-left: 0;
      }
    }
  }
}

.multi-check {
  margin-left: 40px;
  position: relative;

  .--selected {
    background: url('/img/icons/arrow-right-o.svg') no-repeat right center;
    background-size: auto 12px;
    color: $text-gray;
    cursor: pointer;
    padding: 4px 20px 4px 10px;
    white-space: nowrap;

    @include border-radius(13px);

    span {
      &:after {
        content: ', ';
        display: inline-block;
      }

      &:last-child:after {
        content: '';
      }
    }

    &:hover {
      background-color: #fafafa;
    }
  }

  .--modal {
    background-color: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 4px 17px 3px rgba(#000, .1);
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100%;
    white-space: nowrap;
    z-index: 10;

    @include border-radius(13px);

    .check {
      align-items: center;
      border-bottom: 1px solid #f4f4f4;
      cursor: pointer;
      display: flex;
      padding: 10px 50px 10px 20px;

      .flag {
        height: 20px;
        margin-right: 15px;
      }

      &.checked {
        background-image: url('/img/icons/check_orange.svg');
        background-position: calc(100% - 10px) center;
        background-repeat: no-repeat;
      }

      &:hover {
        background-color: #fafafa;
      }
    }
  }
}
