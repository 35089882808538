#videointerviews {
    font-size: 18px;
    margin: 30px 0;

    h2 {
        font-size: 44px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    h3 {
        font-size: 28px;
        font-weight: 400;
    }

    h4 {
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 15px;
    }

    p {
        font-size: 21px;
        font-weight: 200;
        margin: 15px 0 25px;
    }

    hr {
        margin: 80px 0;
    }

    .close {
        position: fixed;
    }

    .edit-form {
        padding: 45px;

        .ol-list {
            li {
                font-size: 20px;
                font-weight: 200;
                margin: 0;
                padding: 10px 0 10px 40px;
                position: relative;

                em {
                    background-color: $blue;
                    color: #fff;
                    font-style: normal;
                    font-weight: bold;
                    left: 0;
                    line-height: 26px;
                    position: absolute;
                    text-align: center;
                    width: 26px;
                    @include border-radius(50%);
                }

                &:before {
                    display: none;
                }
            }
        }

        .vi-1 {
            width: 575px;
        }

        .vi-2 {
            width: 304px;
        }

        .vi-3 {
            width: 554px;
        }

        .buy-vi {
            font-size: 24px;
            margin-top: 60px;
            padding: 24px;
            @include border-radius(50px);

            small {
                font-weight: normal;
                margin-left: 5px;
            }
        }
    }

    @media (max-width: 991px) {
        img {
            margin-bottom: 40px;
        }
    }
}
