.inline-block {
    display: inline-block;
}

.left {
    float: left;
}

.right {
    float: right;
}
