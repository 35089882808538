.job-filters {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  .job-filters--search {
    min-width: 350px;
    display: flex;
    height: 100%;

    img {
      position: relative;
      top: 5px;
      width: 25px;
      margin-right: 5px;
    }

    input {
      padding: 10px 10px 10px 40px;
      background-image: url('/img/icons/search.svg');
      background-repeat: no-repeat;
      background-position: 10px 50%;
      background-size: 20px;
      background-color: #fff;
      border: 1px #f4f4f4 solid;
      width: 100%;
      color: #888;
      outline: none;

      @include border-radius(13px);

      &:focus,
      &:hover {
        opacity: 1;
        border: 1px #ddd solid;
      }
    }

    &.focus,
    &:hover {
      input {
        opacity: 1;
      }
    }

    .remove {
      background: url('/img/icons/remove.svg') no-repeat center center;
      background-size: 15px auto;
      border: none;
      height: 15px;
      opacity: 0.5;
      outline: none;
      width: 15px;
      position: relative;
      left: 5px;
      top: 12px;

      &:hover {
        opacity: 0.8;
      }
    }

    &.wider {
      min-width: 420px;

      @media(max-width: $sm-width) {
        min-width: auto;
      }
    }
  }

  .job-filters--tag {
    background: $orange url('/img/icons/close.svg') no-repeat calc(100% - 15px) center;
    background-size: auto 10px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    display: inline-block;
    padding: 8px 40px 8px 15px;

    &:hover {
      background-color: darken($orange, 5%);
    }
  }

  .job-filters--left {
    .job-filter {
      margin-right: 10px;
    }
  }

  .job-filters--right {
    display: flex;
    align-items: center;

    > div {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .clear {
    background-color: transparent;
    color: $red;
    display: inline-block;
    font-size: 14px;
    margin: 0;
    padding: 10px 20px;

    &:hover {
      background-color: inherit;
      color: darken($red, 16%);
    }
  }

  .brigady-btn {
    background-color: rgba(#32aa05,.7);
    border: 2px #228705 solid;
    color: #fff;
    display: block;
    font-weight: 700;
    padding: 8px 20px;
    @include border-radius(13px);

    &:hover {
      background-color: rgba(#32aa05,.9);
    }
  }

  .hide-lg {
    display: block;
  }

  .hide-mobile {
    display: block;
  }

  @media(max-width: 1199px) {
    flex-direction: column;

    .job-filters--search {
      margin-bottom: 10px;
    }

    .job-filters--left {
      margin-bottom: 20px;
    }

    .job-filters--right {
      justify-content: space-between;
    }

    .hide-lg {
      display: none;
    }
  }

  @media(max-width: 992px) {
    .job-filters--search {
      .remove {
        left: 5px;
      }
    }
  }

  @media(max-width: $sm-width) {
    .job-filters--right {
      display: block;
      margin-bottom: 10px;
      flex-direction: column;

      .job-filter-container {
        margin: 0 0 10px;
      }

      .filter-tags-button {
        .btn {
          text-align: left;
          width: 100%;
        }
      }
    }

    .hide-mobile {
      display: none;
    }
  }
}
