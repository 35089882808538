.empty-screen {
  background-color: #fff;
  text-align: center;

  img {
    width: 70px;
    display: inline-block;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .empty-screen--title {
    font-weight: 700;
    font-size: 20px;
  }

  .empty-screen--content {
    color: #9b9b9b;
    max-width: 420px;
    margin: 5px auto 15px;
    line-height: 25px;
  }
}
