#category {
  min-height: 100vh;

  h1 {
    margin-top: 40px;

    small {
      font-weight: 300;
      opacity: 1;
      vertical-align: middle;
    }
  }

  .filter-selects {
    margin-top: -15px;

    > div {
      display: inline-block;
      margin-right: 8px;

      &:last-of-type {
        margin-right: -$gutter;
      }
    }

    @media(max-width: $md-width) {
      margin-top: 20px;
      text-align: center;

      > div {
        font-size: 12px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .job-filter-container {
        &:first-of-type {
          &.active {
            min-width: 170px; // otherwise the order of the filters will change in mobile
          }
        }
      }
    }
  }

  .filter {
    margin: 40px 0 25px;
    overflow: visible;

    .tags {
      .tag {
        background: $orange url('/img/icons/close.svg') no-repeat calc(100% - 15px) center;
        background-size: auto 10px;
        border-radius: 12px;
        color: #fff;
        cursor: pointer;
        font-weight: 500;
        display: inline-block;
        padding: 5px 40px 5px 15px;

        &:hover {
          background-color: darken($orange, 10%);
        }
      }
    }

    .search {
      img {
        opacity: 0.5;
        width: 25px;
      }

      input {
        background: none;
        border: none;
        border-bottom: 1px solid #ccc;
        color: #888;
        margin-left: 5px;
        outline: none;
        padding: 2px 5px;
        width: 230px;

        @include transition();

        &:focus {
          border: none;
          border-bottom: 1px solid #ccc;
          opacity: 1;
        }
      }

      &.focus,
      &:hover {
        input {
          opacity: 1;
        }
      }

      .remove {
        background: url('/img/icons/remove.svg') no-repeat center center;
        background-size: 15px auto;
        border: none;
        height: 15px;
        opacity: 0.5;
        outline: none;
        width: 15px;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .select {
      background: url('/img/icons/select.svg') no-repeat right center;
      background-size: auto 8px;
      color: inherit;
      padding: 10px 20px;
      position: relative;

      span {
        color: $text-gray;
      }

      .dropdown {
        background-color: #fff;
        border: 1px solid $border-gray;
        display: none;
        left: auto;
        position: absolute;
        right: 0;
        text-align: left;
        top: 100%;
        width: max-content;
        z-index: 50;
        @include border-radius(14px);
        @include box-shadow(0 2px 5px rgba(#000, 0.2));

        a {
          background-position: 14px center;
          background-repeat: no-repeat;
          border-bottom: 1px solid $border-gray;
          color: inherit;
          display: block;
          padding: 14px 20px 14px 45px;
          word-break: keep-all;

          &.i-all {
            background-image: url('/img/icons/all_boxes.svg');
          }

          &.i-profile {
            background-image: url('/img/icons/profile_g.svg');
          }

          &:last-child {
            border: none;
          }

          &.active,
          &:hover {
            font-weight: 600;
          }
        }
      }

      &:hover {
        .dropdown {
          display: block;
        }
      }
    }
  }

  .jobs {
    min-height: calc(100vh - 960px);
  }

  .main-footer {
    background-color: #fff;
  }
}
