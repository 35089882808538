$time: 500ms;
$angle: 7deg;
.apply-processing {
    .logo {
        width: 60px;
        height: 60px;
        background-image: url('/img/alfred.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 auto 20px;
    }
}

.shakeit {
  -webkit-animation: rotate $time ease-in-out infinite;
  -moz-animation: rotate $time ease-in-out infinite;
  animation: rotate $time ease-in-out infinite;
}

@-moz-keyframes rotate {
    0% { -webkit-transform: rotate(-$angle); transform:rotate(-$angle); }
    50% { -webkit-transform: rotate($angle); transform:rotate($angle); }
    100% { -webkit-transform: rotate(-$angle); transform:rotate(-$angle); }
}
@-webkit-keyframes rotate {
    0% { -webkit-transform: rotate(-$angle); transform:rotate(-$angle); }
    50% { -webkit-transform: rotate($angle); transform:rotate($angle); }
    100% { -webkit-transform: rotate(-$angle); transform:rotate(-$angle); }
}
@keyframes rotate {
    0% { -webkit-transform: rotate(-$angle); transform:rotate(-$angle); }
    50% { -webkit-transform: rotate($angle); transform:rotate($angle); }
    100% { -webkit-transform: rotate(-$angle); transform:rotate(-$angle); }
}
