.profile-skills-empty {
  background-image: url('/img/icons/thunder-o.svg');
  background-position: 20px calc(50% + 20px);
  background-repeat: no-repeat;
  background-size: 20px;
  padding: 20px 20px 0 60px;

  .profile-skills-empty--title {
    font-weight: 700;
    margin-bottom: 10px;
  }
}
