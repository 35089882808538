.christmasjobs-btn {
  position: relative;

  a {
    display: inline-block;
    color: #fff;
    background-color: rgba(#f44e4e,1);
    padding: 8px 40px 8px 20px;
    border: 2px #f44e4e solid;
    font-weight: 700;
    @include border-radius(13px);
    @include transition;

    .icon {
      width: 25px;
      height: 25px;
      position: absolute;
      display: inline-block;
      top: calc(50% - 12px);
      right: 10px;

      &.tree {
        background-image: url('/img/icons/bauble.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &:hover {
      background-color: rgba(#f44e4e,.9);

      .icon {
        animation: swing 1s linear forwards;
        // transform: rotate(-10deg);
      }
    }
  }
}
@keyframes swing {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0);
  }
}
