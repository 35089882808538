.company-profile-card {
  .hide {
    display: none;
  }
}
.company-profile-card--logo {
  display: inline-block;
  background-color: #fff;
  border: 1px #E6E6E6 solid;
  padding: 10px;
  min-width: 80px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: calc(100% - 20px);
  @include border-radius(7px);
  img {
    display: inline-block;
  }

  &.--empty-logo {
    border: none;
    padding: 0;
  }
}

.company-profile-card--company-left {
  display: flex;
  align-items: center;

  &.without-brand-description {
    align-items: center;
    .company-profile-card--content-title {
      font-size: 20px;
    }
  }
}

.company-profile-card--link {
  a {
    margin-top: 2px;
    display: inline-block;
    background-image: url('/img/icons/arrow-right-o.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 2px) 50%;
    padding-right: 15px;
  }
}

.company-profile-card--title {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px #E6E6E6 solid;
}

.company-profile-card--content-button {
  a {
    color: $orange;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid #E6E6E6;
    background-image: url('/img/icons/arrow-right-o.svg');
    padding: 12px 35px 12px 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) 50%;
    @include transition;
    @include border-radius(20px);

    &:hover {
      background-color: #fcfcfc;
    }
  }
}

.company-profile-card--left {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
}

.company-profile-card--company {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    &:first-of-type {
      flex: 1;
    }
  }

  &.with-border {
    border-bottom: 1px #F4F4F4 solid;
  }
}

.company-profile-card--content-title {
  font-weight: 700;
  font-size: 16px;
  padding-right: 15px;
}

.company-profile-card--content-description {
  margin-top: 5px;
  padding-right: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.company-profile-card--cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 280px;
}

.company-profile-card--link {
  margin-top: 4px;
}

@media(max-width: $sm-width) {
  .company-profile-card--company-left {
    align-items: center;
  }
  .company-profile-card--content-description {
    display: none;
  }
  .company-profile-card--content-title {
    font-size: 18px;
  }
  .company-profile-card--content-button {
    display: none;
  }

  .company-profile-card--link {
    display: inline-block !important;
  }
}
