.empty-message {
  background-color: #fff;
  padding: 15px;
  text-align: center;
  margin-top: 40px;
  @include box-shadow();
  @include border-radius(20px);
}

.empty-message--content {
  margin-top: 15px;
  margin-bottom: 15px;
}
