.search-bar {
  margin: -31px 0 0;
  text-align: center;

  .search {
    background-color: #fff;
    border: 1px solid $border-gray;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 550px;
    @include ad-card-shadow();
    @include border-radius(50px);

    input {
      border: none;
      color: $text;
      font-size: 20px;
      font-weight: 400;
      line-height: 60px;
      outline: none;
      padding: 0 63px 0 30px;
      width: 100%;

      @media (max-width: 480px) {
        font-size: 18px;
        padding-left: 20px;
      }
    }

    .btn {
      background-image: url('/img/icons/search.svg');
      background-position: center;
      height: 44px;
      margin: 0;
      position: absolute;
      right: 11px;
      top: 8px;
      width: 44px;

      &.clear {
        background-image: url('/img/icons/close.svg');
        background-size: auto 19px;
      }
    }

    &.loading {
      .btn {
        background: rgba($orange, 0.4) url('/img/loader-orange.svg') no-repeat center;
        pointer-events: none;
      }
    }
  }
}
