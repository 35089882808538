$border: #f4f4f4;
$orange-accent: #fffcfa;

$orange: #ff7200;
$blue: #23b8e5;

$green: #32aa05;
$red: #f44e4e;
$yellow: #ffb400;

$gray: #ddd;
$medium: #a0a0a0;
$light-gray: #f2f2f2;

$text: #0a0a0a;
$text-gray: #8c8c8c;
$border-gray: #e6e6e6;
$white-gray: #fbfbfb;

$fb: #3b5998;
$google: #4285f4;
$twitter: #2aa3f0;
$success: #b1cc0d;
$alert: #9a0830;

// Color variables above are deprecated. Use design-system (ds) colors instead.

// Design system colors
$ds-blue-100: #d3f1fa;
$ds-blue-200: #a7e3f5;
$ds-blue-300: #7bd4ef;
$ds-blue-400: #4fc6ea;
$ds-blue-500: #23b8e5;
$ds-blue-600: #1c93b7;
$ds-blue: $ds-blue-500;

$ds-orange-100: #fff8f2;
$ds-orange-200: #ffead9;
$ds-orange-300: #ffc799;
$ds-orange-400: #ff8e33;
$ds-orange-500: #ff7200;
$ds-orange-600: #cc5b00;
$ds-orange: $ds-orange-500;

$ds-gray-100: #f9f9f9;
$ds-gray-200: #f0f0f0;
$ds-gray-300: #b3b3b3;
$ds-gray-400: #848484;
$ds-gray-500: #7e7e7e;
$ds-gray-600: #656565;
$ds-gray: $ds-gray-500;

$ds-yellow-100: #fff0cc;
$ds-yellow-200: #ffe199;
$ds-yellow-300: #ffd266;
$ds-yellow-400: #ffc333;
$ds-yellow-500: #ffb400;
$ds-yellow-600: #cc9000;
$ds-yellow: $ds-yellow-500;

$ds-red-100: #fff6f6;
$ds-red-200: #fee5e5;
$ds-red-300: #f99795;
$ds-red-400: #f77572;
$ds-red-500: #f5524f;
$ds-red-600: #c4423f;
$ds-red: $ds-red-500;

$ds-green-100: #d6eecd;
$ds-green-200: #addd9b;
$ds-green-300: #84cc69;
$ds-green-400: #5bbb37;
$ds-green-500: #32aa05;
$ds-green-600: #288804;
$ds-green: $ds-green-500;

$ds-black: #0a0a0a;

// Design system Typography

$ds-text-4xl: 60px;
$ds-text-3xl: 48px;
$ds-text-2xl: 36px;
$ds-text-xl: 30px;
$ds-text-lg: 24px;
$ds-text-md: 20px;
$ds-text: 16px;
$ds-text-sm: 12px;

// Design system Spacing

$ds-space-0: 0;
$ds-space-1: 0.25rem;
$ds-space-2: 0.5rem;
$ds-space-3: 0.75rem;
$ds-space-4: 1rem;
$ds-space-5: 1.25rem;
$ds-space-6: 1.5rem;
$ds-space-7: 1.75rem;
$ds-space-8: 2rem;
$ds-space-9: 2.25rem;
$ds-space-10: 2.5rem;
$ds-space-11: 2.75rem;
$ds-space-12: 3rem;
$ds-space-14: 3.5rem;
$ds-space-16: 4rem;
$ds-space-20: 5rem;
$ds-space-24: 6rem;
$ds-space-28: 7rem;
$ds-space-32: 8rem;
$ds-space-36: 9rem;
$ds-space-40: 10rem;
$ds-space-44: 11rem;
$ds-space-48: 12rem;
$ds-space-52: 13rem;
$ds-space-56: 14rem;
$ds-space-60: 15rem;
$ds-space-64: 16rem;
$ds-space-72: 18rem;
$ds-space-80: 20rem;
$ds-space-96: 24rem;

// Design system border radius

$ds-border-radius-none: 0;
$ds-border-radius-sm: 0.125rem;
$ds-border-radius: 0.25rem;
$ds-border-radius-md: 0.375rem;
$ds-border-radius-lg: 0.5rem;
$ds-border-radius-xl: 0.75rem;
$ds-border-radius-2xl: 1rem;
$ds-border-radius-3xl: 1.5rem;
$ds-border-radius-full: 50%;

$gutter: 15px;

$lg-width: 1180px;
$md-width: 960px;
$sm-width: 767px;
$xs-width: 480px;
