.profile-attachment--title {
  font-weight: 600;
}
.profile-attachment {
  position: relative;
  overflow: hidden;

  .profile-attachment--image {
    display: inline-block;
    margin: -3px 6px 0 0;
    width: 20px;
  }

  .profile-card-editable-item {
    border-bottom: 1px solid #efefef;
    padding: 30px 15px;
    @include transition;
    @include border-radius(0);
  }
  &:first-of-type {
    @include border-radius(20px 20px 0 0);
  }
  &:last-of-type {
    @include border-radius(0);
  }
}
.profile-attachment--loading {
  background-image: url('/img/loader-orange.svg') !important;
  background-repeat: no-repeat;
  background-position: center !important;
  background-size: 25px !important;
  padding: 30px 0;
}
