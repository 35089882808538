@import 'react-apps/common/styles/variables';
.user--watch {
  display: flex;
  margin-bottom: $ds-space-10;

  .-padd {
    padding: 0 15px;
  }

  .settings {
    font-size: 14px;
    margin-right: 30px;
    margin-top: 40px;
    width: 267px;

    .--empty {
      margin-top: 10px;
    }

    .description {
      line-height: 22px;
    }

    .edit {
      background: #f9f9f9 url('/img/icons/edit.svg') no-repeat 9px center;
      font-weight: 600;
      font-size: 12px;
      padding: 5px 11px 5px 25px;

      @include border-radius(11px);

      &:hover {
        background-color: #efefef;
        cursor: pointer;
      }
    }

    .check-box {
      .checkbox {
        height: 19px;
        margin: 0;
        width: 19px;
      }
    }
  }

  .content {
    flex: 1;
    padding-top: 20px;
  }

  @media (max-width: $lg-width) {
    .jobs--grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: $md-width) {
    flex-direction: column;

    .content {
      padding-top: 0;
    }

    .settings {
      margin-top: 20px;
      margin-right: 0;
      width: auto;
    }
  }

  @media (max-width: $sm-width) {
    .jobs--grid {
      grid-template-columns: 1fr;
    }
  }
}

.watch--search {
  padding: 20px;

  .--input {
    align-items: center;
    background: #f9f9f9 url('/img/icons/search.svg') no-repeat 12px center;
    background-size: auto 20px;
    border: 1px solid #e6e6e6;
    display: flex;
    padding: 6px 10px 6px 38px;
    @include border-radius(20px);

    input {
      background: transparent;
      border: none;
      outline: none;
      padding: 3px 5px;
      width: 100%;
    }

    .remove {
      background: url('/img/icons/remove.svg') no-repeat center center;
      background-size: 100% auto;
      border: none;
      height: 16px;
      width: 16px;
    }
  }
}

.watch--items {
  background-color: #fff;
  border: 1px solid $border-gray;
  margin-bottom: 20px;
  @include border-radius(16px);

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .subtitle {
    margin: 10px 0 -3px;
  }

  .--header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .items--section {
    padding: 20px;

    &:not(:first-child) {
      border-top: 1px solid $border;
    }

    &.--line {
      border-bottom: 1px solid $border;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -3px 0;

    .item {
      background-color: #fff;
      border: 1px solid #e6e6e6;
      margin: 3px;
      padding: 5px 8px;

      @include border-radius(9px);

      &:hover {
        background-color: #fafafa;
        border-color: #ddd;
        cursor: pointer;
      }

      &.selected {
        background-color: $ds-orange-200;
        border-color: $ds-orange-200;

        &:hover {
          background-color: $ds-orange-300;
        }
      }
    }
  }

  .checks {
    .item {
      cursor: pointer;
      margin: 10px 0;

      &:hover {
        .checkbox {
          border-color: $orange;
        }
      }
    }
  }

  .--bell {
    background: url('/img/icons/bell.svg') no-repeat center;
    background-size: auto 24px;
    height: 24px;
    position: relative;
    width: 19px;

    .--count {
      background-color: $red;
      border: 1px solid #fff;
      color: #fff;
      font-size: 10px;
      line-height: 16px;
      font-weight: bold;
      min-width: 17px;
      padding: 1px 3px;
      position: absolute;
      right: -10px;
      text-align: center;
      top: -6px;
      @include border-radius(9px);
    }
  }
}
