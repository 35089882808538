.screen-loader {
  background: rgba(#000000, 0.05) url('/img/loader-orange.svg') no-repeat center;
  height: 100px;
  left: 50%;
  position: fixed;
  top: 50%;
  width: 100px;
  z-index: 99999;
  @include border-radius(20px);
  @include center();
}
