.summerjobs-btn {
  position: relative;

  a {
    display: inline-block;
    color: #fff;
    background-color: rgba(#32aa05,.7);
    padding: 8px 40px 8px 20px;
    border: 1px rgba(#32aa05,.9) solid;
    font-weight: 700;
    @include border-radius(13px);
    @include transition;

    .icon {
      width: 25px;
      height: 25px;
      position: absolute;
      display: inline-block;
      top: calc(50% - 12px);
      right: 10px;

      &.tree {
        background-image: url('/img/icons/tree.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &:hover {
      background-color: rgba(50, 170, 5, .9);

      .icon {
        animation: swing 1s linear forwards;
        // transform: rotate(-10deg);
      }
    }
  }

  &.hide-text {
    a {
      padding: 15px;
      @include border-radius(50%);
      .icon {
        right: 4px;
      }
    }
    .summerjobs-btn--text {
      display: none;
    }
  }
}
@keyframes swing {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0);
  }
}
